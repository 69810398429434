import React from "react";

// Chakra imports
import { Flex, useColorModeValue,Text, Icon, Image, Box } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import logo from "assets/img/auth/diet1.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
        <Box h='100px' w='100px' mr='2px'>
        <Image  src={logo} color={logoColor} alt='logo' />
        </Box>
      <HSeparator mb='10px' />
    </Flex>
  );
}

export default SidebarBrand;
