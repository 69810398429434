/* eslint-disable */
import {
  Flex,
  Avatar,
  Box,
  Icon,
  Button,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react";

import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import React, { useState, useEffect, useContext } from "react";
import { Table } from "react-chakra-pagination";
import { MdFilterNone, MdRefresh } from "react-icons/md";
import { UserContext } from "contexts/UserContext";
import axios from "axios";
import { AddFoodModal } from "./AddFoodModal";
export default function MesalTable() {
  const [meals, setMeals] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchMeals();
  }, [loading]);
  async function fetchMeals() {
    getMeals()
      .then((res) => {
        if (!Array.isArray(res)) {
          setError("response is not an array");
          setMealList([]);
          return;
        }
        setError(null);
        setMealList(res);
        setMeals(res);
      })
      .catch((err) => {
        console.error(err);
        setError("An error occured");
      });
      return;
  }

  // function to retireve meals from the server
  async function getMeals() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/retrieve_meals");
    const data = res.data;
    return data;
  }
  // Control current Page
  const [page, setPage] = React.useState(1);
  function onSearch(e) {
   
    const search = e.target.value.toLowerCase();

    const filtered = meals.filter((meal) => {
      return meal.meal_name.trim().toLowerCase().includes(search);
    });
    setMealList(filtered);
  }
  if (error !== null) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text size="xl" fontWeight="bold" fontSize="xl">
          {error}
        </Text>
      </Flex>
    );
  }
  // Formatter for each user
  if (mealList === undefined || mealList.length === 0) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  const tableData = mealList.map((meal, id) => ({
    name: (
      <Flex align="center" key={id}>
        <Avatar name={meal.meal_name} src={meal.image_url} size="md" mr="4" />
        <Link to={"/admin/meal/" + meal.meal_id}>{meal.meal_name}</Link>
      </Flex>
    ),
    protein: meal.protein.toFixed(2),
    carbs: meal.carbs.toFixed(2),
    id: meal.id,
    fat: meal.fat.toFixed(2),
    calories: meal.calories.toFixed(2),
    action: (
      <Button
        colorScheme="gray"
        onClick={() => {
          axios
            .delete(`/api/auth/delete_meal/${meal.meal_id}`)
            .then(async (response) => {
              fetchMeals();
            })
            .catch(function (error) {
              console.error(error);
            });
        }}
        size="sm"
      >
        <Icon as={FiTrash2} fontSize="20" />
      </Button>
    ),
  }));

  // Accessor to get a data in user object
  const tableColumns = [
    {
      Header: "Όνομα",
      accessor: "name",
    },
    {
      Header: "Πρωτεΐνες",
      accessor: "protein",
    },
    {
      Header: "Υδατάνθρακες",
      accessor: "carbs",
    },
    {
      Header: "Λιπαρά",
      accessor: "fat",
    },
    {
      Header: "Θερμίδες",
      accessor: "calories",
    },
    {
      Header: "",
      accessor: "action",
    },
  ];

  return (
    <Box p="12">
      <Box p="2"></Box>
      <Box
        p="1"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <SearchBar onSearch={onSearch} placeholder="Αναζήτηση" />
      </Box>
      <Box mt="6">
        <Table
          colorScheme="blue"
          // Fallback component when list is empty
          emptyData={{
            icon: MdFilterNone,
            text: "No meals found.",
          }}
          totalRegisters={meals !== undefined ? meals.length : 0}
          page={page}
          // Listen change page event and control the current page using state
          onPageChange={(page) => setPage(page)}
          columns={tableColumns}
          data={tableData}
        />
        <AddFoodModal />
      </Box>
    </Box>
  );
}
