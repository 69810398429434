import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Input,
  Text,
  HStack,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { MdInfo, MdManageSearch } from "react-icons/md";
export function Metrics(props) {
  const { client_id } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [weight, setWeight] = useState([]);
  const [date, setDate] = useState([]);
  const [lastModified, setLastModified] = useState("");
  const [chest, setChest] = useState([]);
  const [waist, setWaist] = useState([]);
  const [hip, setHip] = useState([]);
  const [arm, setArm] = useState([]);
  const [thigh, setThigh] = useState([]);
  const [bmi,setBmi] = useState([]);
  const [gastrocnymium,setGastrocnymium] = useState([]);
  const [abdomen,setAbdomen] = useState([]);
  const [height,setHeight] = useState([]);
  useEffect(() => {
    axios
      .get("api/auth/get_client_metrics", {
        client_id: client_id,
      })
      .then((res) => {
        console.debug(res.data);

        const data = res.data[client_id];

        const weight_values = data.map((value) => value.weight);
        const weight_dates = data.map((value) =>
          new Date(value.date_measured).toLocaleDateString()
        );
        const weight_last_modified = new Date(
          data[data.length - 1].date_measured
        );
        setWeight(weight_values);
        setDate(weight_dates);
        setLastModified(weight_last_modified.toLocaleDateString());
        const chest_values = data.map((value) => value.chest);
        setChest(chest_values);
        const waist_values = data.map((value) => value.waist);
        setWaist(waist_values);
        const hip_values = data.map((value) => value.hips);
        setHip(hip_values);
        const arm_values = data.map((value) => value.flexed_arm);
        setArm(arm_values);
        const thigh_values = data.map((value) => value.thigh);
        setThigh(thigh_values);
        const bmi_values = data.map((value) => value.bmi);
        setBmi(bmi_values);
        const gastrocnymium_values = data.map((value) => value.gastrocnemius);
        setGastrocnymium(gastrocnymium_values);
        const abdomen_values = data.map((value) => value.abdomen);
        setAbdomen(abdomen_values);
        const height_values = data.map((value) => value.height);
        setHeight(height_values);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      <Button variant="outline" leftIcon={<MdManageSearch />} onClick={onOpen}>
        Φύλλο παρακολούθησης
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent overflowY="auto">
          <ModalHeader>Φύλλο παρακολούθησης</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm" color="gray.500">
              Τελευταία ενημέρωση: {lastModified}
            </Text>
            <Metric title={"Βάρος"} series={weight} categories={date} />
            <Metric title={"Στήθος"} series={chest} categories={date} />
            <Metric title={"Γλουτοί"} series={hip} categories={date} />
            <Metric title={"Περιφέρεια"} series={waist} categories={date} />
            <Metric title={"Πλάτη"} series={thigh} categories={date} />
            <Metric title={"Χέρια"} series={arm} categories={date} />
            <Metric title={"BMI"} series={bmi} categories={date} />
            <Metric title={"Γαστροκνήμιο"} series={gastrocnymium} categories={date} />
            <Metric title={"Κοιλιά"} series={abdomen} categories={date} />
            <Metric title={"Ύψος"} series={height} categories={date} />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              colorScheme="brand"
              mr={3}
              onClick={onClose}
            >
              Πίσω
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function Metric(props) {
  const { title, series, categories } = props;
  var options = {
    chart: {
      height: 280,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: title,
        data: series,
      },
    ],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: categories,
    },
  };

  return (
    <Box
      w="100%"
      h="100%"
      bg="gray.100"
      borderRadius="lg"
      boxShadow="lg"
      p="1em"
      mb="1em"
    >
      <HStack>
        <Icon as={MdInfo} w={6} h={6} color="blue.300" />
        <Text>{title}</Text>
      </HStack>
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={160}
      />
    </Box>
  );
}
