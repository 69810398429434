import { AddIcon, ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  VStack,
  Textarea,
  Box,
  Tag,
  Text,
  Collapse,
  Checkbox,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Flex,
  CheckboxGroup,
  Stack,
  Radio,
  RadioGroup,
  HStack,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  useToast,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { MdEMobiledata, MdHistory } from "react-icons/md";
import Meals from "./Meals";
import MedicalHistory from "./MedicalHistory";

export function HistoryModal(props) {
  const { client_id } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [history, setHistory] = useState([]);
  const [medication, setMediation] = useState([]);
  const [diet, setDiet] = useState([]);
  const getHistory = async () => {
    axios
      .get(`/api/auth/get_pharmaceutical_history/${client_id}`)
      .then((response) => {
        console.log("pharma", response.data);
        setHistory(response.data.value);
        setMediation(response.data.medication);
      });
  };

  const getDiet = async () => {
    axios.get(`/api/auth/get_nutrition_history/${client_id}`).then((res) => {
      console.debug(res.data);
      setDiet(res.data);
    });
  };

  useEffect(() => {
    getHistory();
    getDiet();
  }, []);
  return (
    <>
      <Button variant="outline" leftIcon={<MdHistory />} onClick={onOpen}>
        Γενικό Ιστορικό
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Γενικό Ιστορικό</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Ιατρικό ιστορικό & φαρμακευτική αγωγή</Tab>
                <Tab>Διατροφικό ιστορικό </Tab>
                <Tab>Ιστορικό καθημερινότητας/άσκησης</Tab>
                <Tab>Ανάκληση 24ώρου</Tab>
              </TabList>
              <TabPanels>
                <TabPanel overflowY="auto">
                  <GenericHistory
                    initalMedication={medication}
                    initialHistory={history}
                    client_id={client_id}
                  />
                </TabPanel>
                <TabPanel overflowY="auto">
                  <DietHistory client_id={client_id} diet={diet} />
                </TabPanel>
                <TabPanel overflowY="auto">
                  <ExerciseHistory client_id={client_id} />
                </TabPanel>
                <TabPanel overflowY="auto">
                  <Meals client_id={client_id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Πίσω
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
function CustomRadioGroup(props) {
  const { title, options, value, onChange } = props;
  return (
    <Box p={2} shadow="md" borderWidth="1px">
      <HStack spacing={10}>
        <Tag fontSize="md">{title}</Tag>
        <RadioGroup colorScheme="blue" value={value} onChange={onChange}>
          <Stack spacing={[1, 5]} direction={["column", "row"]}>
            <Radio value="daily">Σχεδόν κάθε μέρα</Radio>
            <Radio value="weekly5">3-5 Φορές την εβδομάδα</Radio>
            <Radio value="weekly2">1-2 Φορές την εβδομάδα</Radio>
            <Radio value="sparely">1-2 Φορές τον μήνα</Radio>
            <Radio value="not">Καθόλου</Radio>
          </Stack>
        </RadioGroup>
      </HStack>
    </Box>
  );
}
function DietHistory(props) {
  const { client_id, diet } = props;
  const [showGeneralDietBehavior, setShowGeneralDietBehavior] = useState(false);
  const [showDietaryRestrictions, setShowDietaryRestrictions] = useState(false);
  const [dairy, setDairy] = useState(diet["dairy"] || "daily");
  const [meat, setMeat] = useState(diet["meat"] || "daily");
  const [fish, setFish] = useState(diet["fish"] || "daily");
  const [eggs, setEggs] = useState(diet["eggs"] || "daily");
  const [vegetables, setVegetables] = useState(diet["vegetables"] || "daily");
  const [fruits, setFruits] = useState(diet["fruits"] || "daily");
  const [beverages, setBeverages] = useState(diet["beverages"] || "daily");
  const [alc, setAlc] = useState(diet["alc"] || "daily");
  const [awareOfShopping, setAwareOfShopping] = useState(
    diet["awareOfShopping"] === "yes" ? "yes" : "no" || "no"
  );
  const [awareOfCooking, setAwareOfCooking] = useState(
    diet["awareOfCooking"] === "yes" ? "yes" : "no" || "no"
  );
  const [delivery, setDelivery] = useState(
    diet["delivery"] === "month" ? "month" : "week" || "week"
  );
  const [mealDuration, setMealDuration] = useState(diet["mealDuration"]);
  const [bio, setBio] = useState(diet["bio"] === "yes" ? "yes" : "no" || "no");
  const [dietPlan, setDietPlan] = useState(diet["dietPlan"]);
  const [coffeeType, setCoffeeType] = useState(diet["coffeeType"]);
  const toast = useToast();

  async function updateDietHistory() {
    axios
      .put("/api/auth/set_nutrition_history", {
        client_id: client_id,
        history: diet,
      })
      .then((res) => {
        console.info("res", res);
        toast({
          title: "Επιτυχής αποθήκευση",
          description: "Τα δεδομένα αποθηκεύτηκαν επιτυχώς",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("err", err);
        toast({
          title: "Αποτυχία αποθήκευσης",
          description: "Τα δεδομένα δεν αποθηκεύτηκαν",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }
  return (
    <>
      <Button
        onClick={() => {
          setShowGeneralDietBehavior(!showGeneralDietBehavior);
        }}
        leftIcon={showGeneralDietBehavior ? <ArrowUpIcon /> : <ArrowDownIcon />}
      >
        Γενική διατροφική συμπεριφορά
      </Button>

      <Collapse in={showGeneralDietBehavior} animateOpacity>
        <CustomRadioGroup
          title="Γαλακτοκομικά"
          value={dairy}
          onChange={(e) => {
            setDairy(e);
            diet["dairy"] = e;
            console.log(diet);
          }}
        />
        <CustomRadioGroup
          title="Κόκκινο Κρέας"
          value={meat}
          onChange={(e) => {
            setMeat(e);
            diet["meat"] = e;
          }}
        />
        <CustomRadioGroup
          title="Ψάρι"
          value={fish}
          onChange={(e) => {
            setFish(e);
            diet["fish"] = e;
          }}
        />
        <CustomRadioGroup
          title="Αυγά"
          value={eggs}
          onChange={(e) => {
            setEggs(e);
            diet["eggs"] = e;
          }}
        />
        <CustomRadioGroup
          title="Φρούτα"
          value={fruits}
          onChange={(e) => {
            setFruits(e);
            diet["fruits"] = e;
          }}
        />
        <CustomRadioGroup
          title="Λαχανικά"
          value={vegetables}
          onChange={(e) => {
            setVegetables(e);
            diet["vegetables"] = e;
          }}
        />
        <CustomRadioGroup
          title="Αλκοολούχα Ποτά"
          value={alc}
          onChange={(e) => {
            setAlc(e);
            diet["alc"] = e;
          }}
        />
        <CustomRadioGroup
          title="Χυμοί&Αναψυκτικά"
          value={beverages}
          onChange={(e) => {
            setBeverages(e);
            diet["beverages"] = e;
          }}
        />
        <Box h={5} />
        <HStack>
          <Text w={"100%"} fontSize="md">
            Είστε υπεύθυνος/η για τα ψώνια του φαγητού;
          </Text>
          <Select
            value={awareOfShopping}
            onChange={(e) => {
              setAwareOfShopping(e.target.value);
              diet["awareOfShopping"] = e.target.value;
            }}
          >
            <option value="yes">Ναί</option>
            <option value="no">Όχι</option>
          </Select>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Είστε υπεύθυνος/η για την προετοιμασία του φαγητού;
          </Text>
          <Select
            value={awareOfCooking}
            onChange={(e) => {
              setAwareOfCooking(e.target.value);
              diet["awareOfCooking"] = e.target.value;
            }}
          >
            <option value="yes">Ναί</option>
            <option value="no">Όχι</option>
          </Select>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Πόσο συχνά παραγγέλνετε delivery στο σπίτι;
          </Text>
          <Input
            type="number"
            placeholder={diet["deliveryFrequency"] || "1"}
            onChange={(e) => {
              diet["deliveryFrequency"] = e.target.value;
            }}
          />

          <Select
            value={delivery}
            onChange={(e) => {
              setDelivery(e.target.value);
              diet["delivery"] = e.target.value;
            }}
          >
            <option value="month">Φορές/μήνα</option>
            <option value="week">Φορές/εβδομάδα</option>
          </Select>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Πως είναι η όρεξή σας;
          </Text>
          <NumberInput
            defaultValue={diet["appetite"] || 5}
            min={0}
            max={10}
            onChange={(e) => {
              diet["appetite"] = e;
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Πόσο διαρκεί ένα κυρίως γεύμα σας;
          </Text>
          <Select
            value={mealDuration}
            onChange={(e) => {
              setMealDuration(e.target.value);
              diet["mealDuration"] = e.target.value;
              console.log(diet);
            }}
          >
            <option value="less">λιγότερο από 10 λεπτά</option>
            <option value="equals">10 - 20 λεπτά </option>
            <option value="more">περισσότερα από 20 λεπτά</option>
          </Select>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Καταναλώνετε βιολογικά προϊόντα;
          </Text>
          <Select
            value={bio}
            onChange={(e) => {
              setBio(e.target.value);
              diet["bio"] = e.target.value;
            }}
          >
            <option value="yes">Ναί</option>
            <option value="no">Όχι</option>
          </Select>
        </HStack>
        <HStack>
          <Text w={"100%"} fontSize="md">
            Ακολουθείτε κάποιο συγκεκριμένο Διατροφικό Πρότυπο;
          </Text>
          <Select
            value={dietPlan}
            onChange={(e) => {
              setDietPlan(e.target.value);
              diet["dietPlan"] = e.target.value;
            }}
          >
            <option value="vegetarian">Χορτοφαγία (Vegetarian)</option>
            <option value="vegan">Αυστηρή Χορτοφαγία (Vegan) </option>
            <option value="keto">Κετογονική</option>
            <option value="fasting">Διαλείπουσα νηστεία (Fasting)</option>
          </Select>
        </HStack>
        <Button
          mt="1em"
          colorScheme="teal"
          size="sm"
          onClick={() => {
            updateDietHistory();
          }}
        >
          Αποθήκευση
        </Button>
      </Collapse>
      <Box h={10} />
      <Button
        onClick={() => {
          setShowDietaryRestrictions(!showDietaryRestrictions);
        }}
        leftIcon={showDietaryRestrictions ? <ArrowUpIcon /> : <ArrowDownIcon />}
      >
        Διατροφή στην καθημερινότητα
      </Button>
      <Collapse in={showDietaryRestrictions} animateOpacity>
        <VStack>
          <InputGroup size="sm">
            <InputLeftAddon children="Γεύματα" />
            <Input
              placeholder={diet["meals"] === undefined ? "0" : diet["meals"]}
              type="number"
              onChange={(e) => {
                diet["meals"] = e.target.value;
              }}
            />
            <InputRightAddon children="ανά μέρα" />
          </InputGroup>
          <Text fontSize="sm">Γεύματα που καταναλώνετε εκτός σπιτιού</Text>
          <CheckboxGroup
            colorScheme="blue"
            value={diet["outside_meal_type"]}
            onChange={(e) => {
              diet["outside_meal_type"] = e;
            }}
          >
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              <Checkbox value="breakfast">Πρωινό</Checkbox>
              <Checkbox value="snack1">Δεκατιανό</Checkbox>
              <Checkbox value="lunch">Μεσημεριανό</Checkbox>
              <Checkbox value="snack2">Απογευματινό</Checkbox>
              <Checkbox value="dinner">Βραδινό</Checkbox>
            </Stack>
          </CheckboxGroup>
          <Textarea
            size="sm"
            placeholder={
              diet["outside_meal"] || "Ποια παραγγέλνετε/ αγοράζετε απέξω;"
            }
            onChange={(e) => {
              diet["outside_meal"] = e.target.value;
            }}
          />
          <Textarea
            size="sm"
            placeholder={
              diet["preperation_meal"] ||
              "Ποια προετοιμάζετε στο σπίτι και παίρνετε μαζί σας;"
            }
            onChange={(e) => {
              diet["preperation_meal"] = e.target.value;
            }}
          />
          <InputGroup size="sm">
            <InputLeftAddon children="Νερό" />
            <Input
              placeholder={diet["water"] === undefined ? "0" : diet["water"]}
              type="number"
              onChange={(e) => {
                diet["water"] = e.target.value;
              }}
            />
            <InputRightAddon children="ποτήρια/μέρα" />
          </InputGroup>
          <InputGroup size="sm">
            <InputLeftAddon children="Καφές/Τσάι" />
            <Input
              placeholder={diet["coffee"] === undefined ? "0" : diet["coffee"]}
              type="number"
              onChange={(e) => {
                diet["coffee"] = e.target.value;
              }}
            />

            <Select
              onChange={(e) => {
                console.log(e.target.value);
                diet["coffeeType"] = e.target.value;
                setCoffeeType(e.target.value);
              }}
              value={coffeeType}
            >
              <option value="day">ποτήρια/ ημέρα</option>
              <option value="week">ποτήρια/ εβδομάδα</option>
            </Select>
          </InputGroup>
          <InputGroup size="sm">
            <InputLeftAddon children="Ζάχαρη" />
            <Input
              placeholder={diet["sugar"] === undefined ? "0" : diet["sugar"]}
              type="number"
              onChange={(e) => {
                diet["coffee"] = e.target.value;
              }}
            />
            <InputRightAddon children="κουταλάκια/μέρα" />
          </InputGroup>
        </VStack>
        <Tag size="md" mt="1em">
          Αγαπημένα Τρόφιμα
        </Tag>
        <VStack>
          <Text fontSize="xs" fontStyle="italic">
            Χωρίστε τα με κόμμα ( , ) αν είναι παραπάνω από 1
          </Text>
          <InputGroup size="sm">
            <InputLeftAddon children="Πρωινό" />
            <Input
              placeholder={
                diet["favorite"] === undefined
                  ? "Ομελέτα"
                  : diet["favorite"]["breakfast"]
              }
              onChange={(e) => {
                if (diet["favorite"] === undefined) {
                  diet["favorite"] = {};
                }
                diet["favorite"]["breakfast"] = e.target.value;
              }}
            />
          </InputGroup>
          <InputGroup size="sm">
            <InputLeftAddon children="Μεσημεριανό" />
            <Input
              placeholder={
                diet["favorite"] === undefined
                  ? "Μακαρόνια"
                  : diet["favorite"]["lunch"]
              }
              onChange={(e) => {
                if (diet["favorite"] === undefined) {
                  diet["favorite"] = {};
                }
                diet["favorite"]["lunch"] = e.target.value;
              }}
            />
          </InputGroup>
          <InputGroup size="sm">
            <InputLeftAddon children="Σνάκ" />
            <Input
              placeholder={
                diet["favorite"] === undefined
                  ? "Μπάρα δημητριακών"
                  : diet["favorite"]["snack"]
              }
              onChange={(e) => {
                if (diet["favorite"] === undefined) {
                  diet["favorite"] = {};
                }
                diet["favorite"]["snack"] = e.target.value;
              }}
            />
          </InputGroup>
          <InputGroup size="sm">
            <InputLeftAddon children="Βραδινό" />
            <Input
              placeholder={
                diet["favorite"] === undefined
                  ? "Πίτσα,Σουβλάκι"
                  : diet["favorite"]["dinner"]
              }
              onChange={(e) => {
                if (diet["favorite"] === undefined) {
                  diet["favorite"] = {};
                }
                diet["favorite"]["dinner"] = e.target.value;
              }}
            />
          </InputGroup>
        </VStack>
        <Tag size="md" mt="1em">
          Τρόφιμα που δεν τρώει
        </Tag>
        <Textarea
          size="sm"
          placeholder={
            diet["non-favorite"] === undefined
              ? "Παρακαλώ πληκτρολογήστε τα τρόφιμα που δεν τρώει ο ασθενής"
              : diet["non-favorite"]
          }
          onChange={(e) => {
            const text = e.target.value;
            const normailized_text = text.replace(/\n/g, ",");
            diet["non-favorite"] = normailized_text;
          }}
        />
        <Box mt="1em" />
        <Textarea
          placeholder={
            diet["comments"] === undefined ? "Σχόλια" : diet["comments"]
          }
          size="sm"
          onChange={(e) => {
            diet["comments"] = e.target.value;
          }}
        />
        <Button
          mt="1em"
          colorScheme="teal"
          size="sm"
          onClick={updateDietHistory}
        >
          Αποθήκευση
        </Button>
      </Collapse>
    </>
  );
}

function ExerciseHistory(props) {
  const [exercise, setExercise] = useState({});
  const { client_id } = props;

  useEffect(() => {
    axios.get(`/api/auth/get_exercise_history/${client_id}`).then((res) => {
      console.log(res.data);
      setExercise(res.data);
    });
  }, [client_id]);
  async function updateExerciseHistory() {
    await axios
      .put(`/api/auth/set_exercise_history`, {
        client_id: client_id,
        history: exercise,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  return (
    <>
      <VStack>
        <InputGroup size="sm">
          <InputLeftAddon children="Υπνος" />
          <Input
            placeholder={
              exercise["sleep"] === undefined ? "0" : exercise["sleep"]
            }
            type="number"
            onChange={(e) => (exercise["sleep"] = e.target.value)}
          />
          <InputRightAddon children="ώρες/μέρα" />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Εργασία" />
          <Input
            placeholder={
              exercise["work_days"] === undefined ? "0" : exercise["work_days"]
            }
            type="number"
            onChange={(e) => (exercise["work_days"] = e.target.value)}
          />
          <Input
            placeholder={
              exercise["work_hours"] === undefined
                ? "0"
                : exercise["work_hours"]
            }
            type="number"
            onChange={(e) => (exercise["work_hours"] = e.target.value)}
          />
          <InputRightAddon children="μέρες" />
          <InputRightAddon children="ωράριο" />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Βήματα" />
          <Input
            placeholder={
              exercise["steps"] === undefined ? "0" : exercise["steps"]
            }
            type="number"
            onChange={(e) => (exercise["steps"] = e.target.value)}
          />
          <InputRightAddon children="ανά μέρα" />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Συμπληρώματα" />
          <Input
            placeholder={
              exercise["supplements"] === undefined
                ? "Πρωτείνη,Αμινοξέα"
                : exercise["supplements"]
            }
            type="text"
            onChange={(e) => (exercise["supplements"] = e.target.value)}
          />
          <Input
            size="sm"
            placeholder={
              exercise["supplements_quantity"] === undefined
                ? "30γρ"
                : exercise["supplements_quantity"]
            }
            onChange={(e) =>
              (exercise["supplements_quantity"] = e.target.value)
            }
          />
          <InputRightAddon children="Δοσολογία" />
        </InputGroup>
        <Tag size="md" mt="1em">
          Άσκηση
        </Tag>
        <InputGroup size="sm">
          <InputLeftAddon children="Συχνότητα" />
          <Input
            placeholder={
              exercise["workout"] === undefined
                ? "0"
                : exercise["workout"]["frequency"]
            }
            type="number"
            onChange={(e) => {
              if (exercise["workout"] === undefined) {
                exercise["workout"] = {};
              }
              exercise["workout"]["frequency"] = e.target.value;
            }}
          />
          <InputRightAddon children="φορές/εβδομάδα" />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Είδος γυμναστικής" />
          <Input
            placeholder={
              exercise["workout"] === undefined
                ? "Ποδήλατο,Πολεμικές τέχνες"
                : exercise["workout"]["type"]
            }
            type="text"
            onChange={(e) => {
              if (exercise["workout"] === undefined) {
                exercise["workout"] = {};
              }
              exercise["workout"]["type"] = e.target.value;
            }}
          />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Ενταση γυμναστικής" />
          <Input
            placeholder={
              exercise["workout"] === undefined
                ? "Μέτρια"
                : exercise["workout"]["intensity"]
            }
            type="text"
            onChange={(e) => {
              if (exercise["workout"] === undefined) {
                exercise["workout"] = {};
              }
              exercise["workout"]["intensity"] = e.target.value;
            }}
          />
        </InputGroup>
        <InputGroup size="sm">
          <InputLeftAddon children="Διάρκεια" />
          <Input
            placeholder={
              exercise["workout"] === undefined
                ? "0"
                : exercise["workout"]["duration"]
            }
            type="number"
            onChange={(e) => {
              if (exercise["workout"] === undefined) {
                exercise["workout"] = {};
              }
              exercise["workout"]["duration"] = e.target.value;
            }}
          />
          <InputRightAddon children="σε λεπτά" />
        </InputGroup>

        <Textarea
          placeholder={
            exercise["comments"] === undefined ? "Σχόλια" : exercise["comments"]
          }
          size="sm"
          onChange={(e) => {
            exercise["comments"] = e.target.value;
            console.log(exercise);
          }}
        />
        <Button
          mt="1em"
          colorScheme="teal"
          size="sm"
          alignSelf="flex-start"
          onClick={updateExerciseHistory}
        >
          Αποθήκευση
        </Button>
      </VStack>
    </>
  );
}

function GenericHistory(props) {
  const { client_id, initalMedication, initialHistory } = props;
  const [history, setHistory] = useState(initialHistory);
  const toast = useToast();
  const [mediation, setMediation] = useState(initalMedication);
  const [heart_disease, setHeartDisease] = useState(
    initialHistory["hypertension"]
      ? initialHistory["hypertension"]["value"] || false
      : false
  );
  const [diabetes, setDiabetes] = useState(initialHistory["diabetes1"]);
  const [showMedicalHistory, setShowMedicalHistory] = useState(false);
  const [medical_history, setMedicalHistory] = useState({});
  const [showMedicineHistory, setShowMedicineHistory] = useState(false);
  const [renalFailure, setRenameFailure] = useState(
    initialHistory["renal_failure"]
  );
  const [anemia, setAnemia] = useState(initialHistory["anemia"]);
  const [gastroesophagealReflux, setGastroesophagealReflux] = useState(
    initialHistory["gastroesophageal_reflux"]
  );
  const [diseasesOfTheIntestine, setDiseasesOfTheIntestine] = useState(
    initialHistory["diseases_of_the_intestine"]
  );
  const [thyroidDisease, setThyroidDisease] = useState(
    initialHistory["thyroid_disease"]
  );
  const handleToggle = () => {
    return setShowMedicalHistory(!showMedicalHistory);
  };
  useEffect(() => {
    axios.get(`/api/auth/get_medical_history/${client_id}`).then((res) => {
      console.log("medical history", res.data);
      setMedicalHistory(res.data);  
    });
  }, []);

  console.log("mediation history", mediation);
  return (
    <>
      <Box>
        <Button
          leftIcon={showMedicalHistory ? <ArrowUpIcon /> : <ArrowDownIcon />}
          onClick={handleToggle}
        >
          Ατομικό Ιατρικό Ιστορικό & φαρμακευτική αγωγή
        </Button>
        <Collapse in={showMedicalHistory} animateOpacity mt={4}>
          <TableContainer>
            <Text fontSize="sm">Ατομικό ιατρικό ιστορικό</Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Είδος νόσου</Th>
                  <Th>ΝΑΙ/ ΟΧΙ</Th>
                  <Th>Κληρονομικότητα</Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr>
                  <Td>Στεφανιαία Νόσος - Καρδιοπάθειες</Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["heart_disease"] === undefined
                          ? false
                          : history["heart_disease"]["value"]
                      }
                      onChange={(e) => {
                        if (history["heart_disease"] === undefined) {
                          history["heart_disease"] = {};
                        }
                        history["heart_disease"]["value"] =
                          !history["heart_disease"]["value"];
                        console.log(history);
                      }}
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["heart_disease"] === undefined
                          ? false
                          : history["heart_disease"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["heart_disease"] === undefined) {
                          history["heart_disease"] = {};
                        }
                        history["heart_disease"]["inheritance"] =
                          !history["heart_disease"]["inheritance"];
                        console.log(e.target.value);
                        console.log(history);
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Υπέρταση</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["hypertension"] === undefined
                            ? false
                            : history["hypertension"]["value"]
                        }
                        onChange={(e) => {
                          if (history["hypertension"] === undefined) {
                            history["hypertension"] = {};
                          }
                          history["hypertension"]["value"] =
                            !history["hypertension"]["value"];
                          setHeartDisease(!heart_disease);
                        }}
                      />

                      {heart_disease ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["hypertension"]
                              ? history["hypertension"]["description"] ||
                                "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (history["hypertension"] === undefined) {
                              history["hypertension"] = {};
                            }
                            history["hypertension"]["description"] =
                              e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["hypertension"] === undefined
                          ? false
                          : history["hypertension"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["hypertension"] === undefined) {
                          history["hypertension"] = {};
                        }
                        history["hypertension"]["inheritance"] =
                          !history["hypertension"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Υπερχοληστερολαιμία</Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["hypercholesterolemia"] === undefined
                          ? false
                          : history["hypercholesterolemia"]["value"]
                      }
                      onChange={(e) => {
                        if (history["hypercholesterolemia"] === undefined) {
                          history["hypercholesterolemia"] = {};
                        }
                        history["hypercholesterolemia"]["value"] =
                          !history["hypercholesterolemia"]["value"];
                        console.log(history);
                      }}
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["hypercholesterolemia"] === undefined
                          ? false
                          : history["hypercholesterolemia"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["hypercholesterolemia"] === undefined) {
                          history["hypercholesterolemia"] = {};
                        }
                        history["hypercholesterolemia"]["inheritance"] =
                          !history["hypercholesterolemia"]["inheritance"];
                        console.log(history);
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Υπερτριγλυκεριδαιμία</Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["hypertriglyceridemia"] === undefined
                          ? false
                          : history["hypertriglyceridemia"]["value"]
                      }
                      onChange={(e) => {
                        if (history["hypertriglyceridemia"] === undefined) {
                          history["hypertriglyceridemia"] = {};
                        }
                        history["hypertriglyceridemia"]["value"] =
                          !history["hypertriglyceridemia"]["value"];
                        console.log(history);
                      }}
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["hypertriglyceridemia"] === undefined
                          ? false
                          : history["hypertriglyceridemia"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["hypertriglyceridemia"] === undefined) {
                          history["hypertriglyceridemia"] = {};
                        }
                        history["hypertriglyceridemia"]["inheritance"] =
                          !history["hypertriglyceridemia"]["inheritance"];
                        console.log(history);
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Σακχαρώδης Διαβήτης Τύπου Ι</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["diabetes1"] === undefined
                            ? false
                            : history["diabetes1"]["value"]
                        }
                        onChange={(e) => {
                          if (history["diabetes1"] === undefined) {
                            history["diabetes1"] = {};
                          }
                          history["diabetes1"]["value"] =
                            !history["diabetes1"]["value"];
                          setDiabetes(!diabetes);
                          console.log(history);
                        }}
                      />
                      {diabetes ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["diabetes1"]
                              ? history["diabetes1"]["description"] ||
                                "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (history["diabetes1"] === undefined) {
                              history["diabetes1"] = {};
                            }
                            history["diabetes1"]["description"] =
                              e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["diabetes1"] === undefined
                          ? false
                          : history["diabetes1"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["diabetes1"] === undefined) {
                          history["diabetes1"] = {};
                        }
                        history["diabetes1"]["inheritance"] =
                          !history["diabetes1"]["inheritance"];
                        console.log(history);
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Σακχαρώδης Διαβήτης Τύπου ΙΙ</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["diabetes_2"] === undefined
                            ? false
                            : history["diabetes_2"]["value"]
                        }
                        onChange={(e) => {
                          if (history["diabetes_2"] === undefined) {
                            history["diabetes_2"] = {};
                          }
                          history["diabetes_2"]["value"] =
                            !history["diabetes_2"]["value"];
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["diabetes_2"] === undefined
                          ? false
                          : history["diabetes_2"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["diabetes_2"] === undefined) {
                          history["diabetes_2"] = {};
                        }
                        history["diabetes_2"]["inheritance"] =
                          !history["diabetes_2"]["inheritance"];
                        console.log(history);
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Νεφρική Ανεπάρκεια</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["renal_failure"] === undefined
                            ? false
                            : history["renal_failure"]["value"]
                        }
                        onChange={(e) => {
                          if (history["renal_failure"] === undefined) {
                            history["renal_failure"] = {};
                          }
                          history["renal_failure"]["value"] =
                            !history["renal_failure"]["value"];
                          setRenameFailure(!renalFailure);
                          console.log(history);
                        }}
                      />
                      {renalFailure ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["renal_failure"]
                              ? history["renal_failure"]["description"] ||
                                "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (history["renal_failure"] === undefined) {
                              history["renal_failure"] = {};
                            }
                            history["renal_failure"]["description"] =
                              e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["renal_failure"] === undefined
                          ? false
                          : history["renal_failure"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["renal_failure"] === undefined) {
                          history["renal_failure"] = {};
                        }
                        history["renal_failure"]["inheritance"] =
                          !history["renal_failure"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Αναιμία</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["anemia"] === undefined
                            ? false
                            : history["anemia"]["value"]
                        }
                        onChange={(e) => {
                          if (history["anemia"] === undefined) {
                            history["anemia"] = {};
                          }
                          history["anemia"]["value"] =
                            !history["anemia"]["value"];
                          setAnemia(!anemia);
                          console.log(history);
                        }}
                      />
                      {anemia ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["anemia"]
                              ? history["anemia"]["description"] || "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (history["anemia"] === undefined) {
                              history["anemia"] = {};
                            }
                            history["anemia"]["description"] = e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["anemia"] === undefined
                          ? false
                          : history["anemia"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["anemia"] === undefined) {
                          history["anemia"] = {};
                        }
                        history["anemia"]["inheritance"] =
                          !history["anemia"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Παχυσαρκία</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["obesity"] === undefined
                            ? false
                            : history["obesity"]["value"]
                        }
                        onChange={(e) => {
                          if (history["obesity"] === undefined) {
                            history["obesity"] = {};
                          }
                          history["obesity"]["value"] =
                            !history["obesity"]["value"];
                          console.log(history);
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["obesity"] === undefined
                          ? false
                          : history["obesity"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["obesity"] === undefined) {
                          history["obesity"] = {};
                        }
                        history["obesity"]["inheritance"] =
                          !history["obesity"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Έλκος</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["asthma"] === undefined
                            ? false
                            : history["asthma"]["value"]
                        }
                        onChange={(e) => {
                          if (history["asthma"] === undefined) {
                            history["asthma"] = {};
                          }
                          history["asthma"]["value"] =
                            !history["asthma"]["value"];

                          console.log(history);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Γαστροοισοφαγική Παλινδρόμηση</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["gastroesophageal_reflux"] === undefined
                            ? false
                            : history["gastroesophageal_reflux"]["value"]
                        }
                        onChange={(e) => {
                          if (
                            history["gastroesophageal_reflux"] === undefined
                          ) {
                            history["gastroesophageal_reflux"] = {};
                          }
                          history["gastroesophageal_reflux"]["value"] =
                            !history["gastroesophageal_reflux"]["value"];
                          console.log(history);
                          setGastroesophagealReflux(!gastroesophagealReflux);
                        }}
                      />
                      {gastroesophagealReflux ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["gastroesophageal_reflux"]
                              ? history["gastroesophageal_reflux"][
                                  "description"
                                ] || "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (
                              history["gastroesophageal_reflux"] === undefined
                            ) {
                              history["gastroesophageal_reflux"] = {};
                            }
                            history["gastroesophageal_reflux"]["description"] =
                              e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Νόσοι του Εντέρου</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["diseases_of_the_intestine"] === undefined
                            ? false
                            : history["diseases_of_the_intestine"]["value"]
                        }
                        onChange={(e) => {
                          if (
                            history["diseases_of_the_intestine"] === undefined
                          ) {
                            history["diseases_of_the_intestine"] = {};
                          }
                          history["diseases_of_the_intestine"]["value"] =
                            !history["diseases_of_the_intestine"]["value"];
                          console.log(history);
                          setDiseasesOfTheIntestine(!diseasesOfTheIntestine);
                        }}
                      />
                      {history["diseases_of_the_intestine"] ||
                      diseasesOfTheIntestine ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["diseases_of_the_intestine"]
                              ? history["diseases_of_the_intestine"][
                                  "description"
                                ] || "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (
                              history["diseases_of_the_intestine"] === undefined
                            ) {
                              history["diseases_of_the_intestine"] = {};
                            }
                            history["diseases_of_the_intestine"][
                              "description"
                            ] = e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["diseases_of_the_intestine"] === undefined
                          ? false
                          : history["diseases_of_the_intestine"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (
                          history["diseases_of_the_intestine"] === undefined
                        ) {
                          history["diseases_of_the_intestine"] = {};
                        }
                        history["diseases_of_the_intestine"]["inheritance"] =
                          !history["diseases_of_the_intestine"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Θυρεοειδοπάθεια</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["thyroid_disease"] === undefined
                            ? false
                            : history["thyroid_disease"]["value"]
                        }
                        onChange={(e) => {
                          if (history["thyroid_disease"] === undefined) {
                            history["thyroid_disease"] = {};
                          }
                          history["thyroid_disease"]["value"] =
                            !history["thyroid_disease"]["value"];
                          console.log(history);
                          setThyroidDisease(!thyroidDisease);
                        }}
                      />
                      {thyroidDisease ? (
                        <Textarea
                          m={5}
                          placeholder={
                            history["thyroid_disease"]
                              ? history["thyroid_disease"]["description"] ||
                                "Περιγραφή"
                              : "Περιγραφή"
                          }
                          onChange={(e) => {
                            if (history["thyroid_disease"] === undefined) {
                              history["thyroid_disease"] = {};
                            }
                            history["thyroid_disease"]["description"] =
                              e.target.value;
                          }}
                        />
                      ) : null}
                    </Flex>
                  </Td>
                  <Td>
                    <Checkbox
                      defaultChecked={
                        history["thyroid_disease"] === undefined
                          ? false
                          : history["thyroid_disease"]["inheritance"]
                      }
                      onChange={(e) => {
                        if (history["thyroid_disease"] === undefined) {
                          history["thyroid_disease"] = {};
                        }
                        history["thyroid_disease"]["inheritance"] =
                          !history["thyroid_disease"]["inheritance"];
                      }}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Λιπώδες Ήπαρ</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["hyperlipidemia"] === undefined
                            ? false
                            : history["hyperlipidemia"]["value"]
                        }
                        onChange={(e) => {
                          if (history["hyperlipidemia"] === undefined) {
                            history["hyperlipidemia"] = {};
                          }
                          history["hyperlipidemia"]["value"] =
                            !history["hyperlipidemia"]["value"];
                          console.log(history);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Χολή</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["bile"] === undefined
                            ? false
                            : history["bile"]["value"]
                        }
                        onChange={(e) => {
                          if (history["bile"] === undefined) {
                            history["bile"] = {};
                          }
                          history["bile"]["value"] = !history["bile"]["value"];
                          console.log(history);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Ουρικό Οξύ</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["uric_acid"] === undefined
                            ? false
                            : history["uric_acid"]["value"]
                        }
                        onChange={(e) => {
                          if (history["uric_acid"] === undefined) {
                            history["uric_acid"] = {};
                          }
                          history["uric_acid"]["value"] =
                            !history["uric_acid"]["value"];
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Δυσκοιλιότητα</Td>
                  <Td>
                    <Flex align="center">
                      <Checkbox
                        defaultChecked={
                          history["constipation"] === undefined
                            ? false
                            : history["constipation"]["value"]
                        }
                        onChange={(e) => {
                          if (history["constipation"] === undefined) {
                            history["constipation"] = {};
                          }
                          history["constipation"]["value"] =
                            !history["constipation"]["value"];
                          console.log(history);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Text fontSize={"sm"}>Φαρμακευτική αγωγή</Text>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Ονομασία Φαρμάκου</Th>
                  <Th>Πάθηση</Th>
                  <Th>Δοσολογία</Th>
                </Tr>
              </Thead>
              {mediation.map((med, index) => {
                return (
                  <Tbody key={index}>
                    <Tr>
                      <Td>
                        <Input
                          placeholder={med.name || "Ονομασία Φαρμάκου"}
                          onChange={(e) => {
                            med.name = e.target.value;
                            console.log(med);
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          placeholder={med.disease || "Πάθηση"}
                          onChange={(e) => {
                            med.disease = e.target.value;
                            console.log(med);
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          placeholder={med.dosage || "Δοσολογία"}
                          onChange={(e) => {
                            med.dosage = e.target.value;
                            console.log(med);
                            console.log(mediation);
                          }}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                );
              })}
            </Table>
            <Button
              leftIcon={<AddIcon />}
              onClick={() => {
                setMediation([
                  ...mediation,
                  { name: "", disease: "", dosage: "" },
                ]);
              }}
            >
              Προσθήκη Φαρμάκου
            </Button>
          </TableContainer>
          <Button
            mt="1em"
            colorScheme="teal"
            size="sm"
            onClick={() => {
              console.log("clicked");
              axios
                .put("/api/auth/set_pharmaceutical_history", {
                  client_id: client_id,
                  history: {
                    value: history,
                    medication: mediation,
                  },
                })
                .then((res) => {
                  console.debug(res);
                  toast({
                    title: "Επιτυχής αποθήκευση",
                    description: "Τα στοιχεία αποθηκεύτηκαν επιτυχώς",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                })
                .catch((err) => {
                  toast({
                    title: "Αποτυχία αποθήκευσης",
                    description: "Τα στοιχεία δεν αποθηκεύτηκαν",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  console.log(err);
                });
            }}
          >
            Αποθήκευση
          </Button>
        </Collapse>
      </Box>
      <Box h={10} />
      <Box>
        <Button
          leftIcon={showMedicineHistory ? <ArrowUpIcon /> : <ArrowDownIcon />}
          onClick={() => {
            setShowMedicineHistory(!showMedicineHistory);
          }}
        >
          Ιατρικό - Διαιτολογικό Ιστορικό
        </Button>
        <Collapse in={showMedicineHistory} animateOpacity mt={4}>
          <MedicalHistory
            medicalHistory={medical_history}
            client_id={client_id}
          />
        </Collapse>
      </Box>
    </>
  );
}
