// Chakra imports
import {
  Box,
  Button,
  HStack,
  Input,
  Spacer,
  Text,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
import axios from "axios";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { useState } from "react";
import { MdDone, MdEdit } from "react-icons/md";

export default function Information(props) {
  const { title, value, id, ...rest } = props;
  const [edit, setEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState(value);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");

  async function updateValue(title, value, id) {
    console.log("title:", title, "new value:", value, "id:", id);
    let body = {
      client_id: id,
    };
    switch (title) {
      case "Address":
        body.address = value;
        break;
      case "Phone":
        body["phone_number"] = value;
        break;
      case "Subscription":
        body.subscription = value;
        break;
      case "notes":
        break;
      case "Birthday":
        body.birth_date = value.split("/").reverse().join("-");
        break;
      default:
        break;
    }
    axios
      .put("api/auth/update_client", body)
      .then((res) => {
        console.debug("res:", res);
        setEdit(false);
      })
      .catch((err) => {
        console.error("err:", err);
      });
    setValueEdit(value);
  }
  return (
    <Card bg={bg} {...rest}>
      <Box minH={50}>
        <HStack spacing={2} align="center">
          <Text fontWeight="500" color={textColorSecondary} fontSize="sm" width="100%">
            {title === "Birthday"
              ? "Ημερομηνία γέννησης"
              : title === "Subscription"
              ? "Συνδρομή"
              : title === "Phone"
              ? "Τηλέφωνο"
              : title === "Address"
              ? "Διεύθυνση"
              : title}
          </Text>
          <Spacer />
          {title === "Subscription" || title === "Email" ? null : (
            <Button
              leftIcon={!edit ? <MdEdit /> : <MdDone />}
              variantColor="blue"
              variant="ghost"
              size="sm"
              onClick={async () => {
                if (edit && value !== undefined) {
                  await updateValue(title, valueEdit, id);
                }
                setEdit(!edit);
              }}
            >
              {!edit ? null : "Αποθήκευση"}
            </Button>
          )}
        </HStack>
        {edit ? (
          <Input
            value={valueEdit !== undefined ? valueEdit : value}
            onChange={(e) => setValueEdit(e.target.value)}
          />
        ) : (
          <Wrap>
          <Text
            float="center"
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
          >
            {valueEdit !== undefined ? valueEdit : value}
          </Text>
          {title === "Birthday" ? (
          <Text color={textColorSecondary} fontSize="sm"  alignSelf={'flex-start'} px={5}>
            {_calculateAge(value) + " χρονών"}
          </Text>
        ) : null}
        </Wrap>
        )}
        
        
      </Box>
    </Card>
  );

  function _calculateAge(birthday) {
    // birthday is a date
    console.debug("Calculating age for:", birthday);

    var parts = birthday.split("/");
    var us_date = parts[2] + "-" + parts[1] + "-" + parts[0];
    var birthDate = new Date(us_date);
    var ageDifMs = Date.now() - birthDate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
