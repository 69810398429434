import React, { useState } from "react";
import axios from "axios";
import { Box, Button, HStack, Input, ModalBody, Tag, Text, useToast, VStack } from "@chakra-ui/react";
import AsyncSelect from "react-select/async";

export default function SearchFood(props) {
  const { value, setValue, id ,setId} = props;
  const toast = useToast();
  const [label,setLabel] = useState(value[id] !== null ? ( value[id].label !== undefined ? value[id].label : value[id].food) : ' ');

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      fetchFoods({ filter: inputValue }).then((foods) => {
        console.debug("foods", foods);
        resolve(
          foods.map((food) => ({
            label: food.name,
            alcochol: food.alcochol,
            caffeine: food.caffeine,
            calcium: food.calcium,
            calories: food.calories,
            carbs: food.carbs,
            category: food.category,
            cholesterol: food.cholesterol,
            choline: food.choline,
            code: food.code,
            copper: food.copper,
            fat: food.fat,
            ferrum: food.ferrum,
            fibers: food.fibers,
            follicles: food.follicles,
            lycopin: food.lycopin,
            magnisium: food.magnisium,
            monounsaturated: food.monounsaturated,
            name: food.name,
            natrium: food.natrium,
            "omega3": food["omega3"],
            "omega6": food["omega6"],
            phosphorus: food.phosphorus,
            polyunsaturated: food.polyunsaturated,
            potassium: food.potassium,
            protein: food.protein,
            saturated: food.saturated,
            selinium: food.selinium,
            sugars: food.sugars,
            "vitaminA": food["vitaminA"],
            "vitaminB1": food["vitaminB1"],
            "vitaminB2": food["vitaminB2"],
            "vitaminB3": food["vitaminB3"],
            "vitaminB6": food["vitaminB6"],
            "vitaminB12": food["vitaminB12"],
            "vitaminC": food["vitaminC"],
            "vitaminD": food["vitaminD"],
            "vitaminE": food["vitaminE"],
            "vitaminK": food["vitaminK"],
            water: food.water,
            zinc: food.zinc,
          }))
        );
      });
    });

  async function fetchFoods({ filter } = {}) {
    console.debug("fetchTodos", { filter });
    return axios
      .post(`/api/auth/search_food`, { keyword: filter })
      .then((res) => {
        console.debug("res", res);
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  return (
    <ModalBody pb={6}>
      {" "}
      <HStack align="stretch" direction="row" >
        <Box pb={6} width="100%"  minHeight="100" height="200">
          <AsyncSelect
            placeholder={
              value[id] !== null ? value[id].food : "Επιλέξτε τρόφημα"
            }
            loadOptions={promiseOptions}
            closeMenuOnSelect={true}
            noOptionsMessage={() => "Δεν βρέθηκαν αποτελέσματα"}
            size="lg"
            onChange={(e) => {
              value[id] = e;
              console.info("value", value);
              setLabel(value[id] !== null ? ( value[id].label !== undefined ? value[id].label : value[id].food) : ' ');
              setValue(value);
              if(setId !== undefined)
              setId(id);
            }}
            isClearable={true}
          />
        </Box>
        <Box h='50' minW={50} align="center" pt={1}>
          <Input
            placeholder={value[id] !== null ? value[id].quantity : 0}
            onChange={(e) => {
              value[id].quantity = e.target.value;
              setValue(value);
              console.debug("value", value);
            }}
            type="number"
            size={"sm"}
          />
        </Box>
        <Box h='100' bgColor={'lightBrand'} borderRadius={5} p={2} border={'1px'} color="gray.400"   align="center" pt={1}>
          <Text>Θερμίδες {value[id] !== null && value[id].quantity !== null && value[id].quantity !==undefined ? (value[id].calories * value[id].quantity / 100).toFixed(2) : 0} kcal</Text>
        </Box>
        <Box  h='100' bgColor={'lightBrand'} borderRadius={5} p={2} border={'1px'} color="gray.400"   align="center" pt={1}>
          <Text>Πρωτεΐνες {value[id] !== null && value[id].quantity !== null && value[id].quantity !==undefined ? (value[id].protein * value[id].quantity / 100).toFixed(2) : 0}γρ.</Text>
        </Box>
        <Box h='100' bgColor={'lightBrand'} borderRadius={5} p={2} border={'1px'} color="gray.400"   align="center" pt={1}>
          <Text>Υδατάνθρακες {value[id] !== null &&  value[id].quantity !== null && value[id].quantity !==undefined ? (value[id].carbs * value[id].quantity / 100).toFixed(2) : 0}γρ.</Text>
        </Box>
        <Box h='100' bgColor={'lightBrand'} borderRadius={5} p={2} border={'1px'} color="gray.400"   align="center" pt={1}>
          <Text>Λιπαρά {value[id] !== null && value[id].quantity !== null && value[id].quantity !==undefined ? (value[id].fat * value[id].quantity / 100).toFixed(2) : 0}γρ.</Text>
        </Box>
       
        <VStack minW="20%">
          <Tag size="sm"     color="gray.400"   align="center" >
            <Text>Όνομα</Text>
          </Tag>
          <Input fontSize={'xs'} size="md" onChange={
            (e) => {
              if(value[id] === null){
                return;
              }
              setLabel(e.target.value);
            }
          } value={label } maxW={'100%'}/>
          <Button size="xs" onClick={() => {
            if(value[id] === null){
              return;
            }
            axios.put('/api/auth/update_food_label', {
              code: value[id].code,
              label: label
            }).then((res) => {
              console.debug("res", res);
              toast({
                title: "Επιτυχής αποθήκευση",
                description: "Το όνομα του τροφίμου αποθηκεύτηκε με επιτυχία",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }
            ).catch((err) => {
              console.error(err);
              toast({
                title: "Αποτυχία αποθήκευσης",
                description: "Παρουσιάστηκε σφάλμα κατά την αποθήκευση του όνοματος του τροφίμου",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            });
          }} colorScheme="blue" variant="outline">
            Αποθήκευση
          </Button>
          </VStack>
      </HStack>
    </ModalBody>
  );
}
