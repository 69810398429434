/* eslint-disable */
import {
  Flex,
  Avatar,
  Box,
  Icon,
  Button,
  useColorModeValue,
  Spinner,
  Text,
  RangeSlider,
  Wrap,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderMark,
  HStack,
  Select,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { Link } from "react-router-dom";

import { FiPlus, FiTrash2, FiUser } from "react-icons/fi";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "contexts/UserContext";
import axios from "axios";
import { Table } from "react-chakra-pagination";
import { useInterval } from "views/admin/default/components/Tasks";
import CreateCouponModal from "./CreateCouponModal";

export default function DevelopmentTable(props) {
  const { user, setUser } = useContext(UserContext);
  const [list, setList] = useState([]);
  const [error, setError] = useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [userList, setUserList] = useState([]);
  const [page, setPage] = React.useState(1);
  const [ageMarker, setAgeMarker] = useState([0, 100]);
  const [searchText, setSearchText] = useState("");
  const [memberships, setMemberships] = useState([
    "all",
    "free",
    "standard",
    "premium",
  ]);
  const [sex, setSex] = useState(["male", "female", null]);

  useEffect(() => {
    getClients()
      .then((value) => {
        setError(null);
        setList(value);
        setUserList(value);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, []);

  useInterval(() => {
    getClients().then((value) => {
      if (value.length !== userList.length) {
        console.log("update");
        setUserList(value);
        applyFilters(searchText, sex);
      }
    });
  }, 1000 * 10);

  async function getClients() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/client_info");
    console.debug("Clients", res.data);
    return res.data;
  }

  function onSearch(e) {
    const search = e.target.value.toLowerCase();
    setSearchText(search);
    applyFilters(search, sex);
  }

  function applyFilters(search, sex, newMemberships, age) {
    setList(
      userList.filter(
        (user) =>
          user.fullName.toLowerCase().includes(search) &&
          (age === undefined
            ? user.age >= ageMarker[0] && user.age <= ageMarker[1]
            : user.age >= age[0] && user.age <= age[1]) &&
          sex.includes(user.sex) &&
          (newMemberships === undefined
            ? memberships.includes(user.account_type)
            : newMemberships.includes(user.account_type))
      )
    );
  }
  // Formatter for each user
  if (userList === undefined)
    return error !== null ? (
      <Flex>
        <Box p="12" />{" "}
        <Text size="xl" fontWeight="bold" fontSize="xl">
          {" "}
          {error.message}
        </Text>
      </Flex>
    ) : (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  const tableData = list.map((user, id) => {
    return {
      id: id,
      name: (
        <Flex align="center" key={id}>
          <Avatar name={user.fullName} size="sm" mr="4" />
          <Link
            to={"/admin/profile/" + user.client_id}
            color={textColor}
            fontSize="sm"
            fontWeight="700"
          >
            {user.fullName}
          </Link>
        </Flex>
      ),
      email: user.email,
      phone: user.phone_number,
      age: user.age,
      membership: user.account_type,
      action: (
        <Button
          colorScheme="gray"
          onClick={() => console.log("remove user!")}
          size="sm"
        >
          <Icon as={FiTrash2} fontSize="20" />
        </Button>
      ),
    };
  });

  // Accessor to get a data in user object
  const tableColumns = [
    {
      Header: "Όνομα",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Τηλέφωνο",
      accessor: "phone",
    },
    {
      Header: "Ηλικία",
      accessor: "age",
    },
    {
      Header: "Συνδρομή",
      accessor: "membership",
    },
    {
      Header: "",
      accessor: "action",
    },
  ];

  return (
    <Box p="12">
      <Wrap spacing="10" justifyContent={"space-around"}>
        <Box
          p="1"
          align="center"
          alignItems="center"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          height="75%"
        >
          <SearchBar onSearch={onSearch} placeholder="Όνομα πελάτη" />
        </Box>
        <Flex align="center">
          <Text size="sm">Ηλικία</Text>
          <Box width="10" />
          <Box pt={6} pb={2}>
            <RangeSlider
              defaultValue={[0, 100]}
              min={0}
              max={100}
              step={1}
              minW="200px"
              onChange={(value) => {
                if (value === undefined || value === null) return;
                setAgeMarker(value);
                applyFilters(searchText, sex, memberships, value);
              }}
              colorScheme="blue"
              width="35%"
            >
              <RangeSliderTrack bg="blue.100">
                <RangeSliderFilledTrack bg="blue" />
              </RangeSliderTrack>
              <RangeSliderThumb boxSize={6} index={0} />
              <RangeSliderThumb boxSize={6} index={1} />
              <RangeSliderMark
                value={ageMarker != null ? ageMarker[0] : 0}
                textAlign="center"
                align="center"
                color="black"
                mt={2}
              >
                {ageMarker[0]}
              </RangeSliderMark>
              <RangeSliderMark
                value={ageMarker != null ? ageMarker[1] : 100}
                textAlign="center"
                color="black"
                mt={2}
              >
                {ageMarker[1]}
              </RangeSliderMark>
            </RangeSlider>
          </Box>
        </Flex>
        <HStack spacing={4}>
          <Select
            size="sm"
            onChange={(e) => {
              if (e.target.value === "all") {
                setMemberships(["all", "free", "standard", "premium"]);
                applyFilters(searchText, sex, [
                  "all",
                  "free",
                  "standard",
                  "premium",
                ]);
              } else if (e.target.value === "free") {
                setMemberships(["free"]);
                applyFilters(searchText, sex, ["free"]);
              } else if (e.target.value === "standard") {
                setMemberships(["standard"]);
                applyFilters(searchText, sex, ["standard"]);
              } else if (e.target.value === "premium") {
                setMemberships(["premium"]);
                applyFilters(searchText, sex, ["premium"]);
              }
            }}
          >
            <option value="all">Όλοι</option>
            <option value="free">Free</option>
            <option value="premium">Premium</option>
            <option value="standard">Standard</option>
          </Select>
        </HStack>

        <Select
          width="200px"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "all") {
              const sexArray = ["male", "female", null];
              setSex(sexArray);
              applyFilters(searchText, sexArray);
              return;
            } else if (value === "male") {
              setSex(["male"]);
              applyFilters(searchText, ["male"]);
              return;
            }
            setSex(["female"]);
            applyFilters(searchText, ["female"]);
            console.debug(sex);
          }}
        >
          <option value="all">Όλοι</option>
          <option value="male">Άνδρες</option>
          <option value="female">Γυναίκες</option>
        </Select>
      </Wrap>
      <Box mt="6">
        <Table
          colorScheme="blue"
          // Fallback component when list is empty
          emptyData={{
            icon: FiUser,
            text: "Δεν βρέθηκαν πελάτες",
          }}
          totalRegisters={list.length}
          page={page}
          // Listen change page event and control the current page using state
          onPageChange={(page) => setPage(page)}
          columns={tableColumns}
          data={tableData}
        />
      </Box>
      <CreateCouponModal/>
    </Box>
  );
}
