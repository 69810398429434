import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Wrap,
  Input,
  Select,
  Spacer,
  toast,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { MdOutlineAdd } from "react-icons/md";
export function AddFoodModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [food, setFood] = useState({
    category: "",
    name: "",
    protein: 0,
    fat: 0,
    carbs: 0,
    calories: 0,
    alcochol: 0,
    water: 0,
    caffeine: 0,
    sugars: 0,
    fibers: 0,
    calcium: 0,
    ferrum: 0,
    magnisium: 0,
    phosphorus: 0,
    potassium: 0,
    natrium: 0,
    zinc: 0,
    copper: 0,
    selinium: 0,
    vitaminA: 0,
    vitaminE: 0,
    vitaminD: 0,
    vitaminC: 0,
    vitaminB1: 0,
    vitaminB2: 0,
    vitaminB3: 0,
    vitaminB6: 0,
    vitaminB12: 0,
    choline: 0,
    vitaminK: 0,
    cholesterol: 0,
    follicles: 0,
    saturated: 0,
    omega3: 0,
    omega6: 0,
    monounsaturated: 0,
    polyunsaturated: 0,
  });
  function handleFoodChange(name, e) {
    const oldFood = food;
    oldFood[name] = e;
    setFood(oldFood);
  }
  async function addFood(food) {
    
    console.debug(food);
    if(food.name === ""){
      toast({
        title: "Προβλημα",
        description: "Πρέπει να δώσεις όνομα στο φαγητό",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    axios.post("/api/auth/add_food", {macros:food}).then((res) => {
      toast({
        title: "Επιτυχής προσθήκη",
        description: "Το φαγητό προστέθηκε επιτυχώς",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      console.debug(res);
    }).catch((err) => {
      toast({
        title: "Πρόβλημα",
        description: "Υπήρξε κάποιο πρόβλημα με την προσθήκη του φαγητού",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.error(err);
    })

  }
  return (
    <>
      <Button variant="outline" leftIcon={<MdOutlineAdd />} onClick={onOpen}>
        Προσθήκη τροφίμου
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Προσθήκη τροφίμου</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Wrap spacing="30px">
              <Input
                type="text"
                placeholder="Όνομα τροφίμου"
                onChange={(event) => {
                  food["name"] = event.target.value;
                  setFood(food);
                  
                }}
              />
              <Select
                placeholder="Κατηγορία"
                value={food["category"] === "" ? null : food["category"]}
                onChange={(event) =>
                  handleFoodChange("category", event.target.value)
                }
              >
                <option value="dairy">Γαλακτοκομικό</option>
                <option value="meat">Κρέας</option>
                <option value="recipes">Συνταγή</option>
                <option value="vegetables">Λαχανικό</option>
                <option value="fruits">Φρούτο</option>
                <option value="nuts">Καρπός</option>
                <option value="legumes">Όσπριο</option>
                <option value="sugar">Ζάχαρη</option>
                <option value="cereals">Δημητριακό</option>
                <option value="beverages">Ποτό</option>
              </Select>
              <Input
                type="number"
                placeholder="Πρωτείνη"
                value={food["protein"] === 0 ? null : food["protein"]}
                onChange={(event) =>
                  handleFoodChange("protein", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Υδατάνθρακες"
                value={food["carbs"] === 0 ? null : food["carbs"]}
                onChange={(event) =>
                  handleFoodChange("carbs", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Λιπαρά"
                value={food["fat"] === 0 ? null : food["fat"]}
                onChange={(event) =>
                  handleFoodChange("fat", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Θερμίδες"
                value={food["calories"] === 0 ? null : food["calories"]}
                onChange={(event) =>
                  handleFoodChange("calories", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Αλκοόλ"
                value={food["alcochol"] === 0 ? null : food["alcochol"]}
                onChange={(event) =>
                  handleFoodChange("alcochol", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Νερό"
                value={food["water"] === 0 ? null : food["water"]}
                onChange={(event) =>
                  handleFoodChange("water", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Καφείνη"
                value={food["caffeine"] === 0 ? null : food["caffeine"]}
                onChange={(event) =>
                  handleFoodChange("caffeine", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Σάκχαρα"
                value={food["sugars"] === 0 ? null : food["sugars"]}
                onChange={(event) =>
                  handleFoodChange("sugars", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Φυτικές Ίνες"
                value={food["fibers"] === 0 ? null : food["fibers"]}
                onChange={(event) =>
                  handleFoodChange("fibers", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Σίδηρο"
                value={food["ferrum"] === 0 ? null : food["ferrum"]}
                onChange={(event) =>
                  handleFoodChange("ferrum", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Μαγνήσιο"
                value={food["magnisium"] === 0 ? null : food["magnisium"]}
                onChange={(event) =>
                  handleFoodChange("magnisium", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Φωσφόρο"
                value={food["phosphorus"] === 0 ? null : food["phosphorus"]}
                onChange={(event) =>
                  handleFoodChange("phosphorus", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Κάλιο"
                value={food["potassium"] === 0 ? null : food["potassium"]}
                onChange={(event) =>
                  handleFoodChange("potassium", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Νάτριο"
                value={food["natrium"] === 0 ? null : food["natrium"]}
                onChange={(event) =>
                  handleFoodChange("natrium", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Ψευδάργυρος"
                value={food["zinc"] === 0 ? null : food["zinc"]}
                onChange={(event) =>
                  handleFoodChange("zinc", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Χαλκός"
                value={food["copper"] === 0 ? null : food["copper"]}
                onChange={(event) =>
                  handleFoodChange("copper", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Σελίνιο"
                value={food["selinium"] === 0 ? null : food["selinium"]}
                onChange={(event) =>
                  handleFoodChange("selinium", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Ασβέστιο"
                value={food["calcium"] === 0 ? null : food["calcium"]}
                onChange={(event) =>
                  handleFoodChange("calcium", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Χολίνη"
                value={food["choline"] === 0 ? null : food["choline"]}
                onChange={(event) =>
                  handleFoodChange("choline", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Χοληστερίνη"
                value={food["cholesterol"] === 0 ? null : food["cholesterol"]}
                onChange={(event) =>
                  handleFoodChange("cholesterol", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Φολικό οξύ"
                value={food["follicles"] === 0 ? null : food["follicles"]}
                onChange={(event) =>
                  handleFoodChange("follicles", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Κορεμένα"
                value={food["saturated"] === 0 ? null : food["saturated"]}
                onChange={(event) =>
                  handleFoodChange("saturated", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Μονοακόρεστα"
                value={food["monounsaturated"] === 0 ? null : food["monounsaturated"]}
                onChange={(event) =>
                  handleFoodChange("monounsaturated", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Πολυακόρεστα"
                value={food["polyunsaturated"] === 0 ? null : food["polyunsaturated"]}
                onChange={(event) =>
                  handleFoodChange("polyunsaturated", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Α"
                value={food["vitaminA"] === 0 ? null : food["vitaminA"]}
                onChange={(event) =>
                  handleFoodChange("vitaminA", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Ε"
                value={food["vitaminE"] === 0 ? null : food["vitaminE"]}
                onChange={(event) =>
                  handleFoodChange("vitaminE", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη D"
                value={food["vitaminD"] === 0 ? null : food["vitaminD"]}
                onChange={(event) =>
                  handleFoodChange("vitaminD", event.target.value)
                }
              />

              <Input
                type="number"
                placeholder="Βιταμίνη Κ"
                value={food["vitaminK"] === 0 ? null : food["vitaminK"]}
                onChange={(event) =>
                  handleFoodChange("vitaminK", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Β1"
                value={food["vitaminB1"] === 0 ? null : food["vitaminB1"]}
                onChange={(event) =>
                  handleFoodChange("vitaminB1", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Β2"
                value={food["vitaminB2"] === 0 ? null : food["vitaminB2"]}
                onChange={(event) =>
                  handleFoodChange("vitaminB2", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Β3"
                value={food["vitaminB3"] === 0 ? null : food["vitaminB3"]}
                onChange={(event) =>
                  handleFoodChange("vitaminB3", event.target.value)
                }
              />

              <Input
                type="number"
                placeholder="Βιταμίνη Β6"
                value={food["vitaminB6"] === 0 ? null : food["vitaminB6"]}
                onChange={(event) =>
                  handleFoodChange("vitaminB6", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη Β12"
                value={food["vitaminB12"] === 0 ? null : food["vitaminB12"]}
                onChange={(event) =>
                  handleFoodChange("vitaminB12", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Βιταμίνη C"
                value={food["vitaminC"] === 0 ? null : food["vitaminC"]}
                onChange={(event) =>
                  handleFoodChange("vitaminC", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Ωμέγα 3"
                value={food["omega3"] === 0 ? null : food["omega3"]}
                onChange={(event) =>
                  handleFoodChange("omega3", event.target.value)
                }
              />
              <Input
                type="number"
                placeholder="Ωμέγα 6"
                value={food["omega6"] === 0 ? null : food["omega6"]}
                onChange={(event) =>
                  handleFoodChange("omega6", event.target.value)
                }
              />
            </Wrap>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Πίσω
            </Button>
            <Spacer />
            <Button colorScheme="blue" mr={3} onClick={
              async () => {
                await addFood(food);
                onClose();
              }
            }>
              Προσθήκη
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
