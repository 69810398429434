// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useToast,

} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

// Assets
import { MdCalendarToday,MdNotifications } from "react-icons/md";
import React, { useEffect } from "react";
import Meeting from "views/admin/default/components/Meeting.js";
import { useRef } from "react";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "contexts/UserContext";
export default function Conversion(props) {
  const { user, setUser } = useContext(UserContext);
  const { ...rest } = props;
  const toast = useToast();
  const [tasks, setTasks] = React.useState([]);
  const sameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  async function getTasks() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const response = await axios
      .get("/api/auth/get_meetings")
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα σύνδεσης",
          description: "Δεν μπορέσαμε να συνδεθούμε στον server",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
    const messages = await axios.get("/api/auth/get_messages");
    if (response.data) {
      if(messages.data)
      {
        const emp_id =  JSON.parse(sessionStorage.getItem("user"))['user_id'];
        messages.data.forEach(element => {
          if(element.receiver_id === emp_id && sameDate(new Date(element.date), new Date()))
          response.data.push({
            meeting_id: element.message_id,
            date: element.date,
            type: 'message',
            title: element.sender,
            client_id: emp_id === element.sender_id ? element.receiver_id : element.sender_id,
            comments: element.message
          });
        });
      }
      setTasks(response.data);
      console.debug(response.data);
    }
  }
  useEffect(() => {
    getTasks();
  }, []);

  useInterval(() => {
    getTasks();
  }, 1000 * 10);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card p="20px" align="center" direction="column" w="100%" maxH={'800px'} overflowY={'auto'} {...rest}>
      <Flex alignItems="center" w="100%" mb="30px">
        <Text color={textColor} fontSize="lg" fontWeight="700">
          Γεγονότα Ημέρας
        </Text>
      </Flex>
      <Box px="11px">
        {tasks.map((task) => (
          <Meeting
            key={task.meeting_id} 
            time={new Date(task.date).toLocaleTimeString()}
            title={task.type === 'meeting' ? 'Συνάντηση' : task.type ==='message' ? task.title :'Ειδοποίηση'}
            buttonText={task.type === 'meeting' ? "Πήγαινε" : "Δες"}
            link={task.type === 'meeting' ? task.link : '#/admin/profile/'+ task.client_id}
            icon={task.type === 'meeting' ? MdCalendarToday : MdNotifications}
            notes={task.comments}
          />
        ))}
      </Box>
    </Card>
  );
}
export const useInterval = (callback, delay) => {

  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}