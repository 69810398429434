// Chakra imports
import {
  Button,
  CircularProgress,
  SimpleGrid,
  Text,
  Textarea,
  useColorModeValue,
  Wrap,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";
import Banner from "views/admin/profile/components/Banner.js";
import banner from "assets/img/auth/banner.png";
import {
  MdCalendarViewWeek,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { FileModal } from "./Files";
import { Metrics } from "./Metrics";
import { HistoryModal } from "./History";

// Assets
export default function GeneralInformation(props) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const {
    name,
    address,
    phone,
    subscription,
    email,
    notes,
    birthdate,
    gender,
    id,
  } = props;
  console.debug("props:", props);
  if (props.name === undefined && props.birthdate === "Invalid Date") {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  // Chakra Color Mode
  function handleOnBlur(event) {
    if(event.target.value !== notes){
    console.debug(event.target.value); 
    let body = {
      client_id: id,
      comments: event.target.value,
    };
    axios
    .put("api/auth/update_client", body)
    .then((res) => {
      console.debug("res:", res);
      
    })
    .catch((err) => {
      console.error("err:", err);
    });
    } 
  }

  return (
    <Card
      mb={{ base: "0px", "2xl": "20px" }}
      overflowX='hidden'
      overflowY="auto"
      maxHeight="1000px"
    >
      <Banner
        gridArea="1 / 1 / 2 / 2"
        banner={banner}
        name={name ? name : "unknown"}
        gender={gender}
      ></Banner>
      <Wrap spacing="30px" justify="center">
        <NavLink to={"/admin/fullCalendar/" + id + "/" + gender}>
          {" "}
          <Button variant="outline" leftIcon={<MdCalendarViewWeek />}>
            Διατροφολόγιο
          </Button>
        </NavLink>

        <Metrics client_id={id}/>

       <HistoryModal client_id={id}/>


        <FileModal client_id={id} /> 
      </Wrap>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Γενικές Πληροφορίες
      </Text>
      <Textarea color={textColorSecondary} fontSize="md" me="26px" mb="40px"
      onBlur={handleOnBlur}
      defaultValue={notes}
      >
        
      </Textarea>
      <SimpleGrid columns="2" gap="20px">
        <Information
          boxShadow={cardShadow}
          id={id}
          title="Address"
          value={address}
        />
        <Information
          boxShadow={cardShadow}
          id={id}
          title="Phone"
          value={phone}
        />

        <Information
          boxShadow={cardShadow}
          title="Birthday"
          id={id}
          value={birthdate === "Invalid Date" ? null : birthdate}
        />
        <Information
          boxShadow={cardShadow}
          title="Subscription"
          id={id}
          value={subscription}
        />
        <Information
          boxShadow={cardShadow}
          id={id}
          title="Email"
          value={email}
        />
      </SimpleGrid>
    </Card>
  );
}
