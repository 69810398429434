// Chakra imports
import {
  Text,
  useColorModeValue,
  Box,
  HStack,
  Icon,
  Button,
  Textarea,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState, useContext } from "react";
import MealPlan from "views/admin/profile/components/MealPlan";
// Assets
import { useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import { UserContext } from "contexts/UserContext";

import { MdNoFood } from "react-icons/md";
import { TotalsComponent } from "./Totals.js";
import { useEffect } from "react";
import { AddIcon } from "@chakra-ui/icons";
import CreateMealModal from "views/admin/meals/components/CreateAMealModal";
export default function Meals(props) {
  const { user } = useContext(UserContext);

  const [mealPlanList, setMealPlanList] = useState([]);
  const { name, client_id, refresh } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openMore, setOpenMore] = useState(false);
  const date = "2000-10-03T00:00:00.000Z";
  async function getMealPlanList() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.post("/api/auth/view_diet_plan", {
      client_id: client_id,
      date: date,
    });
    console.debug("res.data", res.data);
    return res.data;
  }

  const goals = JSON.parse(localStorage.getItem("goals"));

  useEffect(() => {
    getMealPlanList()
      .then((res) => {
        setMealPlanList(res);
      })
      .catch((err) => {
        console.error("err", err);
        setMealPlanList([]);
      });
  }, [date]);

  async function closeEditMeal() {
    getMealPlanList()
      .then((res) => {
        console.info("res", res);
        setMealPlanList(res);
      })
      .catch((err) => {
        console.error("err", err);
        console.error("err", err);
        setMealPlanList([]);
      });
    onClose();
  }
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const cardBorder = useColorModeValue("gray.200", "gray.300");
  return (
    <Card
      mb={{ base: "0px", "2xl": "20px" }}
      borderColor={cardBorder}
      overflowX="auto"
      overflowY="auto"
      
    >
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2lg"
        mt="10px"
        mb="4px"
      >
        Ανάκληση 24ώρου
      </Text>

      {mealPlanList !== undefined && mealPlanList.length > 0 ? (
        mealPlanList.map((mealPlan, id) => (
          <MealPlan
            key={id}
            meal={mealPlan}
            date={date}
            onOpen={onOpen}
            close={closeEditMeal}
            isOpen={isOpen}
            client_id={client_id}
          />
        ))
      ) : (
        <Card bg={cardShadow} borderColor={cardBorder}>
          <HStack spacing="4px" mt="10px">
            <Icon as={MdNoFood} size="48px" color={textColorSecondary} />
            <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
              Δεν υπάρχουν γεύματα
            </Text>
          </HStack>
        </Card>
      )}
      {mealPlanList.length < 5 ? (
        <>
          <Button
            onClick={onOpen}
            variantcolor="teal"
            variant="outline"
            size="md"
            p={4}
            mr="4px"
            mb="4px"
            leftIcon={<AddIcon />}
          >
            Προσθήκη γεύματος
          </Button>
          <CreateMealModal
            isOpen={isOpen}
            onClose={closeEditMeal}
            date={new Date(date)}
            client_id={client_id}
            showType={true}
          />
        </>
      ) : null}
      {mealPlanList !== undefined && mealPlanList.length > 0
        ? TotalsComponent(mealPlanList, goals, setOpenMore, openMore)
        : null}

      <Textarea placeholder="Σχόλια" />
    </Card>
  );
}
