import {
  Box,
  Button,
  Select,
  VStack,
  Flex,
  Center,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Text,
  Progress,
  Spacer,
  Textarea,
  Input,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import { UserContext } from "contexts/UserContext";
import { useContext } from "react";
import { FiEdit } from "react-icons/fi";
import {
  MdAddCircle,
  MdAddCircleOutline,
  MdCheckCircle,
  MdDeleteOutline,
  MdDone,
  MdFileCopy,
  MdInfoOutline,
  MdOutlineContentPaste,
  MdOutlineFavorite,
} from "react-icons/md";
import AsyncSelect from "react-select/async";
import { useState } from "react";
import SearchFood from "views/admin/meals/components/SearchFood";
import Card from "components/card/Card";
import { seeMoreModal } from "views/admin/meals/components/CreateAMealModal";
import { useEffect } from "react";
import addMeal, { addFavoriteMeal } from "./AddAMeal";

export default function EditDrawer(props) {
  const { isOpen, onClose, selectValue, meal, client_id, isDelete, date } =
    props;
  const { user } = useContext(UserContext);
  const [foods, setFoods] = useState(meal.consists_of);
  const [micros, setMicros] = useState(meal.micros);
  const [type, setType] = useState(selectValue);
  const [openMore, setOpenMore] = useState(false);
  const [copied, setCopied] = useState(false);
  const [allDayMicros, setAllDayMicros] = useState([]);
  const [searchFavorite, setSearchFavorite] = useState(null);
  const [showSearchFavorite, setShowSearchFavorite] = useState(false);
  const [addedToFavorite, setAddedToFavorite] = useState(false);
  const [name, setName] = useState("");
  const [comments, setComments] = useState(meal.comments);
  const goals = JSON.parse(localStorage.getItem("goals"));
  async function editMeal() {
    const codes = [...foods.map((e) => e.code)];
    const quantities = [...foods.map((e) => e.quantity)];
    await addMeal(
      date,
      user,
      codes,
      quantities,
      meal.meal_type,
      client_id,
      comments
    );
  }

  function doCopy() {
    localStorage.setItem("meal", JSON.stringify(meal.consists_of));
    setCopied(true);
  }

  useEffect(() => {
    getMealPlanList()
      .then((data) => {
        console.debug(data);
        const foods = meal.consists_of.map((e) => {
          e.protein = (e.protein / e.quantity) * 100;
          e.carbs = (e.carbs / e.quantity) * 100;
          e.fat = (e.fat / e.quantity) * 100;
          e.calories = (e.calories / e.quantity) * 100;
          for (const label in e.micros) {
            e.micros[label] = (e.micros[label] / e.quantity) * 100;
          }
          return e;
        });
        const allDayMicros = data.filter((e) => e.meal_type !== selectValue);
        console.debug(allDayMicros);
        setAllDayMicros(allDayMicros);
        hanleValueChange(foods, allDayMicros);
      })
      .catch((err) => {
        console.log("error getting meal plan list", err);
      });
  }, [date, meal]);

  async function getMealPlanList() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    console.debug("date edi", date);
    const res = await axios.post("/api/auth/view_diet_plan", {
      client_id: client_id,
      date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
    });
    return res.data;
  }
  function handleNameChange(e) {
    setName(e.target.value);
  }
  function hanleValueChange(food, mealPlanList) {
    setFoods(food);
    let micros_list = {};
    food.map((item, id) => {
      if (item === null) return item;
      if (item.micros) {
        item.micros["protein"] = item.protein;
        item.micros["carbs"] = item.carbs;
        item.micros["fat"] = item.fat;
        item.micros["calories"] = item.calories;
      }
      if (
        item.quantity === 0 ||
        item.quantity === null ||
        Number.isNaN(item.quantity) ||
        item.quantity === undefined
      ) {
        for (let label in item.micros !== undefined ? item.micros : item)
          micros_list[label] = 0;
        return item;
      }
      for (let label in item.micros !== undefined ? item.micros : item) {
        if (label === "quantity") continue;
        micros_list[label] = food.reduce((acc, cur) => {
          return (
            acc +
            (cur === null
              ? 0
              : ((cur.micros !== undefined ? cur.micros[label] : cur[label]) *
                  cur.quantity) /
                100)
          );
        }, 0);
      }
      return item;
    });

    let other_meals = mealPlanList.map((item) => {
      const temp = {
        protein: item.protein,
        carbs: item.carbs,
        fat: item.fat,
        calories: item.calories,
      };

      for (let label in item.micros) {
        temp[label] = item.micros[label];
      }
      return temp;
    });
    console.info("other_meals", other_meals);
    for (let label in other_meals[0]) {
      micros_list[label] += other_meals.reduce((a, b) => a + b[label], 0);
    }
    setMicros(micros_list);
    console.debug("micros", micros_list);
  }
  async function getMeals() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/retrieve_meals");
    const data = res.data;
    return data;
  }
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getMeals({ filter: inputValue }).then((meals) => {
        resolve(
          meals.map((meal) => {
            return {
              meal_id: meal.meal_id,
              name: meal.meal_name,
              consists_of: meal.consists_of,
              label: meal.meal_name,
              meal_type: meal.meal_type,
              calories: meal.calories,
              protein: meal.protein,
              carbs: meal.carbs,
              fat: meal.fat,
              micros: meal.micros,
            };
          })
        );
      });
    });
  return (
    <Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="6xl"
        motionPreset="slideInBottom"
        scrollBehavior={"inside"}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader borderBottomWidth="1px">
            <HStack spacing={4} alignItems="center">
              <Icon as={FiEdit} mr={3} />
              <Text>Επεξεργασία γεύματος</Text>
              <Spacer />
              <Button
                leftIcon={<FiEdit />}
                variant="outline"
                bgColor={"blue.100"}
                variantColor="blue"
                onClick={async () => {
                  await editMeal();
                  onClose();
                }}
              >
                Αποθήκευση
              </Button>
              <Button
                leftIcon={copied ? <MdDone /> : <MdFileCopy />}
                variant="ghost"
                onClick={doCopy}
              >
                {copied ? "Αντιγράφθηκε" : "Αντιγραφή"}
              </Button>
              <Button
                leftIcon={<MdOutlineContentPaste />}
                variant="ghost"
                onClick={() => {
                  const meal = JSON.parse(localStorage.getItem("meal"));
                  if (meal) {
                    console.debug(meal);
                    if (meal === undefined || meal === null) return;

                    console.debug("pasted_foods", meal);
                    setFoods([...foods, ...meal]);
                    hanleValueChange([...foods, ...meal], allDayMicros);
                  }
                }}
              >
                Επικόλληση
              </Button>
              {isDelete === false ? null : (
                <Button
                  leftIcon={<MdDeleteOutline />}
                  color="black"
                  bgColor={"red.400"}
                  onClick={async () => {
                    await deleteMeal(meal.meal_id);
                    onClose();
                  }}
                >
                  Διαγραφή
                </Button>
              )}
              <Box width="5%" />
            </HStack>
            <Box width="100%" height="1px" bgColor="gray.300" m={2} />
            <Box height="10px" />
            <HStack spacing={4} alignItems="start">
              <Button
                leftIcon={<MdAddCircle />}
                colorScheme="blue"
                color="white"
                onClick={() => setFoods([...foods, { food: "", quantity: 0 }])}
              >
                Προσθήκη τροφίμου
              </Button>

              <Button
                ml={3}
                variant="outline"
                bgColor={"blue.100"}
                leftIcon={<MdAddCircleOutline />}
                onClick={() => setShowSearchFavorite(!showSearchFavorite)}
              >
                Προσθήκη γεύματος
              </Button>
              <Spacer />
              <Input
                width="50%"
                value={name}
                onChange={handleNameChange}
                placeholder="Δώσε όνομα στο γεύμα"
              />
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5}>
              <Select
                px={2}
                py={2}
                value={type}
                onChange={(e) => {
                  console.debug(e.target.value);
                  setType(e.target.value);
                }}
              >
                <option value="breakfast">Πρωινό</option>
                <option value="lunch">Μεσημεριανό</option>
                <option value="dinner">Βραδινό</option>
                <option value="snack1">Σνακ1</option>
                <option value="snack2">Σνακ2</option>
              </Select>
            </VStack>
            <Box h={5} />
            <Box h={5} />
            {foods.map((e, id) => (
              <HStack key={id} width="100%" height="100%">
                {" "}
                <SearchFood
                  key={id}
                  value={foods}
                  id={id}
                  setValue={(c) => hanleValueChange(c, allDayMicros)}
                />
                <Box alignSelf={"flex-start"} pt={5}>
                  {" "}
                  <Button
                    float="center"
                    align="top"
                    leftIcon={<MdDeleteOutline />}
                    onClick={() => {
                      const index = foods.indexOf(foods[id]);
                      if (index === -1) {
                        return;
                      }
                      const deletedValues = foods.splice(index, 1);
                      setFoods(foods.filter((e) => !deletedValues.includes(e)));
                      hanleValueChange(foods, allDayMicros);
                    }}
                  />
                </Box>{" "}
              </HStack>
            ))}

            <Box px={2} width="100%">
              {showSearchFavorite ? (
                <AsyncSelect
                  width="100%"
                  name="meals"
                  align="center"
                  value={searchFavorite}
                  px={10}
                  placeholder="Επιλέξτε γεύμα"
                  defaultOptions={meal}
                  loadOptions={promiseOptions}
                  closeMenuOnSelect={true}
                  size="lg"
                  onChange={(meal) => {
                    if (meal === undefined || meal === null) return;
                    const favorite_foods = meal.consists_of.map((e) => {
                      e.protein = (e.protein / e.quantity) * 100;
                      e.carbs = (e.carbs / e.quantity) * 100;
                      e.fat = (e.fat / e.quantity) * 100;
                      e.calories = (e.calories / e.quantity) * 100;
                      for (const label in e.micros) {
                        e.micros[label] = (e.micros[label] / e.quantity) * 100;
                      }
                      return e;
                    });
                    console.debug("favorite_foods", favorite_foods);
                    setFoods([...foods, ...favorite_foods]);
                    hanleValueChange(
                      [...foods, ...favorite_foods],
                      allDayMicros
                    );
                    setSearchFavorite(null);
                    setShowSearchFavorite(false);
                  }}
                  isClearable={true}
                />
              ) : null}
            </Box>
            {foods.length > 0 && goals !== null && micros !== undefined ? (
              <Card width="100%" rounded={"lg"}>
                <VStack>
                  <Text>
                    Θερμίδες:{" "}
                    {micros.calories !== undefined
                      ? micros.calories.toFixed(2)
                      : 0}{" "}
                    kcal (
                    {goals && goals.macros !== undefined
                      ? (
                          (micros.calories / goals.macros.calories) *
                          100
                        ).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="green"
                    hasStripe
                    value={micros.calories}
                    max={goals && goals.macros !== undefined ? goals.macros.calories : 0}
                  />
                  <Text>
                    Πρωτεΐνες:{" "}
                    {micros.protein !== undefined
                      ? micros.protein.toFixed(2)
                      : 0}
                    γρ (
                    {goals && goals.macros !== undefined
                      ? ((micros.protein / goals.macros.protein) * 100).toFixed(
                          2
                        )
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    hasStripe
                    value={micros.protein}
                    max={goals && goals.macros !== undefined ? goals.macros.protein : 0}
                  />
                  <Text>
                    Υδατάνθρακες:{" "}
                    {micros.carbs !== undefined ? micros.carbs.toFixed(2) : 0}γρ
                    (
                    {goals && goals.macros !== undefined
                      ? ((micros.carbs / goals.macros.carbs) * 100).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="yellow"
                    hasStripe
                    value={micros.carbs}
                    max={goals && goals.macros !== undefined ? goals.macros.carbs : 0}
                  />
                  <Text>
                    Λιπαρά:{" "}
                    {micros.fat !== undefined ? micros.fat.toFixed(2) : 0}γρ (
                    {goals && goals.macros !== undefined
                      ? ((micros.fat / goals.macros.fat) * 100).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="red"
                    hasStripe
                    value={micros.fat}
                    max={goals && goals.macros !== undefined ? goals.macros.fat : 0}
                  />

                  <Button
                    leftIcon={<MdInfoOutline />}
                    onClick={() => setOpenMore(true)}
                  >
                    Περισσότερα
                  </Button>
                  <Textarea
                    width="100%"
                    placeholder={"Σχόλια για το γεύμα"}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </VStack>
              </Card>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justify="space-between">
              {addedToFavorite ? (
                <Box borderRadius={20} padding={3} margin={2}>
                  <HStack spacing={3}>
                    <Text fontSize="md" fontWeight="bold">
                      Προστέθηκε!
                    </Text>
                    <Icon as={MdCheckCircle} color="green.500" />
                  </HStack>
                </Box>
              ) : (
                <Button
                  mr={2}
                  bgColor={"red.100"}
                  leftIcon={<MdOutlineFavorite />}
                  onClick={async () => {
                    const codes = [...foods.map((e) => e.code)];
                    console.log(codes);
                    const quantities = [...foods.map((e) => e.quantity)];
                    const res = await addFavoriteMeal(
                      name,
                      codes,
                      quantities,
                      client_id,
                      type,
                      user
                    );
                    if (res === null) {
                      return;
                    }
                    setAddedToFavorite(true);
                  }}
                >
                  Αποθήκευση στα αγαπημένα
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {foods.length > 0 && goals !== null && micros !== undefined
        ? seeMoreModal(openMore, setOpenMore, micros, goals)
        : null}
    </Box>
  );
}
export async function deleteMeal(id) {
  const data = await axios.delete(`/api/auth/delete_diet_meal/${id}`);
  return data;
}
