import axios from "axios";

export default async function get_client_goals(client_id) {
  const res = await axios.get(`/api/auth/get_client_goals/${client_id}`);
  const data = res.data;
  const percentages = await axios.get(
    `/api/auth/get_percentages/${client_id}/1`
  );
  const percentages_data = percentages.data;
  for (const goal of Object.keys(data.micros)) {
    console.log("goal", goal);
    const max_goal = percentages_data[`${goal}`]["max_goal"];
    if (max_goal !== undefined) {
      if(data['max_goals'] === undefined){
        data['max_goals'] = {};
        }
      data['max_goals'][`${goal}`] = max_goal;
    }
  }
  console.log("Goals:", data);
  console.log("Percentages:", data.max_goals);
  return data;
}
