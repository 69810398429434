import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import DatePicker from "react-date-picker";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { MdContentPaste, MdDateRange, MdDoneOutline } from "react-icons/md";
import axios from "axios";
import addMeal from "./AddAMeal";
import { useContext } from "react";
import { UserContext } from "contexts/UserContext";
import AsyncSelect from "react-select/async";

export default function CustomDatePicker(props) {
  const { client_id, open, onChange } = props;
  const { user } = useContext(UserContext);
  const [value, setValue] = useState(new Date());
  const [modalOpen] = useState(open);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [client, setClient] = useState(null);
  async function pasteFoods(id) {
    setLoading(true);
    const dates = getDates(value, fromDate);
    let mealplans = [];
    let newDate = toDate;
    dates.forEach(async (date) => {
      const mealplan = await axios.post(`/api/auth/view_diet_plan`, {
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        client_id: client_id,
      });
      if (mealplan.status !== 404) {
        //add meals
        mealplan.data.map((meal) => {
          const codes = meal.consists_of.map((e) => e.code);
          const quantities = meal.consists_of.map((e) => e.quantity);
          const type = meal.meal_type;
          const comments = meal.comments;
          addMeal(newDate, user, codes, quantities, type, id, comments);
        });
        mealplans.push(mealplan.data);
        newDate = newDate.addDays(1);
      }
    });
    setLoading(false);
    setComplete(true);
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      axios.defaults.headers.common["auth-token"] = `${user}`;
      axios.get("/api/auth/client_info").then((clients) => {
        resolve(
          clients.data.map((client) => ({
            client_id: client.client_id,
            label: client.fullName,
          }))
        );
      });
    });

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }
  return (
    <Modal
      isOpen={modalOpen}
      onClose={onChange}
      size="xl"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Icon as={MdDateRange} mr={3} />
          Διάλεξε ημερομηνίες
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Box>
              <Text>Loading...</Text>
            </Box>
          ) : (
            <Box h={450}>
              <Text fontSize="lg">Αντιγραφή από</Text>
              <HStack spacing={4}>
                <DatePicker onChange={setValue} value={value} />
                <Text fontSize="lg">-&gt;</Text>
                <DatePicker onChange={setFromDate} value={fromDate} />
              </HStack>

              <Box h={10} />
              <Text fontSize="lg">Επικόλληση σε</Text>
              <HStack spacing={4}>
                <DatePicker onChange={setToDate} value={toDate} />
                <Text fontSize="lg">-&gt;</Text>
                <DatePicker
                  disabled
                  value={
                    toDate === null || fromDate === null || value === null
                      ? new Date()
                      : new Date(
                          toDate.getTime() +
                            (fromDate.getTime() - value.getTime())
                        )
                  }
                />
              </HStack>
              <Box h={10} />
              <AsyncSelect
                placeholder={"Επιλέξτε πελάτη"}
                loadOptions={promiseOptions}
                cacheOptions
                defaultOptions
                onChange={(e) => {
                  console.log(e);
                  if (e !== null) {
                    setClient(e.client_id);
                    setComplete(false);
                  }
                }}
                isSearchable
                loadingMessage={() => "Φόρτωση..."}
                isClearable
                noOptionsMessage={() => "Δεν βρέθηκαν αποτελέσματα"}
                size="lg"
              />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onChange}>
            Ακύρωση
          </Button>
          <Button
            onClick={async () => {
              if (client !== null) {
                await pasteFoods(client);
              }
            }}
            leftIcon={complete ? <MdDoneOutline /> : <MdContentPaste />}
            colorScheme="blue"
            mr={3}
          >
            {complete ? "Ολοκληρώθηκε" : "Επικόλληση"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
