import {
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightAddon,
  Text,
  Box,
  Heading,
  useColorModeValue,
  Button,
  Flex,
  Spacer,
  Wrap,
  Icon,
  Spinner,
  HStack,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import { IoMdNutrition, IoMdReturnRight } from "react-icons/io";
import Card from "components/card/Card";
import { MdInfo ,MdError} from "react-icons/md";
import Recipe from "./components/Recipe";
export default function Meal() {
  const { user, setUser } = useContext(UserContext);
  const { id } = useParams();
  const [meal, setMeal] = React.useState({});
  const [error, setError] = React.useState(null);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [value, setValue] = React.useState("");
  useEffect(() => {
    getMeal(id)
      .then((data) => {
        setMeal(data);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        console.error("error", err);
      });
  }, []);

  async function getMeal(id) {
    if (id === null || id === undefined || isNaN(id)) {
      throw new Error("Invalid meal");
    }
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/view_meal/"+id, { meal_id: id });
    const data = res.data;
    if(data.message !== undefined && data.message === 'Invalid meal_id'){
      throw new Error("Invalid meal");
    }
    return data;
  }

  if (error !== null) {
    return (
      <Box pt={{ base: "200px", md: "180px", xl: "180px" }}>
      <Flex>
        <Box p="12" />
        <Icon as={MdError}  w={8} h={8} color="red.500" />
        <Box width={5} />
        <Heading >
         {error}
        </Heading>
      </Flex>
      </Box>
    );
  }
  if (meal === {} || meal === null || meal === undefined)   {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
      </Box>
    );
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
      <Box h={10} />
      <Stack spacing={4}>
        <HStack>
          <Icon size="24px" as={MdInfo} />
          <Text fontSize="xl">Λεπτομέρειες</Text>
        </HStack>
        <InputGroup>
          <InputLeftAddon children="Όνομα" />
          <Input
            color={textColorPrimary}
            type="text"
            defaultValue={meal.name}
          />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Θερμίδες" />
          <Input
            color={textColorPrimary}
            type="number"
            defaultValue={meal.calories}
          />
        </InputGroup>
        <HStack>
          <Icon size="24px" as={IoMdNutrition} />
          <Text fontSize="xl">Macros</Text>
        </HStack>
        <InputGroup>
          <InputLeftAddon children="Πρωτείνες" />
          <Input
            color={textColorPrimary}
            type="number"
            defaultValue={meal.protein !== undefined ? meal.protein.toFixed(2) : meal.protein}
          />
          <InputRightAddon children="γρ." />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Υδατάνθρακες" />
          <Input
            color={textColorPrimary}
            type="number"
            defaultValue={meal.carbs !== undefined ? meal.carbs.toFixed(2) : meal.carbs}
          />
          <InputRightAddon children="γρ." />
        </InputGroup>
        <InputGroup>
          <InputLeftAddon children="Λιπαρά" />
          <Input
            color={textColorPrimary}
            type="number"
            defaultValue={meal.fat !== undefined ? meal.fat.toFixed(2) : meal.fat}
          />
          <InputRightAddon children="γρ." />
        </InputGroup>

        <Text fontSize="xl">Τρόφιμα</Text>
        {meal.consists_of !== undefined
          ? meal.consists_of.map((item, id) => {
              return (
                <Card key={id} >
                <Flex align="center" >
                  <Icon as={IoMdReturnRight} mr={3} />
                  <Text fontSize="lg" fontWeight="bold">
                    {item.name}
                  </Text>
                  <Spacer />
                  <Text fontSize="md" fontWeight='light'>
                    {item.quantity} γραμμάρια
                  </Text>
                </Flex>
                
              </Card>
              );
            })
          : null}
            <Textarea
        value={value}
            onChange={(e) => setValue(e.target.value)}
        placeholder="Γράψτε την συνταγή σας εδώ"
        
        style={{ height: "300px", width: "100%" }}
      />
          
      </Stack>
      <Box h={10} />
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box />
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            window.history.back();
          }}
        >
          Πίσω
        </Button>
        <Spacer />
        <Recipe name={meal.name} value={value} consists_of={meal.consists_of} protein={meal.protein} carbs={meal.carbs} fat={meal.fat} calories={meal.calories}/>
       
      </Flex>
    </Box>
  );
}
