// Chakra imports
import {
  Box,
  Grid,
  SimpleGrid,
  Wrap,
  Flex,
  Spinner,
  Heading,
  Icon,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

// Custom components
import General from "views/admin/profile/components/General";
// Assets
import { useState, useEffect, useContext } from "react";
import Notifications from "./components/Notifications";
import WeeklyReview from "views/admin/default/components/WeeklyReview";
import axios from "axios";
import { UserContext } from "contexts/UserContext";
import { MdError } from "react-icons/md";
import get_client_goals from "views/admin/default/helpers/get_client_goals.js";
import Card from "components/card/Card";
import Chat from "./components/Chat";

export default function Overview() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(false);
  const [date, setDate] = useState(new Date());
  const [client, setClient] = useState({});
  const [error, setError] = useState(null);
  const cardBorder = useColorModeValue("gray.200", "gray.300");

  useEffect(() => {
    getClient(id)
      .then((data) => {
        setClient(data[0]);

        setError(null);
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      });
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  async function getClient(id) {
    if (id === null || id === undefined || isNaN(id)) {
      throw new Error("Invalid client id");
    }
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/view_client/" + id, {
      client_id: id,
    });
    get_client_goals(id)
      .then((data) => {
        console.info("Goals", data);
        localStorage.setItem("goals", JSON.stringify(data));
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
    const data = res.data;
    return data;
  }

  const onChangeDate = (date) => {
    setDate(date);
  };
  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  if (error !== null) {
    return (
      <Box pt={{ base: "200px", md: "180px", xl: "180px" }}>
        <Flex>
          <Box p="12" />
          <Icon as={MdError} w={8} h={8} color="red.500" />
          <Box width={5} />
          <Heading>{error}</Heading>
        </Flex>
      </Box>
    );
  }
  if (client === {}) {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }
  if (isMobile)
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Wrap templateColumns="repeat(2, 1fr)" gap={2}>
          <General
            name={client.fullName}
            address={client.address}
            phone={client.phone_number}
            subscription={client.account_type}
            email={client.email}
            birthdate={new Date(client.birth_date).toLocaleDateString()}
            id={id}
            gender={client.sex}
            notes={client.comments}
          />
          <SimpleGrid spacing={10} width="100%">
            <Notifications w="100%" name={client.fullName} client_id={id} />
            <Card borderColor={cardBorder} width="100%" height="100%">
              {" "}
              <Chat id={id} user={user} name={client.fullName}/>{" "}
            </Card>
            <Box h={20}/>
          </SimpleGrid>

          <WeeklyReview h="100%" id={id} />
        </Wrap>
      </Box>
    );
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <General
          name={client.fullName}
          address={client.address}
          phone={client.phone_number}
          subscription={client.account_type}
          email={client.email}
          birthdate={new Date(client.birth_date).toLocaleDateString()}
          id={id}
          gender={client.sex}
          notes={client.comments}
        />
        <Notifications name={client.fullName}  client_id={id}/>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap={2}>
        <GridItem colSpan={2}>
          <WeeklyReview h="100%" id={id} />
        </GridItem>
        <GridItem colSpan={1}>
          <Card
            borderColor={cardBorder}
            width="100%"
            height="100%"
            p={4}
            centerContent
            float="right"
            flexDirection="column"
          >
            <Box width="100%" height="100%">
              <Chat id={id} user={user} name={client.fullName} />
            </Box>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}
