import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdLock,
  MdFastfood,
  MdOutlineCalendarToday,
  MdPersonSearch,
  MdQueryStats,
  MdOutlineBusinessCenter,
  MdBusiness,
  MdOutlineArticle
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Meals from "views/admin/meals";
import Meal from "views/admin/meal";
import FullCalendar from "views/admin/fullCalendar";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Stats from "views/admin/stats";
import Business from "views/admin/business";
import BusinessProfile from "views/admin/business_profile";
import Articles from "views/admin/articles";
const routes = [
  {
    name: "Αρχική",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  
  {
    name: "Πελάτες",
    layout: "/admin",
    icon: <Icon as={MdPersonSearch} width='20px' height='20px' color='inherit' />,
    path: "/clients",
    component: DataTables,
  },
  {
    name: "Business / Consulting",
    layout: "/admin",
    icon: <Icon as={MdOutlineBusinessCenter} width='20px' height='20px' color='inherit' />,
    path: "/business",
    component: Business,
  },
  {
    name: "Αγαπημένα / Συνταγές",
    layout: "/admin",
    icon: <Icon as={MdFastfood} width='20px' height='20px' color='inherit' />,
    path: "/meals",
    component: Meals,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile/:id",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Business Profile",
    layout: "/admin",
    path: "/business_profile/:id",
    icon: <Icon as={MdBusiness} width='20px' height='20px' color='inherit' />,
    component: BusinessProfile,
  },
  {
    name: "Calendar",
    layout: "/admin",
    path: "/fullCalendar/:id/:gender",
    icon: <Icon as={MdOutlineCalendarToday} width='20px' height='20px' color='inherit' />,
    component: FullCalendar,
  },
  {
    name: "Meal",
    layout: "/admin",
    path: "/meal/:id",
    icon: <Icon as={MdFastfood} width='20px' height='20px' color='inherit' />,
    component: Meal,
  },
  {
    name: "Στατιστικά",
    layout: "/admin",
    path: "/stats",
    icon: <Icon as={MdQueryStats} width='20px' height='20px' color='inherit' />,
    component: Stats,
  },
  {
    name: "Δημιουργία Άρθρου",
    layout: "/admin",
    path: "/create_article",
    icon: <Icon as={MdOutlineArticle} width='20px' height='20px' color='inherit' />,
    component: Articles,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

export default routes;
