
import React from "react";

import CreateMealModal from "views/admin/meals/components/CreateAMealModal";


export default function InsertMealModal(props) {
  const { isOpen, onClose, date, client_id ,type} = props;
  return (
   <CreateMealModal isOpen={isOpen} onClose={onClose} date={date} client_id={client_id} meal_type={type} />
  );
}
