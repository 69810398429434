import { Box, Button, Textarea } from "@chakra-ui/react";
import jsPDF from "jspdf";

import React, { useState } from "react";
import { MdDoDisturb, MdPrint } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import { font } from "./roboto-normal";
export default function Recipe(props) {
  const { name, consists_of, protein, carbs, fat, calories, value } = props;
  const doc = new jsPDF({
    unit: "pt",
    format: "a4",
    floatPrecision: 16,
    filters: ["ASCIIHexEncode"],
  });

  return (
    <Box>
      <Button
        leftIcon={<MdPrint />}
        colorScheme="blue"
        mx={2}
        onClick={() => {
          doc.setFontSize(12);
          console.debug(doc.getFontList());
          doc.addFileToVFS("roboto.ttf", font);
          doc.addFont("roboto.ttf", "roboto", "normal");
          doc.setFont("roboto");
          doc.setFontSize(25);
          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, 595, 842, "F");
          doc.text("Συνταγή για " + name, 15, 25);
          doc.setFontSize(12);
          doc.text("Υλικά:", 15, 42);
          const lines = consists_of.length;

          consists_of.forEach((element, index) => {
            doc.text(
              element.name.trim() + " : " + element.quantity + "γρ.",
              15,
              45 + (index + 1) * 15
            );
          });

          doc.text(
            "Πρωτεΐνες : " +
              (protein !== undefined ? protein.toFixed(2) : protein) +
              "γρ.",
            15,
            50 + (lines + 1) * 20
          );
          doc.text(
            "Υδατάνθρακες : " +
              (carbs !== undefined ? carbs.toFixed(2) : carbs) +
              "γρ.",
            15,
            50 + (lines + 2) * 20
          );
          doc.text(
            "Λιπαρά : " + (fat !== undefined ? fat.toFixed(2) : fat) + "γρ.",
            15,
            50 + (lines + 3) * 20
          );
          doc.text(
            "Συνολικές θερμίδες : " +
              (calories !== undefined ? calories.toFixed(2) : calories) +
              "kcal.",
            15,
            50 + (lines + 4) * 20
          );
          doc.setFontSize(12);
          doc.text("Οδηγίες:", 15, 55 + (lines + 5) * 20);
          //replace /n with <br>
          const text = value.replace(/\n/g, "<br />");
          console.debug(text);
          doc.html(text, {
            callback: function (pdf) {
              pdf.save(`${name}.pdf`);
            },
            x: 20,
            y: 0,
            margin: [125 + (lines + 6) * 10, 5, 5, 5],
            width: 500,
            windowWidth: 500,
            autoPaging: "text", //text, slice, false
          });
        }}
      >
        Εκτύπωση
      </Button>
    </Box>
  );
} // add custom font to file
