import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import axios from "axios";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { useState } from "react";
import { useEffect } from "react";

import { MdBarChart, MdFileCopy, MdMonetizationOn, MdOutlineFireExtinguisher, MdOutlineFreeBreakfast, MdOutlineFreeCancellation, MdOutlineGeneratingTokens, MdOutlineLink, MdOutlineMoneyOff, MdPerson } from "react-icons/md";

export default function Stats() {
  const [totalClients, setTotalClients] = useState([]);

  useEffect(() => {
    getClients().then((data) => {
      console.log("Clients", data);
      setTotalClients(data);
    });
  }, []);

  async function getClients() {
    const res = await axios.get("/api/auth/client_info");
    console.debug("Clients", res.data);
    return res.data;
  }
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Άνδρες"
          value={totalClients.filter((client) => client.sex === "male").length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Γυναίκες"
          value={
            totalClients.filter((client) => client.sex === "female").length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Άνω των 25"
          value={totalClients.filter((client) => client.age > 25).length}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name="Σύνολο πελατών"
          value={totalClients.length}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineMoneyOff}
                  color={brandColor}
                />
              }
            />
          }
          name="Free Πελάτες"
          value={
            totalClients.filter((client) => client['account_type'] === "free").length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdMonetizationOn}
                  color={brandColor}
                />
              }
            />
          }
          name="Premium Πελάτες"
          value={
            totalClients.filter((client) => client['account_type'] === "premium").length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdPerson}
                  color={brandColor}
                />
              }
            />
          }
          name="Standard Πελάτες"
          value={
            totalClients.filter((client) => client['account_type'] === "standard").length
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineGeneratingTokens}
                  color={brandColor}
                />
              }
            />
          }
          name="Μέσος όρος ηλικίας"
          value={
            (
              totalClients.reduce((acc, client) => acc + client.age, 0) /
              totalClients.length
            ).toFixed(2) + " ετών"
          }
        />
       </SimpleGrid> 
    <Box 
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt="20px"
      color="brand.500"
      fontWeight="bold"
      fontSize="xl"
      borderBottom="1px solid"
      borderColor="brand.500"
      pb="10px"

    >
      <a href="https://dashboard.stripe.com/" >
        
        Δες όλα τα στατιστικά στο Stripe
        <Icon as={MdOutlineLink} ml="5px" />
      </a>
      

      </Box>
    </Box>
  );
}
