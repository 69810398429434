import { Box, SimpleGrid, Text, Textarea, useColorModeValue, Wrap } from "@chakra-ui/react";
import Card from "components/card/Card";
import Banner from "../profile/components/Banner";
import { FileModal } from "../profile/components/Files";
import { HistoryModal } from "../profile/components/History";
import Information from "../profile/components/Information";
import banner from "assets/img/auth/banner.png";

export default function BusinessProfile() {
    const name = "BusinessProfile";
    const id = "BusinessProfile";
    const notes = "BusinessProfile";
    const phone = "BusinessProfile";
    const email = "BusinessProfile";
    const address = "BusinessProfile";
    const birthdate = "BusinessProfile";
    const gender = "BusinessProfile";
    const subscription = "BusinessProfile";
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const cardShadow = useColorModeValue(
      "0px 18px 40px rgba(112, 144, 176, 0.12)",
      "unset"
    );
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        mb={{ base: "0px", "2xl": "20px" }}
        overflowX="hidden"
        overflowY="auto"
        maxHeight="1000px"
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          name={name ? name : "unknown"}
          gender={gender}
        ></Banner>
        <Wrap spacing="30px" justify="center">
          <HistoryModal client_id={id} />
          <FileModal />
        </Wrap>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Γενικές Πληροφορίες
        </Text>
        <Textarea
          color={textColorSecondary}
          fontSize="md"
          me="26px"
          mb="40px"
          
          defaultValue={notes}
        ></Textarea>
        <SimpleGrid columns="2" gap="20px">
          <Information
            boxShadow={cardShadow}
            id={id}
            title="Address"
            value={address}
          />
          <Information
            boxShadow={cardShadow}
            id={id}
            title="Phone"
            value={phone}
          />

          <Information
            boxShadow={cardShadow}
            title="Birthday"
            id={id}
            value={birthdate === "Invalid Date" ? null : birthdate}
          />
          <Information
            boxShadow={cardShadow}
            title="Subscription"
            id={id}
            value={subscription}
          />
          <Information
            boxShadow={cardShadow}
            id={id}
            title="Email"
            value={email}
          />
        </SimpleGrid>
      </Card>
    </Box>
  );
}
