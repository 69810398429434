import { useState, useEffect } from "react";
import axios from "axios";
import {
  Text,
  useColorModeValue,
  Button,
  Box,
  HStack,
  Progress,
  Spacer,
  Heading,
} from "@chakra-ui/react";
import { seeMoreModal } from "views/admin/meals/components/CreateAMealModal";
import { MdInfo } from "react-icons/md";

export default function Totals(props) {
  const { id, date, user } = props;
  const goals = JSON.parse(localStorage.getItem("goals"));
  async function getMealPlanList() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.post("/api/auth/view_diet_plan", {
      client_id: id,
      date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
    });
    return res.data;
  }

  useEffect(() => {
    getMealPlanList()
      .then((res) => {
        setMealPlanList(res);
        console.debug("meal plan list:", res);
      })
      .catch((err) => {
        console.error("err", err);
        setMealPlanList([]);
      });
  }, [date]);

  const [mealPlanList, setMealPlanList] = useState([]);
  const [openMore, setOpenMore] = useState(false);

  if (mealPlanList !== undefined && mealPlanList.length > 0 && goals !== null)
    return TotalsComponent(
      mealPlanList,
      goals,
      setOpenMore,
      openMore,
      "Συνολικά διατροφικά στοιχεία σήμερα"
    );
  else {
    return <Box></Box>;
  }
}
export function TotalsComponent(
  mealPlanList,
  goals,
  setOpenMore,
  openMore,
  text
) {
  const isUndefined = (element) =>
    element.micros === undefined || element.protein === undefined;
    console.debug("mealPlanList", mealPlanList);
  return (
    <Box>
      <Heading size="md">{text}</Heading>
      <Box h={"10vh"} />
      <Box width="100%" borderRadius={15}>
        <HStack spacing="4px" mt="10px">
          <Text fontSize="md" fontWeight="400" color={"grey"}>
            Θερμίδες:{" "}
            {mealPlanList
              .reduce((acc, curr) => acc + curr.calories, 0)
              .toFixed(2)}
            kcal
          </Text>
          <Text
            fontSize="md"
            fontWeight="400"
            fontStyle="italic"
            color={"grey"}
          >
            ({" "}
            {goals && goals.macros !== undefined
              ? (
                  (mealPlanList.reduce((acc, curr) => acc + curr.calories, 0) /
                    goals.macros.calories) *
                  100
                ).toFixed(2)
              : 0}{" "}
            % )
          </Text>
        </HStack>
        <Progress
          bgColor={"gray.500"}
          width="100%"
          colorScheme="green"
          hasStripe
          isAnimated
          value={mealPlanList
            .reduce((acc, curr) => acc + curr.calories, 0)
            .toFixed(2)}
          max={goals && goals.macros !== undefined ? goals.macros.calories : 0}
        />

        <HStack spacing="4px" mt="10px">
          <Text fontSize="md" fontWeight="400" color={"grey"}>
            Πρωτείνες:{" "}
            {mealPlanList
              .reduce((acc, curr) => acc + curr.protein, 0)
              .toFixed(2)}{" "}
           γ
          </Text>
          <Text
            fontSize="md"
            fontWeight="400"
            fontStyle="italic"
            color={"grey"}
          >
            ({" "}
            {goals && goals.macros !== undefined
              ? (
                  (mealPlanList.reduce((acc, curr) => acc + curr.protein, 0) /
                    goals.macros.protein) *
                  100
                ).toFixed(2)
              : 0}{" "}
            % )
          </Text>
        </HStack>
        <Progress
          bgColor={"gray.500"}
          hasStripe
          isAnimated
          width="100%"
          value={mealPlanList
            .reduce((acc, curr) => acc + curr.protein, 0)
            .toFixed(2)}
          max={goals && goals.macros !== undefined ? goals.macros.protein : 0}
        />
        <HStack spacing="4px" mt="10px">
          <Text fontSize="md" fontWeight="400" color={"grey"}>
            Υδατάνθρακες:{" "}
            {mealPlanList.reduce((acc, curr) => acc + curr.carbs, 0).toFixed(2)}{" "}
           γ
          </Text>
          <Text
            fontSize="md"
            fontWeight="400"
            fontStyle="italic"
            color={"grey"}
          >
            ({" "}
            {goals && goals.macros !== undefined
              ? (
                  (mealPlanList.reduce((acc, curr) => acc + curr.carbs, 0) /
                    goals.macros.carbs) *
                  100
                ).toFixed(2)
              : 0}{" "}
            % )
          </Text>
        </HStack>
        <Progress
          bgColor={"gray.500"}
          hasStripe
          isAnimated
          colorScheme="yellow"
          width="100%"
          value={mealPlanList
            .reduce((acc, curr) => acc + curr.carbs, 0)
            .toFixed(2)}
          max={goals && goals.macros !== undefined ? goals.macros.carbs : 0}
        />

        <HStack spacing="4px" mt="10px">
          <Text fontSize="md" fontWeight="400" color={"grey"}>
            Λιπαρά:{" "}
            {mealPlanList.reduce((acc, curr) => acc + curr.fat, 0).toFixed(2)}γ
          </Text>
          <Text
            fontSize="md"
            fontWeight="400"
            fontStyle="italic"
            color={"grey"}
          >
            ({" "}
            {goals && goals.macros !== undefined
              ? (
                  (mealPlanList.reduce((acc, curr) => acc + curr.fat, 0) /
                    goals.macros.fat) *
                  100
                ).toFixed(2)
              : 0}{" "}
            % )
          </Text>
        </HStack>
        <Progress
          bgColor={"gray.500"}
          hasStripe
          isAnimated
          colorScheme="red"
          width="100%"
          value={mealPlanList
            .reduce((acc, curr) => acc + curr.fat, 0)
            .toFixed(2)}
          max={goals && goals.macros !== undefined ? goals.macros.fat : 0}
        />
        <Box h={"10vh"} />
        <Button
          onClick={() => setOpenMore(true)}
          variantcolor="teal"
          variant="outline"
          size="md"
          p={4}
          mt="5px"
          mr="4px"
          mb="4px"
          leftIcon={<MdInfo />}
        >
          Δες περισσότερα
        </Button>
      </Box>
      {!mealPlanList.some(isUndefined)
        ? seeMoreModal(
            openMore,
            setOpenMore,
            {
              protein: mealPlanList.reduce(
                (acc, curr) => acc + curr.protein,
                0
              ),
              carbs: mealPlanList.reduce((acc, curr) => acc + curr.carbs, 0),
              fat: mealPlanList.reduce((acc, curr) => acc + curr.fat, 0),
              calories: mealPlanList.reduce(
                (acc, curr) => acc + curr.calories,
                0
              ),
              calcium: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.calcium,
                0
              ),
              alcochol: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.alcochol,
                0
              ),
              caffeine: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.caffeine,
                0
              ),
              choline: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.choline,
                0
              ),
              copper: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.copper,
                0
              ),
              ferrum: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.ferrum,
                0
              ),
              fibers: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.fibers,
                0
              ),
              follicles: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.follicles,
                0
              ),
              magnisium: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.magnisium,
                0
              ),
              monounsaturated: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.monounsaturated,
                0
              ),
              polyunsaturated: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.polyunsaturated,
                0
              ),
              potassium: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.potassium,
                0
              ),
              saturated: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.saturated,
                0
              ),
              phosphorus: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.phosphorus,
                0
              ),
              selinium: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.selinium,
                0
              ),
              sugars: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.sugars,
                0
              ),
              vitaminA: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminA"],
                0
              ),
              vitaminB1: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminB1"],
                0
              ),
              vitaminB2: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminB2"],
                0
              ),
              vitaminB3: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminB3"],
                0
              ),
              vitaminB12: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminB12"],
                0
              ),
              vitaminB6: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminB6"],
                0
              ),
              vitaminC: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminC"],
                0
              ),
              vitaminD: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminD"],
                0
              ),
              vitaminE: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminE"],
                0
              ),
              vitaminK: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["vitaminK"],
                0
              ),
              natrium: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.natrium,
                0
              ),
              zinc: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.zinc,
                0
              ),
              water: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros.water,
                0
              ),
              omega3: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["omega3"],
                0
              ),
              omega6: mealPlanList.reduce(
                (acc, curr) => acc + curr.micros["omega6"],
                0
              ),
            },
            goals
          )
        : null}
    </Box>
  );
}
