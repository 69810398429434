import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Flex,
  Icon,
  VStack,
  useInterval,
  useToast,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import axios from "axios";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { MdFileDownload, MdFilePresent, MdOutlineViewAgenda, MdViewModule } from "react-icons/md";
import Dropzone from "./Dropzone";
export function FileModal(props) {
  const { client_id } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [files, setFiles] = useState([]);
  const toast = useToast();
  useEffect(() => {
    axios
      .get(`api/auth/get_files/${client_id}`)
      .then((res) => {
        console.log(res);
        setFiles(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onUpload = (file) => {
    setFiles([...files, file]);
    toast({
      title: "Επιτυχία",
      description: "Το αρχείο ανέβηκε επιτυχώς",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <>
      <Button variant="outline" leftIcon={<MdFileDownload />} onClick={onOpen}>
        Αρχεία
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Αρχεία</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Απεσταλμένα</Tab>
              </TabList>
              <TabPanels>
                <TabPanel overflowY="auto">
                  <VStack spacing={4}>
                    <Dropzone onUpload={onUpload} id={client_id}></Dropzone>
                    {files.map((file) => {
                      return <File id={file.file_id} name={file.name} />;
                    })}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Πίσω
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function File(props) {
  const { name, id } = props;
  return (
    <Card bg={"gray.100"} borderRadius={"lg"} boxShadow={"lg"} w={"100%"}>
      <Flex direction="row" justify="space-between">
        <Flex direction={"row"} justify="start">
          <Icon as={MdFilePresent} w={6} h={6} />
          <Text align="center" alignItems="center">
            {name}
          </Text>
        </Flex>
        <Button
          variant="outline"
          onClick={() => {
            axios
              .get(`api/auth/download_file/${id}`, {
                responseType: "blob",
              })
              .then((res) => res.data)
              .then((blob) => {
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus();
              });
          }}
          leftIcon={<MdOutlineViewAgenda />}
        >
          Άνοιγμα
        </Button>
      </Flex>
    </Card>
  );
}
