import { Box, Progress, Text } from "@chakra-ui/react";



export default function MicroProgress(props){
    const {title,value,measurement,goal,color} = props;
    return (
            <Box width="100%">
            <Text align="center">{title} ({value.toFixed(2)} {measurement === undefined ? 'mg' : measurement} ) { goal !== 0 ? (value/goal * 100).toFixed(2) : (value *100).toFixed(2)} % </Text>
            <Progress
                bgColor={'gray.500'}
                width="100%"
                colorScheme={color !== undefined ? color :( value > goal) ? 'red' : 'green'}
                hasStripe
                isAnimated
                max={goal}
                value={value} />
            </Box>
        );
    
}