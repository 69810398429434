import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text,
    useDisclosure,
    IconButton,
  } from "@chakra-ui/react";
  import React from "react";
  import { FiTrash2 } from "react-icons/fi";
  
  function DeleteAllTask({ deleteTaskAll }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <>
        <Button
          // colorScheme='red'
          px='8'
          h='45'
          color='red.500'
          mt='8'
          onClick={onOpen}
        >
          Διαγραφή όλων
        </Button>
  
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w='90%'>
            <ModalHeader>Είσαι σίγουρος ότι θες να τα διαγράψεις όλα;</ModalHeader>
            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Όχι
              </Button>
              <Button colorScheme='red' onClick={() => deleteTaskAll()}>
                Ναι
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  
  function DeleteTask({ task, deleteTask }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
  
    return (
      <>
        <IconButton icon={<FiTrash2 />} isRound='true' onClick={onOpen} />
  
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent w='90%'>
            <ModalHeader>Είσαι σίγουρος;</ModalHeader>
            <ModalBody>
              <Text>{task.task}</Text>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Όχι
              </Button>
              <Button
                colorScheme='red'
                onClick={() => deleteTask(task.task_id, onClose)}
              >
                Ναι
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
  
  export { DeleteTask as DeleteTodo, DeleteAllTask as DeleteAllTodo };