// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Button,
  HStack,
  Spacer,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";

import { useDisclosure } from "@chakra-ui/react";
import EditDrawer from "views/admin/fullCalendar/components/EditDrawer";
export default function MealPlan(props) {
  const { meal, type, ranking, date, image, close, client_id, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleCancel() {
    onClose();
    close();
  }

  return (
    <Card width="100%" rounded={"lg"} bg={bg} {...rest} p="2px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        <Box width="100%" mt={{ base: "10px", md: "0" }}>
          <HStack>
            <Text
              color={textColorPrimary}
              fontWeight="500"
              fontSize="md"
              mb="4px"
            >
              •{""}{" "}
              {meal.meal_type === "breakfast"
                ? "Πρωινό"
                : meal.meal_type === "lunch"
                ? "Μεσημεριανό"
                : meal.meal_type === "dinner"
                ? "Βραδινό"
                : "Σνακ"}
            </Text>
            <Spacer />
            <Button bg={bg} align="center" alignItems="center" onClick={onOpen}>
              <Text color={textColorSecondary} fontSize="sm">
                Επεξεργασία
              </Text>
            </Button>
          </HStack>
          {meal.consists_of.map((item, index) => (
            <HStack spacing="4px" key={index}>
              <Card bg={bg} borderRadius="full">
                <Text
                  width={{ base: "100%", md: "auto" }}
                  fontWeight="500"
                  color={textColorSecondary}
                  fontSize="sm"
                  me="4px"
                  key={index}
                >
                  {item.food}
                </Text>
              </Card>
              <Spacer />
              <Card bg={bg} borderRadius="full" p="4px" border="1px">
                <Text
                  width={{ base: "80%", md: "auto" }}
                  align="center"
                  fontWeight="500"
                  color={textColorSecondary}
                  fontSize="sm"
                  key={index}
                >
                  {item.quantity} γρ.
                </Text>
              </Card>
            </HStack>
          ))}
        </Box>
        { isOpen ?
          <EditDrawer
            isOpen={isOpen}
            onClose={handleCancel}
            meal={meal}
            selectValue={meal.meal_type}
            date={new Date(date)}
            client_id={client_id}
          /> : null
        }
      </Flex>
    </Card>
  );
}
