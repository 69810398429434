import { useState, useEffect } from "react";
import axios from "axios";
import {
  Text,
  Button,
  Box,
  Flex,
  Input,
  Avatar,
  AvatarBadge,
  useInterval,
} from "@chakra-ui/react";

export default function Chat(props) {
  const { id, user, name } = props;
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  async function getMessages() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    axios
      .get(`/api/auth/get_client_messages/${id}`)
      .then((res) => {
        console.log(res.data);
        setMessages(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getMessages();
  }, []);

  useInterval(() => {
    getMessages();
  }, 10000);

  async function handleSendMessage() {
    setInputMessage("");
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.post("/api/auth/send_message", {
      receiver_id: id,
      message: inputMessage,
    });
    if (res.data.success) {
      getMessages();
    }
  }
console.log(id);
  return (
    <Box h={"100%"} maxH={"600px"}>
      <Flex w="100%">
        <Avatar size="lg" name={name}>
          <AvatarBadge boxSize="1.25em" bg="green.500" />
        </Avatar>
        <Flex flexDirection="column" mx="5" justify="center">
          <Text fontSize="lg" fontWeight="bold">
            {name}
          </Text>
        </Flex>
      </Flex>
      <Flex h="75%" overflowY="auto" flexDirection="column" p="3">
        
        {messages.map((item, index) => {
          if (item.sender_id !== parseInt(id,10)) {
            return (
              <Flex key={index} w="100%" justify="flex-end" borderRadius={10}>
                <Flex
                  bg="blue.500"
                  color="white"
                  minW="100px"
                  maxW="300px"
                  my="1"
                  p="3"
                  borderRadius={10}
                >
                  <Text
                    fontSize="xx-small"
                    color="white.200"
                    mr="2"
                    maxWidth={"50px"}
                  >
                    {new Date(item.date).toLocaleDateString()}
                  </Text>
                  <Text maxWidth={"180px"}>{item.message}</Text>
                </Flex>
              </Flex>
            );
          } else {
            return (
              <Flex key={index} w="100%" borderRadius={10} justify="flex-start">
                <Avatar name={name} bg="blue.300"></Avatar>
                <Flex
                  bg="gray.100"
                  color="black"
                  minW="100px"
                  maxW="350px"
                  my="1"
                  p="3"
                >
                  <Text
                    fontSize="xx-small"
                    color="white.200"
                    mr="2"
                    maxWidth={"50px"}
                  >
                    {new Date(item.date).toLocaleDateString()}
                  </Text>
                  <Text maxWidth={"180px"}>{item.message}</Text>
                </Flex>
              </Flex>
            );
          }
        })}
      </Flex>
      <Flex w="100%" mt="5">
        <Input
          placeholder="Γράψε ένα μήνυμα..."
          border="none"
          borderRadius="none"
          _focus={{
            border: "1px solid black",
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        <Button
          bg="black"
          color="white"
          borderRadius="none"
          _hover={{
            bg: "white",
            color: "black",
            border: "1px solid black",
          }}
          disabled={inputMessage.trim().length <= 0}
          onClick={handleSendMessage}
        >
          Αποστολή
        </Button>
      </Flex>
    </Box>
  );
}
