import axios from "axios";
export default async function addMeal(
  date,
  user,
  codes,
  quantities,
  type,
  client_id,
  comments
) {
  axios.defaults.headers.common["auth-token"] = `${user}`;
  const new_date = new Date(date);
  const date_string = `${new_date.getFullYear()}-${
    new_date.getMonth() + 1
  }-${new_date.getDate()}`;
  for (const label in codes) {
    if (codes[label] === undefined) {
      console.log("undefined code");
      return;
    }
  }

  if (codes.length === 0 && quantities.length === 0) {
    return;
  }

  quantities = quantities.map((quantity) => {
    if (quantity === undefined) {
      return 1;
    }
    return quantity;
  });

  const res = await axios.put("/api/auth/insert_meal", {
    consists_of: codes,
    quantities: quantities,
    client_id: client_id,
    date: date_string,
    meal_type: type.toLowerCase(),
    comments: comments,
  });
  const data = res.data;
  return data;
}

export function addFavoriteMeal(
  name,
  codes,
  quantities,
  client_id,
  meal_type,
  user
) {
  if (name === "") {
    alert("Παρακαλώ δώστε όνομα στο προτεινόμενο γεύμα");
    return null;
  }
  if (
    codes.length === 0 ||
    quantities.length === 0 ||
    codes.length !== quantities.length
  ) {
    alert("Παρακαλώ επιλέξτε τουλάχιστον ένα προϊόν");
    return null;
  }
  axios.defaults.headers.common["auth-token"] = `${user}`;
  const res = axios.post("/api/auth/create_meal", {
    consists_of: codes,
    quantities: quantities,
    name: name,
    client_id: client_id,
    meal_type: meal_type.toLowerCase(),
  });
  const data = res.data;
  return data;
}
