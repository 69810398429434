import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

function DropZone(props) {
  const { id, onUpload, isArticle, ...rest } = props;
  const [file, setFile] = useState(null);
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = (status) => {
      // Do whatever you want with the file contents
      console.log("file >> ", status);
      const binaryStr = reader.result;
      console.log(binaryStr);
      console.log("file >> ", file);
      setFile(file.name);
      var formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      if (isArticle) {
        if(acceptedFiles[0].type !== "image/jpeg" && acceptedFiles[0].type !== "image/png") {
          alert("Μόνο εικόνες με κατάληξη .jpg ή .png επιτρέπονται");
          setFile(null);
          return;
        }
        onUpload(acceptedFiles);
      } else {
        axios
          .post(`api/auth/upload/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
            onUpload(file.name);
          })
          .catch((err) => {
            console.error(err);
            setFile(null);
          });
      }
    };
    
    reader.readAsArrayBuffer(file);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <Box
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        h="5vh"
        p="1vh"
        minH="100%"
        cursor="pointer"
        variant="main"
      >
        <Input {...getInputProps()} type="file" />
        <Flex direction="row">
          <Text fontWeight="500" fontSize="sm">
            {file === null
              ? "Μετέφερε το αρχείο εδώ ή κανε κλικ για να το διαλέξεις"
              : file}
          </Text>

          {file === null ? null : (
            <Box ml={"1vh"}>
              <Icon as={LinkIcon} />
            </Box>
          )}
        </Flex>
      </Box>
    </div>
  );
}
export default DropZone;
