// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React, { useEffect } from "react";
import { barChartOptionsConsumption } from "variables/charts";
import { MdQueryStats } from "react-icons/md";
import axios from "axios";
import { useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

export default function WeeklyReview(props) {
  const { id, ...rest } = props;
  const [isMontly, setIsMontly] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [map, setMap] = useState([
    {
      name: "Expected",
      data: [],
    },
    {
      name: "Actual",
      data: [],
    },
  ]);
  useEffect(() => {
    if (isMontly) {
      getMonthlyData(id)
        .then((res) => {
          console.info("monthly data:", res);
          setMap(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      getWeeklyData(id)
        .then((res) => {
          setMap(res);
          console.info("weekly data:", res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [isMontly, isClient]);

  async function getWeeklyData(id) {
    const response = isClient
      ? await axios.get(`api/auth/get_client_feed/${id}/15`)
      : await axios.get(`api/auth/get_percentages/${id}/15`);
    const data = await response.data;
    console.log(data);
    const temp_map = [
      {
        name: "Expected",
        data: [],
      },
      {
        name: "Actual",
        data: [],
      },
    ];
    barChartOptionsConsumption.xaxis.names.forEach((e) => {
      temp_map[0].data.push(
        data[e] !== undefined && data[e].goal !== null
          ? data[e].goal.toFixed(2)
          : 0
      );
      temp_map[1].data.push(
        data[e] !== undefined && data[e].actual !== null
          ? data[e].actual.toFixed(2)
          : 0
      );
    });
    console.info(`WeeklyReview : ${JSON.stringify(temp_map)} `);

    return temp_map;
  }

  async function getMonthlyData(id) {
    const response = isClient
      ? await axios.get(`api/auth/get_client_feed/${id}/30`)
      : await axios.get(`api/auth/get_percentages/${id}/30`);
    const data = await response.data;
    const temp_map = [
      {
        name: "Expected",
        data: [],
      },
      {
        name: "Actual",
        data: [],
      },
    ];
    barChartOptionsConsumption.xaxis.names.forEach((e) => {
      console.debug("data[e]", e);
      temp_map[0].data.push(
        data[e] !== undefined ? data[e].goal.toFixed(2) : 0
      );
      temp_map[1].data.push(
        data[e] !== undefined ? data[e].actual.toFixed(2) : 0
      );
    });
   
    return temp_map;
  }

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Tabs
      variant="enclosed"
      width="100%"
      onChange={(index) => setIsClient(index === 1)}
    >
      <TabList>
        <Tab>Διατροφολόγος</Tab>
        <Tab>Πελάτης</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Card {...rest}>
            {map[0].data.length > 0 ? (
              <Card align="center" direction="column" w="100%">
                <Flex align="center" w="100%" px="15px" py="10px">
                  <Text
                    me="auto"
                    color={textColor}
                    fontSize="xl"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    {isMontly ? "Review μήνα" : "15νθήμερο Review"}
                  </Text>
                  <Button
                    align="center"
                    justifyContent="center"
                    onClick={() => setIsMontly(!isMontly)}
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w="37px"
                    h="37px"
                    lineHeight="100%"
                    borderRadius="10px"
                    {...rest}
                  >
                    <Icon
                      as={MdQueryStats}
                      color={iconColor}
                      w="24px"
                      h="24px"
                    />
                  </Button>
                </Flex>

                <Box h="400" mt="auto" overflowX={"auto"} overflowY={"hidden"}>
                  <BarChart
                    chartData={map}
                    chartOptions={barChartOptionsConsumption}
                  />
                </Box>
              </Card>
            ) : null}
          </Card>
        </TabPanel>
        <TabPanel>
          <Card {...rest}>
            {map[0].data.length > 0 ? (
              <Card align="center" direction="column" w="100%">
                <Flex align="center" w="100%" px="15px" py="10px">
                  <Text
                    me="auto"
                    color={textColor}
                    fontSize="xl"
                    fontWeight="700"
                    lineHeight="100%"
                  >
                    {isMontly ? "Review μήνα" : "15νθήμερο Review"}
                  </Text>
                  <Button
                    align="center"
                    justifyContent="center"
                    onClick={() => setIsMontly(!isMontly)}
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w="37px"
                    h="37px"
                    lineHeight="100%"
                    borderRadius="10px"
                    {...rest}
                  >
                    <Icon
                      as={MdQueryStats}
                      color={iconColor}
                      w="24px"
                      h="24px"
                    />
                  </Button>
                </Flex>

                <Box h="400" mt="auto" overflowX={"auto"} overflowY={"hidden"}>
                  <BarChart
                    chartData={map}
                    chartOptions={barChartOptionsConsumption}
                  />
                </Box>
              </Card>
            ) : null}
          </Card>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
