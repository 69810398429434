import {
  Text,
  Box,
  HStack,
  Textarea,
  Link,
  Spacer,
  Button,
  Input,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {  ExternalLinkIcon } from "@chakra-ui/icons";
import React from "react";
import axios from "axios";
import { FiDelete, FiEdit2 } from "react-icons/fi";
import { MdCalendarToday, MdNotifications } from "react-icons/md";

export default function Notification(props) {
  const { id, title, type, notes, link, textColorPrimary, onDelete } = props;
  const [edit, setEdit] = React.useState(false);
  const [linkState, setLink] = React.useState(link);
  async function updateCommentsNotification(updatedNotes) {
    const response = await axios.put("/api/auth/update_meeting", {
      meeting_id: id,
      comments: updatedNotes,
    });
    const data = response.data;
    console.debug("updated meeting", data);
    return data;
  }

  async function updateLinkNotification(updatedLink) {
    const response = await axios.put("/api/auth/update_meeting", {
      meeting_id: id,
      link: updatedLink,
    });
    const data = response.data;
    console.debug("updated meeting", data);
    setEdit(false);
    setLink(updatedLink);
    return data;
  }
  function handleOnBlur(event) {
    if (event.target.value !== notes) {
      updateCommentsNotification(event.target.value);
    }
  }
  return (
    <Box>
      <HStack>
        {type === "meeting" ? (
          <Icon as={MdCalendarToday} color="secondaryGray.600" />
        ) : (
          <Icon as={MdNotifications} color="secondaryGray.600" />
        )}
        <Text color={textColorPrimary} fontSize="md" mb="20px">
          {" "}
          {title}
        </Text>
        <Spacer />
        <Tooltip label="Διαγραφή Ειδοποίησης" fontSize="sm">
          <Button
            colorScheme="secondaryGray"
            variant="ghost"
            onClick={async () => {
              await onDelete(id);
            }}
          >
            <Icon as={FiDelete} />
          </Button>
        </Tooltip>
      </HStack>
      <Box h={5} />
      <HStack>
        {edit ? (
          <Input
            onBlur={(event) => updateLinkNotification(event.target.value)}
            defaultValue={linkState}
          />
        ) : type === "meeting" ? (
          <Link
            href={
              link.includes("https") || link.includes("http")
                ? linkState
                : "https://" + linkState
            }
            isExternal
          >
            {linkState} <ExternalLinkIcon mx="2px" />
          </Link>
        ) : (
          <Text color={textColorPrimary} fontSize="md" mb="20px">
            {linkState}
          </Text>
        )}

        <Spacer />
        <Button
          leftIcon={<FiEdit2 />}
          size="sm"
          onClick={() => setEdit(!edit)}
        />
      </HStack>
      <Box h={5} />
      <Textarea
        variant="unstyled"
        placeholder={notes}
        _placeholder={{ opacity: 1, color: "gray.500" }}
        onBlur={handleOnBlur}
      />
      <Text color={textColorPrimary} fontSize="sm" mb="20px"></Text>
    </Box>
  );
}
