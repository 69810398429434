import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import DailyTraffic from "views/admin/default/components/DailyTraffic";

import Tasks from "views/admin/default/components/Tasks";
import TodoList from "./components/TodoList";
export default function UserReports() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Tasks />
      <TodoList/>
    </Box>
  );
}
