import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Icon, Spacer, Link, Button } from "@chakra-ui/react";

export default function Meeting(props) {
  const { link, textColor, title, notes, time, icon, buttonText, buttonColor } =
    props;
  return (
    <Flex mb="20px" align="center">
      <Link
        href={
          title === "Συνάντηση"
            ? link.includes("http") || link.includes("https")
              ? link
              : "https://" + link
            : link
        }
      >
        {" "}
        <Box
          w="100%"
          bg="brand.100"
          borderRadius="10px"
          boxShadow="0px 18px 40px rgba(112, 144, 176, 0.12)"
          fontSize="sm"
          fontWeight="500"
          px="24px"
          py="5px"
          cursor="pointer"
        >
          {buttonText}
        </Box>
      </Link>
      <Box w={5} />

      <Text fontWeight="bold" color={textColor} fontSize="md">
        {title}
      </Text>
      <Box w={15} />
      <Text
        fontWeight="medium"
        color={textColor}
        fontSize="sm"
        textAlign="center"
      >
        {notes}
      </Text>
      <Spacer />
      <Text fontSize="small" color={textColor} fontWeight="bold">
        {time}
      </Text>
      <Box w={15} />
      <Icon ms="auto" as={icon} color="secondaryGray.600" w="24px" h="24px" />
    </Flex>
  );
}
