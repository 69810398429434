import {
  Box,
  Button,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function MedicalHistory(props) {
  const { medicalHistory, client_id } = props;
  const [showTextarea, setTextarea] = useState(false);
  const [showInput, setInput] = useState(false);
  const toast = useToast();
  const [depression, setDepression] = useState(
    medicalHistory.depression 
  );
  const [dislikes, setDislikes] = useState(
    medicalHistory.dislikes
  );
  const [allergies, setAllergies] = useState(
    medicalHistory.allergies
  );
  const [visitedDietitian, setVisitedDietitian] = useState(
    medicalHistory.visitedDietitian 
  );
  const [didDiet, setDidDiet] = useState(
    medicalHistory['didDiet'] 
  );
  const [dietResults, setDietResults] = useState(
    medicalHistory.dietResults
  );
  const [smoke, setSmoke] = useState(
    medicalHistory.smoke
  );

  return (
    <VStack w="100%" h="100%" p="10px" spacing="10px" alignItems="flex-start">
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Μέγιστο βάρος μετά τα 18 :
        </Text>
        <Input
          type="number"
          size="sm"
          w={"20%"}
          placeholder={medicalHistory.maxWeight}
          onChange={(e) => {
            medicalHistory.maxWeight = e.target.value;
          }}
        />
        <Text fontSize="xs">Κιλά</Text>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Ελάχιστο Βάρος μετά τα 18 :
        </Text>
        <Input
          type="number"
          size="sm"
          w={"20%"}
          placeholder={medicalHistory.minWeight}
          onChange={(e) => {
            medicalHistory.minWeight = e.target.value;
          }}
        />
        <Text fontSize="xs">Κιλά</Text>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Σύνηθες Βάρος μετά τα 18 :
        </Text>
        <Input
          type="number"
          size="sm"
          w={"20%"}
          placeholder={medicalHistory.avgWeight}
          onChange={(e) => {
            medicalHistory.avgWeight = e.target.value;
          }}
        />
        <Text fontSize="xs">Κιλά</Text>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Έχετε επισκεφτεί Διαιτολόγο στο παρελθόν;
        </Text>
        <Select
          size="sm"
          w="30%"
          value={medicalHistory.visitedDietitian}
          onChange={(e) => {
            setVisitedDietitian(e.target.value);
            medicalHistory.visitedDietitian = e.target.value;
            console.log(medicalHistory);
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Αν όχι, έχετε κάνει κάποια δίαιτα από μόνος/ή σας στο παρελθόν:
        </Text>
        <Select
          size="sm"
          w="30%"
          value={ medicalHistory.didDiet}
          onChange={(e) => {
            setDidDiet(e.target.value);
            medicalHistory.didDiet = e.target.value;
            console.log(medicalHistory);
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Τι είδους δίαιτα;
        </Text>
        <Input
          type="text"
          size="sm"
          placeholder={medicalHistory.dietType}
          onChange={(e) => {
            medicalHistory.dietType = e.target.value;
          }}
        />
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Είχατε αποτελέσματα;
        </Text>
        <Select
          size="sm"
          w={"50%"}
          value={medicalHistory.dietResults}
          onChange={(e) => {
            setDietResults(e.target.value);
            medicalHistory.dietResults = e.target.value;
            console.log(medicalHistory);
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Καπνίζετε;
        </Text>
        <Select
          size="sm"
          value={medicalHistory.smoke}
          onChange={(e) => {
            setSmoke(e.target.value);
            medicalHistory.smoke = e.target.value;
            console.log(medicalHistory);
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Πόσα έτη καπνίζετε;
        </Text>
        <Input
          type="number"
          size="sm"
          w={"30%"}
          placeholder={medicalHistory.smokeYears}
          onChange={(e) => {
            medicalHistory.smokeYears = e.target.value;
          }}
        />
      </HStack>
      <HStack>
        <Text fontSize="md" w={"100%"}>
          Πόσα τσιγάρα καπνίζετε την ημέρα;
        </Text>
        <Input
          type="number"
          size="sm"
          w={"30%"}
          placeholder={medicalHistory.smokePerDay}
          onChange={(e) => {
            medicalHistory.smokePerDay = e.target.value;
          }}
        />
      </HStack>
      <HStack>
        <Text fontSize="md">Αλλεργίες;</Text>
        <Select
          size="sm"
          w={"50%"}
          value={medicalHistory.allergies}
          onChange={(e) => {
            setAllergies(e.target.value);
            if (
              medicalHistory.allergies === "yes" ||
              e.target.value === "yes"
            ) {
              setTextarea(true);
            } else {
              setTextarea(false);
            }
            medicalHistory.allergies = e.target.value;
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
        {allergies === 'yes' || medicalHistory.allergies === 'yes' ? (
          <Textarea
            size="md"
            w={"100%"}
            placeholder={medicalHistory.allergiesList}
            onChange={(e) => {
              medicalHistory.allergiesList = e.target.value;
            }}
          />
        ) : null}
      </HStack>
      <HStack>
        <Text fontSize="md">Δυσανεξίες;</Text>
        <Select
          size="sm"
          w={"50%"}
          value={medicalHistory.dislikes}
          onChange={(e) => {
            setDislikes(e.target.value);
            if (medicalHistory.dislikes === "yes" || e.target.value === "yes") {
              setInput(true);
            } else {
              setInput(false);
            }
            medicalHistory.dislikes = e.target.value;
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
        {dislikes === "yes" || medicalHistory.dislikes === "yes" ? (
          <Textarea
            size="md"
            w={"100%"}
            placeholder={medicalHistory.dislikesList}
            onChange={(e) => {
              medicalHistory.dislikesList = e.target.value;
            }}
          />
        ) : null}
      </HStack>

      <HStack>
        <Text fontSize="md" w={"100%"}>
          Έχετε διαγνωστεί με Κατάθλιψη;
        </Text>
        <Select
          size="sm"
          w={"50%"}
          value={medicalHistory.depression}
          onChange={(e) => {
            setDepression(e.target.value);
            medicalHistory.depression = e.target.value;
            console.log(medicalHistory);
          }}
        >
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </Select>
      </HStack>
      <Box h={5} />
      <Button
        mt="1em"
        colorScheme="teal"
        size="sm"
        onClick={() => {
          console.log("clicked");
          axios
            .put("api/auth/set_medical_history", {
              client_id: client_id,
              history: medicalHistory,
            })
            .then((res) => {
              console.log(res);
              toast({
                title: "Επιτυχής αποθήκευση",
                description: "Το ιατρικό ιστορικό αποθηκεύτηκε με επιτυχία",
                status: "success",
                duration: 9000,
                isClosable: true,
              });
            })
            .catch((err) => {
              toast({
                title: "Αποτυχία αποθήκευσης",
                description: "Παρουσιάστηκε σφάλμα",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
              console.log(err);
            });
        }}
      >
        Αποθήκευση
      </Button>
    </VStack>
  );
}
