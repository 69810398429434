import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import { Table } from "react-chakra-pagination";
import { MdOutlineBusiness } from "react-icons/md";
import { Link } from "react-router-dom";
export default function Business() {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const columns = [
    {
      Header: "Επωνυμία Επιχείρησης",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Τηλέφωνο",
      accessor: "phone",
    },
  ];
  const data = [
    {
      name: (
        <Flex align="center">
          <Avatar name='Bizz' size="sm" mr="4" />
          <Link
            to={"/admin/business_profile/" +'Bizz'}
            fontSize="sm"
            fontWeight="700"
          >
            {"Bizz"}
          </Link>
        </Flex>
      ),
      email: "aaaaa",
      phone: "1234567890",
    },
  ];
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Tabs isFitted>
        <TabList>
          <Tab>Αθλητικά Σωματεία</Tab>
          <Tab>Επιχειρήσεις Εστίασης</Tab>
          <Tab>Consulting/Διατροφική Εκπαίδευση</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Table
              colorScheme="blue"
              // Fallback component when list is empty
              emptyData={{
                icon: MdOutlineBusiness,
                text: "Δεν υπάρχουν αθλητικά σωματεία",
              }}
              totalRegisters={list.length}
              page={page}
              // Listen change page event and control the current page using state
              onPageChange={(page) => setPage(page)}
              columns={columns}
              data={data}
            />
          </TabPanel>
          <TabPanel>
            <Table
              colorScheme="blue"
              // Fallback component when list is empty
              emptyData={{
                icon: MdOutlineBusiness,
                text: "Δεν υπάρχουν επιχειρήσεις εστίασης",
              }}
              totalRegisters={list.length}
              page={page}
              // Listen change page event and control the current page using state
              onPageChange={(page) => setPage(page)}
              columns={columns}
              data={[]}
            />
          </TabPanel>
          <TabPanel>
            <Table
              colorScheme="blue"
              // Fallback component when list is empty
              emptyData={{
                icon: MdOutlineBusiness,
                text: "Δεν υπάρχουν consulting/διατροφική εκπαίδευση",
              }}
              totalRegisters={list.length}
              page={page}
              // Listen change page event and control the current page using state
              onPageChange={(page) => setPage(page)}
              columns={columns}
              data={[]}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
