import React from "react";
import UpdateTodo from "./UpdateTodo";
import {  DeleteTodo, DeleteAllTodo } from "./DeleteTodo";
import {
  HStack,
  Box,
  VStack,
  Flex,
  Text,
  StackDivider,
  Spacer,
} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import img from "../../../../assets/img/layout/empty.svg";

function Todos({ tasks, updateTask, deleteTask, deleteTaskAll, checkTask }) {
  if (!tasks.length) {
    return (
      <>
        <Box maxW='80%'>
          <Image
            mt='20px'
            w='98%'
            maxW='350'
            src={img}
            alt='Η λίστα είναι κενή'
          />
        </Box>
      </>
    );
  }
  return (
    <>
      <VStack
        divider={<StackDivider />}
        borderColor='gray.100'
        borderWidth='2px'
        p='5'
        borderRadius='lg'
        w='100%'
        
        alignItems='stretch'
      >
        {tasks.map((task) => (
          <HStack key={task.task_id} opacity={task.completed === 1 ? "0.2" : "1"}>
            <Text
              w='100%'
              p='8px'
              borderRadius='lg'
              as={task.completed === 1 ? "s" : ""}
              cursor='pointer'
              align='left'
              onClick={() => checkTask(task.task_id)}
            >
              {task.task}
            </Text>
            
            <DeleteTodo
              task={task}
              deleteTask={deleteTask}
              deleteTaskAll={deleteTaskAll}
            />
            <UpdateTodo task={task} updateTask={updateTask} />
          </HStack>
        ))}
      </VStack>

      <Flex>
        <DeleteAllTodo deleteTaskAll={deleteTaskAll} />
      </Flex>
    </>
  );
}

export default Todos;