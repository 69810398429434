import { VStack, useToast } from "@chakra-ui/react";
import Todos from "./Todos";
import AddTodo from "./AddTodo";
import { useState, useEffect } from "react";
import Card from "components/card/Card";
import axios from "axios";

function TodoList() {
  const toast = useToast();
  const [tasks, setTasks] = useState([]);
  async function getTasks() {
    await axios
      .get("/api/auth/get_tasks")
      .then((response) => {
        setTasks(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα σύνδεσης",
          description: "Δεν μπορέσαμε να συνδεθούμε στον server",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
  }

  useEffect(() => {
    getTasks();
  }, []);

  function deleteTask(id) {
    const newTasks = tasks.filter((task) => {
      return task.task_id !== id;
    });
    setTasks(newTasks);
    axios
      .delete(`/api/auth/delete_task/${id}`)
      .then((response) => {
        console.log(response);
        toast({
          title: "Επιτυχία",
          description: "Η διαγραφή ολοκληρώθηκε",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα σύνδεσης",
          description: "Δεν μπορέσαμε να διαγράψουμε την εργασία",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
  }

  function deleteTaskAll() {
    tasks.forEach((task) => {
      axios
        .delete(`/api/auth/delete_task/${task.task_id}`)
        .then((response) => {
          console.log(response);
          toast({
            title: "Επιτυχία",
            description: "Η διαγραφή ολοκληρώθηκε",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        })
        .catch((err) => {
          console.error(err);
          toast({
            title: "Πρόβλημα σύνδεσης",
            description: "Δεν μπορέσαμε να διαγράψουμε τις εργασίες",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
        });
      setTasks([]);
    });
  }

  function checkTask(id) {
    const newTasksCheck = tasks.map((task, index, array) => {
      if (task.task_id === id) {
        task.completed = task.completed === 0 ? 1 : 0;
      }
      return task;
    });
    console.log(newTasksCheck);
    axios
      .put(`/api/auth/update_task`, {
        task_id: id,
        completed: newTasksCheck.find((task) => task.task_id === id).completed,
      })
      .then((response) => {
        console.debug(response);
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα σύνδεσης",
          description: "Δεν μπορέσαμε να ενημερώσουμε την εργασία",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
    setTasks(newTasksCheck);
  }

  function updateTask(id, body, onClose) {
    const info = body.trim();

    if (!info) {
      toast({
        title: "Προσθέστε μια εργασία",
        position: "top",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });

      return;
    }

    const newTasksUpdate = tasks.map((task, index, array) => {
      console.log(task,id);
      if (task.task_id === id) {
        console.log(task);
        task.task = body;
        task.completed = 0;
        axios.put(`/api/auth/update_task`, {
          task_id: id,
          task: body,
          completed: 0,
        });
      }
      return task;
    });

    setTasks(newTasksUpdate);
    onClose();
  }

  function addTask(task) {
    
    axios.post(`/api/auth/create_task`, {
      task: task.task,
      date: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
    });
    getTasks();
  }

  return (
    <VStack minH="10vh" pt={5}>
      <Card p="20px" align="center" direction="column" w="100%">
        <AddTodo addTask={addTask} />
        <Todos
          tasks={tasks}
          updateTask={updateTask}
          deleteTask={deleteTask}
          deleteTaskAll={deleteTaskAll}
          checkTask={checkTask}
        />
      </Card>
    </VStack>
  );
}

export default TodoList;
