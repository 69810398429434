import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Button,
  FormControl,
  Input,
  FormLabel,
} from "@chakra-ui/react";
import React from "react";
import { FiEdit } from "react-icons/fi";

export default function ChangeLabelModal(props) {
  const {
    dinner,
    changeDinner,
    breakfast,
    changeBreakfast,
    lunch,
    changeLunch,
    snack1,
    changeSnack1,
    snack2,
    changeSnack2,
  } = props;
  const initialFocusRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button
        leftIcon={<FiEdit />}
        variant="filled"
        bgColor="lavender"
        onClick={() => onOpen()}
      >
        Επεξεργασία labels
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialFocusRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Αλλαγή Ετικέτας</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Πρωινό</FormLabel>
              <Input
                placeholder="Πρωινό"
                value={breakfast}
                onChange={(e) => changeBreakfast(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Δεκατιανό</FormLabel>
              <Input
                placeholder="Δεκατιανό"
                value={snack1}
                onChange={(e) => changeSnack1(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Μεσημεριανό</FormLabel>
              <Input
                placeholder="Μεσημεριανό"
                value={lunch}
                onChange={(e) => changeLunch(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Απογευματινό</FormLabel>
              <Input
                placeholder="Απογευματινό"
                value={snack2}
                onChange={(e) => changeSnack2(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Βραδινό</FormLabel>
              <Input
                placeholder="Βραδινό"
                value={dinner}
                onChange={(e) => changeDinner(e.target.value)}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
