import { useMemo, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { UserContext } from "contexts/UserContext";
export default function Router(){
    const [user,setUser] = useState(null);
    const providerValue = useMemo(()=> ({user,setUser}),[user,setUser]);
    return (
        <HashRouter>
        <Switch>
          <UserContext.Provider value={providerValue}>  
          <Route path={`/auth`} component={AuthLayout}  />
          <Route path={`/`} component={AdminLayout} />
          <Redirect from='/' to={'/auth/sign-in'} />
          </UserContext.Provider>
        </Switch>
      </HashRouter>
    );
}