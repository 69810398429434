import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import Router from "router";
import axios from "axios";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
    <Router/>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
