// Daily Traffic Dashboards Default

export const barChartDataDailyTraffic = [
  {
    name: "Memberships",
    data: [20, 30, 40, 20, 45, 50, 30, 20, 30, 40, 20, 10],
  },
];

export const barChartOptionsDailyTraffic = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#CBD5E0",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};


export const barChartOptionsConsumption = {
  chart: {
   type: "bar",
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    names: ["calories", "carbs", "fat", "protein", "alcochol", "caffeine", "choline","copper", "ferrum", "fibers", "follicles", "magnisium", "monounsaturated", "natrium","omega3","omega6","phosphorus","polyunsaturated","potassium","saturated","selinium","sugars","vitaminA","vitaminB1","vitaminB2","vitaminB3","vitaminB6","vitaminB12","vitaminC","vitaminD","vitaminE","vitaminK","water","zinc"],
    categories: ['θερμίδες','υδατάνθρακες','λιπαρά','πρωτείνη','αλκοόλ','καφείνη','χολίνη','χαλκός','σίδηρος','φυτικές ίνες','φολικό οξύ','μαγνήσιο','μονοακόρεστα','νάτριο','ω-3','ω-6','φώσφορος','πολυακόρεστα','κάλιο','κορεσμένα','σελίνιο','σάκχαρα','βιταμίνη Α','βιταμίνη Β1','βιταμίνη Β2','βιταμίνη Β3','βιταμίνη Β6','βιταμίνη Β12','βιταμίνη C','βιταμίνη D','βιταμίνη E','βιταμίνη K','νερό','ζιρόνιο'],
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    color: "black",
    labels: {
      show: false,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },

  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8", "#6AD2FF", "#E1E9F8"],
  },
  legend: {
    show: false,
  },
  colors: ["#5E37FF", "#6AD2FF", "#E1E9F8", "#6AD2FF", "#E1E9F8"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "40px",
    },
  },
};

export const pieChartMicrosOptions = {
  labels: [ "alcochol", "caffeine", "choline","copper", "ferrum", "fibers", "follicles", "magnisium", "monounsaturated", "natrium","omega3","omega6","phosphorus","polyunsaturated","potassium","saturated","selinium","sugars","vitaminA","vitaminB1","vitaminB2","vitaminB3","vitaminB6","vitaminB12","vitaminC","vitaminD","vitaminE","vitaminK","water","zinc"],
  names: ['αλκοόλ','καφείνη','χολίνη','χαλκός','σίδηρος','φυτικές ίνες','φολικό οξύ','μαγνήσιο','μονοακόρεστα','νάτριο','ω-3','ω-6','φώσφορος','πολυακόρεστα','κάλιο','κορεσμένα','σελίνιο','σάκχαρα','βιταμίνη Α','βιταμίνη Β1','βιταμίνη Β2','βιταμίνη Β3','βιταμίνη Β6','βιταμίνη Β12','βιταμίνη C','βιταμίνη D','βιταμίνη E','βιταμίνη K','νερό','ζιρόνιο'],
  colors: ["#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d","#fa0023","#f9f9f9","#c8e493","#ad5a88","#2353a4","#8bf785","#6be85a","#964fc8","#f679de","#3a43e9","#e751c8","#7e0de2","#7d74c1","#95e34f","#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d"],
  chart: {
    width: "150px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d","#fa0023","#f9f9f9","#c8e493","#ad5a88","#2353a4","#8bf785","#6be85a","#964fc8","#f679de","#3a43e9","#e751c8","#7e0de2","#7d74c1","#95e34f","#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export const pieChartMacrosOptions = {
  labels: ["calories", "carbs", "fat", "protein"],
  colors: ["#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d","#fa0023","#f9f9f9","#c8e493","#ad5a88","#2353a4","#8bf785","#6be85a","#964fc8","#f679de","#3a43e9"],
  chart: {
    width: "150px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      size: "100%",
      expandOnClick: true,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF","#14d8bc","#91e38d","#1e7b75","#638955","#0500e2","#94cedc","#95bf3d","#fa0023","#f9f9f9","#c8e493","#ad5a88","#2353a4","#8bf785","#6be85a","#964fc8","#f679de","#3a43e9"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};
export const pieChartData = [63, 25, 12, 10, 5, 2, 1,63, 25, 12,63, 25, 12,63, 25, 12, 10, 5, 2, 1,63, 25, 12,63, 25, 12,63, 25, 12, 10, 5, 2, 1,63, 25, 12];

// Total Spent Default

export const lineChartDataTotalSpent = [
  {
    name: "Goal",
    data: [2000, 2064, 2048, 2066, 1349, 1568],
  },
  {
    name: "Actual",
    data: [1030, 2040, 2024, 1246, 1620, 1946],
  },
];

export const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "numeric",
    categories: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};
