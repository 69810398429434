import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  SimpleGrid,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Assets
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { MdImage } from "react-icons/md";
import DropZone from "../profile/components/Dropzone";

export default function Articles() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [html_text, setHtml_text] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  function onUpload(acceptedFiles) {
    setImage(acceptedFiles[0]);
  }
  const toast = useToast();
  function createArticle() {
    const formData = new FormData();
    console.log(
      "title",
      title,
      "name",
      name,
      "html_text",
      html_text,
      "image",
      image,
      "category",
      category
    );
    formData.append("file", image);
    formData.append("file_name", image.name);
    formData.append("title", title);
    formData.append("name", name);
    formData.append("html_text", html_text);
    formData.append("category", category);
    axios
      .post("/api/auth/upload_article", formData)
      .then((res) => {
        console.log(res);
        toast({
          title: "Επιτυχία",
          description: "Το άρθρο δημιουργήθηκε επιτυχώς",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Σφάλμα",
          description: "Παρουσιάστηκε κάποιο σφάλμα",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });

    setTitle("");
    setName("");
    setHtml_text("");
    setImage(null);
    setCategory("");
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid column={5} gap="20px" mb="20px">
        <Box
          bg={boxBg}
          borderRadius="lg"
          p="20px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box mt="10px" fontSize="xl" fontWeight="bold"></Box>
        </Box>
        <Text fontSize={"smaller"}>Τίτλος</Text>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Τίτλος"
          size="md"
        />
        <Text fontSize={"smaller"}>Όνομα αρθρογράφου</Text>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Όνομα αρθρογράφου"
          size="md"
        />

        <Text fontSize={"smaller"}>Κατηγορία</Text>
        <Input
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Κατηγορία"
          size="md"
        />

        <ReactQuill theme="snow" value={html_text} onChange={setHtml_text} />
        <Box h={100} w={100} />
        <Box>
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="row"
          >
            <Icon as={MdImage} w="50px" h="50px" color={brandColor} />
            <Text>Εικόνες</Text>
          </Flex>
          <DropZone onUpload={onUpload} isArticle={true} />
        </Box>
      </SimpleGrid>
      <Flex alignItems="center" justifyContent="flex-end" flexDirection="row">
        <Button colorScheme="brand" size="lg" mt="20px" onClick={createArticle}>
          Δημιουργία
        </Button>
      </Flex>
    </Box>
  );
}
