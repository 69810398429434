import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  FormErrorMessage,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { UserContext } from "contexts/UserContext";
import { useHistory } from "react-router-dom";
import logo from "assets/img/auth/diet1.png";

import axios from "axios";
function SignIn() {
  const { user, setUser } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  function onSignIn() {
    axios
      .post("/api/auth/emp_login", {
        username: email,
        password: password,
        headers: {
          "Access-Control-Allow-Origin": "*",
        }
      },
      )
      .then(function (response) {
        console.debug(response);
        setUser(response.headers["auth-token"]);
        sessionStorage.setItem("token", response.headers["auth-token"]);
        sessionStorage.setItem("user", JSON.stringify(response.data));
        console.log(response.data);
        history.push("/admin/default");
      })
      .catch(function (error) {
        console.error(error);
        setError(true);
      });
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        alignItems="center"
        float={"center"}
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Heading

          as="h1" 
          size="xl"
          fontWeight="bold"
          color={textColor}
          mb={{ base: "10px", md: "20px" }}
          textAlign="center"
        >
        
            <Box as="span" color={textColor}>
              <Image src={logo} name="logo" />
            </Box>
            <Box as="span" color={textColor}>
              <Text fontWeight="bold" fontSize="2xl">
                {" "}
                Admin Panel
              </Text>
            </Box>
        </Heading>
     
        <Box h={10} />

        <Box h={10} />
        <Text
          mb="36px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Βάλε τα στοιχεία σου για να συνδεθείς
        </Text>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl isInvalid={error}>
            <FormLabel
              display="flex"
              htmlFor='email'
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              borderColor={error ? "red.500" : "transparent"}
            >
              Email/Username<Text color={brandStars}>*</Text>
            </FormLabel>
            {!error ? (
              <Box/>
            ) : (
              <FormErrorMessage>Invalid credentials.</FormErrorMessage>
            )}
            <Input
              isRequired={true}
              borderColor={error ? 'red' : null}
              variant="auth"
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="example@mail.com"
              mb="24px"
              fontWeight="500"
              size="lg"
            />
           
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Κωδικός<Text color={brandStars}>*</Text>
            </FormLabel>
            {!error ? (
              <Box/>
            ) : (
              <FormErrorMessage>Invalid credentials.</FormErrorMessage>
            )}
            <InputGroup size="md">
              <Input
                isRequired={true}
                htmlFor='password'
                fontSize="sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                borderColor={error ? 'red' : null}
                placeholder="βάλε τον κωδικό σου"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Να με θυμάσαι
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="150px"
                  fontWeight="500"
                >
                  Ξέχασες τον κωδικό σου;
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={onSignIn}
            >
              Σύνδεση
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Δεν έχεις λογαριασμό;
              <Link href="mailto:alexborousas@gmail.com">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Επικοινώνησε μαζί μας
                </Text>
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default SignIn;
