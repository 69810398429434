import React, { useState, useContext, useEffect, Children } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Box,
  Button,
  Container,
  HStack,
  Input,
  Menu,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Text,
  useDisclosure,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import InsertMealModal from "./components/Drawer";
import EditDrawer, { deleteMeal } from "./components/EditDrawer";
import {
  MdContentPaste,
  MdDeleteOutline,
  MdEdit,
  MdInfoOutline,
  MdOutlineContentPaste,
} from "react-icons/md";
import CustomDatePicker from "./components/DatePicker";
import { UserContext } from "contexts/UserContext";
import PropTypes from "prop-types";
import axios from "axios";
import EditUserGoals from "../profile/components/EditUserDetails";
import { useInterval } from "../default/components/Tasks";
import addMeal from "./components/AddAMeal";
import ChangeLabelModal from "./components/ChangeLabelModal";
import "moment/locale/el";
import { useRef } from "react";
import { seeMoreModal } from "../meals/components/CreateAMealModal";

export default function FullCalendar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useContext(UserContext);
  const { id, gender } = useParams();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [mealPlanList, setMealPlanList] = useState({});
  const [breakfastLabel, setBreakfastLabel] = useState("Πρωινό");
  const [lunchLabel, setLunchLabel] = useState("Μεσημεριανό");
  const [dinnerLabel, setDinnerLabel] = useState("Βραδινό");
  const [snack1Label, setSnack1Label] = useState("Σνακ");
  const [snack2Label, setSnack2Label] = useState("Σνακ");
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const [selectableEvent, setSelectableEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });
  const goals = JSON.parse(localStorage.getItem("goals"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openMore, setOpenMore] = useState(false);
  moment.locale("el");
  moment.lang("el");

  useEffect(() => {
    if (isContextMenuOpen) return;
    // 👇️ get global mouse coordinates
    const handleWindowMouseMove = (event) => {
      setGlobalCoords({
        x: event.screenX,
        y: event.screenY,
      });
    };
    window.addEventListener("mousemove", handleWindowMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleWindowMouseMove);
    };
  }, [isContextMenuOpen]);

  const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < new Date() ? "lavenderBlush" : "#f5f5f5",
      },
    });

  const lang = {
    gr: {
      week: "Εβδομάδα",
      day: "Ημέρα",
      previous: "Προηγρούμενη",
      next: "Επόμενη",
      today: "Σήμερα",

      showMore: (total) => `+${total} περισσότερα`,
    },
  };

  const customTimeSlot = (value) => {
    if (new Date(value.value).getHours() >= 20) {
      return (
        <label className="rbc-time-slot">
          <Box bg="purple.100" borderRadius={10} mx={5}>
            <Text alignItems="center" align="center" m={2}>
              Σύνολο Ημέρας
            </Text>
          </Box>
        </label>
      );
    }
    if (
      new Date(value.value).getHours() >= 16 &&
      new Date(value.value).getHours() <= 20
    ) {
      return (
        <label className="rbc-time-slot">
          <Box bg="blue.100" borderRadius={10} mx={5} height="100%">
            <Text alignItems="center" align="center" m={2}>
              {dinnerLabel}
            </Text>
          </Box>
        </label>
      );
    }
    if (new Date(value.value).getHours() >= 12) {
      return (
        <label className="rbc-time-slot">
          <Box bg="yellow.100" borderRadius={10} mx={5}>
            <Text alignItems="center" align="center" m={2}>
              {snack2Label}
            </Text>
          </Box>
        </label>
      );
    }
    if (new Date(value.value).getHours() >= 8) {
      return (
        <label className="rbc-time-slot">
          <Box bg="red.100" borderRadius={10} mx={5}>
            <Text alignItems="center" align="center" m={2}>
              {lunchLabel}
            </Text>
          </Box>
        </label>
      );
    }
    if (new Date(value.value).getHours() >= 4) {
      return (
        <label className="rbc-time-slot">
          <Box bg="yellow.100" borderRadius={10} mx={5}>
            <Text alignItems="center" align="center" m={2}>
              {snack1Label}
            </Text>
          </Box>
        </label>
      );
    }
    return (
      <label className="rbc-time-slot">
        <Box bg="green.100" borderRadius={10} mx={5}>
          <Text alignItems="center" align="center" m={2}>
            {breakfastLabel}
          </Text>
        </Box>
      </label>
    );
  };

  function handleOnClose() {
    setSelectableEvent(null);
    getMealPlanList(startDate, endDate);
    onClose();
  }

  useEffect(() => {
    getMealPlanList(startDate, endDate);
  }, []);

  useInterval(() => {
    getMealPlanList(startDate, endDate);
  }, 1000 * 30);

  async function getMealPlanList(startDate, endDate) {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const args = { client_id: id };
    console.debug(`start date ${startDate} endDate ${endDate}`);
    if (startDate && startDate !== null && endDate && endDate !== null) {
      args["start_date"] = startDate;
      args["end_date"] = endDate;
    } else if (startDate && startDate !== null) {
      args["date"] = startDate;
    }

    axios
      .post("/api/auth/view_diet_plan", args)
      .then((res) => {
        setMealPlanList(res.data);
        const totalMap = {};
        res.data.map((meal) => {
          const date = new Date(meal.date);
          const key = `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`;
          const clientTotal = meal.consists_of
            .map((food, key) => {
              if (
                food.fullfilled === 1 &&
                meal.consists_of[key] !== undefined
              ) {
                return {
                  protein: meal.consists_of[key].protein,
                  carbs: meal.consists_of[key].carbs,
                  fat: meal.consists_of[key].fat,
                  calories: meal.consists_of[key].calories,
                };
              }
              return {
                protein: 0,
                carbs: 0,
                fat: 0,
                calories: 0,
              };
            })
            .reduce(
              (acc, curr) => {
                return {
                  protein: acc.protein + curr.protein,
                  carbs: acc.carbs + curr.carbs,
                  fat: acc.fat + curr.fat,
                  calories: acc.calories + curr.calories,
                };
              },
              {
                protein: 0,
                carbs: 0,
                fat: 0,
                calories: 0,
              }
            );

          if (totalMap[key]) {
            totalMap[key]["calories"] += meal.calories;
            totalMap[key]["carbs"] += meal.carbs;
            totalMap[key]["fat"] += meal.fat;
            totalMap[key]["protein"] += meal.protein;
            totalMap[key]["clientTotal"] = {
              protein:
                totalMap[key]["clientTotal"].protein + clientTotal.protein,
              carbs: totalMap[key]["clientTotal"].carbs + clientTotal.carbs,
              fat: totalMap[key]["clientTotal"].fat + clientTotal.fat,
              calories:
                totalMap[key]["clientTotal"].calories + clientTotal.calories,
            };
          } else {
            totalMap[key] = {
              calories: meal.calories,
              carbs: meal.carbs,
              fat: meal.fat,
              protein: meal.protein,
              clientTotal,
            };
          }
        });
        const events = res.data
          .map((meal) => {
            return {
              start: setHour(meal.meal_type, meal.date),
              end: setEndHour(meal.meal_type, meal.date),
              title: meal.name,
              description:
                meal === undefined
                  ? []
                  : meal.consists_of.map((food) => {
                      if (food === undefined || food === null) {
                        return "";
                      }
                      return `${food.label !== "" ? food.label : food.food} - ${
                        food.quantity
                      }`;
                    }),
              meal: meal,
              type: meal.meal_type,
              id: meal.meal_id,
            };
          })
          .concat(
            Object.keys(totalMap).map((key) => {
              const date = new Date(key);
              return {
                start: setHour("total", date),
                end: setEndHour("total", date),
                title: "Σύνολο Ημέρας",
                description: [
                  `Θερμίδες: ${totalMap[key].calories.toFixed(2)}kcal`,
                  `Υδατάνθρακες: ${totalMap[key].carbs.toFixed(2)} γρ.`,
                  `Λιπαρά: ${totalMap[key].fat.toFixed(2)} γρ.`,
                  `Πρωτεΐνες: ${totalMap[key].protein.toFixed(2)} γρ.`,
                  `Σύνολο Πρωτεΐνων Πελάτη: ${totalMap[
                    key
                  ].clientTotal.protein.toFixed(2)} γρ.`,
                  `Σύνολο Υδατάνθρακων Πελάτη: ${totalMap[
                    key
                  ].clientTotal.carbs.toFixed(2)} γρ.`,
                  `Σύνολο Λιπαρών Πελάτη: ${totalMap[
                    key
                  ].clientTotal.fat.toFixed(2)} γρ.`,
                  `Σύνολο Θερμίδων Πελάτη: ${totalMap[
                    key
                  ].clientTotal.calories.toFixed(2)}kcal`,
                ],
                meal: totalMap[key],
                type: "total",
                id: key,
              };
            })
          );
        console.debug("Events", events);
        setEvents(events);
        return res.data;
      })
      .catch((err) => {
        console.error("error on fullcalendar", err);
      });
  }

  function setHour(type, date) {
    if (type === "breakfast") {
      return moment(date)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "lunch") {
      return moment(date)
        .set({ hour: 8, minute: 1, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "dinner") {
      return moment(date)
        .set({ hour: 16, minute: 1, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "snack1") {
      return moment(date)
        .set({ hour: 4, minute: 1, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "snack2") {
      return moment(date)
        .set({ hour: 12, minute: 1, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "total") {
      return moment(date)
        .set({ hour: 20, minute: 1, second: 0, millisecond: 0 })
        .toDate();
    }
    return moment(date).toDate();
  }

  function setEndHour(type, date) {
    if (type === "breakfast") {
      return moment(date)
        .set({ hour: 4, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "lunch") {
      return moment(date)
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "dinner") {
      return moment(date)
        .set({ hour: 20, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "snack1") {
      return moment(date)
        .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "snack2") {
      return moment(date)
        .set({ hour: 16, minute: 0, second: 0, millisecond: 0 })
        .toDate();
    }
    if (type === "total") {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
        .toDate();
    }
    return moment(date).toDate();
  }

  moment.locale("el", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  const localizer = momentLocalizer(moment);
  const EventWrapperComponent = ({ event, children }) => {
    const newChildren = { ...children };
    const newChildrenProps = { ...newChildren.props };
    newChildrenProps.className = `${newChildrenProps.className}`;
    newChildren.props = { ...newChildrenProps };

    return (
      <Box key={event.id} width="100%">
        {newChildren}
      </Box>
    );
  };

  function Event({ event }) {
    const ScrollViewRef = useRef();
    return (
      <Box
        key={event.id}
        id={event.id}
        class="rbc-event"
        h="100%"
        ref={ScrollViewRef}
        bgColor={
          event.meal.meal_fullfilled === 0
            ? "blue.100"
            : event.type === "total"
            ? "blue.100"
            : "green.200"
        }
        borderRadius={10}
        p={2}
        overflowY="auto"
        objectPosition="center"
        onScroll={(e) => {
          console.debug("scrolling");
          //keep the focus on the element
          e.target.focus();
        }}
        overscrollBehaviorY="contain"
        onMouseMove={(e) => {
          ScrollViewRef.current.focus();
          e.stopPropagation();
          e.preventDefault();
          console.debug("mouse move");
        }}
        minWidth={120}
        overflowX="none"
        onContextMenu={(e) => {
          if (event.type !== "total") {
            setContextMenuOpen(true);
            setSelectableEvent(event);
            e.preventDefault();
          }
        }}
      >
        {event.description &&
        event.description.length > 0 &&
        event.type !== "total" ? (
          Array.from(event.description)
            .map((desc, key) => {
              const [food, quantity] = desc.split(" - ");

              return (
                <Wrap p={1}>
                  <Text
                    as={
                      event.meal.added !== undefined &&
                      event.meal.added.includes(food)
                        ? "b"
                        : event.meal.consists_of[key].fullfilled === 1
                        ? "kbd"
                        : "i"
                    }
                    color="black"
                    fontSize={{ md: "xs", sm: "xs", lg: "xs", xl: "xs" }}
                  >
                    {food}
                  </Text>
                  <Text
                    as={
                      event.meal.added !== undefined &&
                      event.meal.added.includes(food)
                        ? "b"
                        : event.meal.consists_of[key].fullfilled === 1
                        ? "kbd"
                        : "i"
                    }
                    color="GrayText"
                    fontSize={{
                      md: "xx-small",
                      sm: "xx-small",
                      lg: "xx-small",
                    }}
                  >
                    {quantity}γρ.
                  </Text>
                </Wrap>
              );
            })
            .concat(
              event.meal.deleted !== undefined
                ? Array.from(event.meal.deleted).map((desc, key) => {
                    return (
                      <Wrap key={key} p={1}>
                        <Text
                          as="del"
                          color="black"
                          fontSize={{ md: "xs", sm: "xs", lg: "xs", xl: "xs" }}
                        >
                          {desc}
                        </Text>
                      </Wrap>
                    );
                  })
                : null
            )
        ) : (
          <HStack alignItems={"flex-start"}>
            <VStack alignItems={"flex-start"}>
              <Text fontSize="sm" color={"grayText"}>
                Θερμίδες:{" "}
                {event.meal.calories !== undefined
                  ? event.meal.calories.toFixed(2)
                  : 0}{" "}
                kcal (
                {goals && goals.macros !== undefined
                  ? (
                      (event.meal.calories / goals.macros.calories) *
                      100
                    ).toFixed(2)
                  : 0}
                %)
              </Text>
              <Text fontSize="sm" color={"blue"}>
                Πελάτης:{" "}
                {event.meal.clientTotal.calories !== undefined
                  ? event.meal.clientTotal.calories.toFixed(2)
                  : 0}
                kcal
              </Text>
              <Progress
                width="100%"
                colorScheme="green"
                hasStripe
                value={event.meal.calories}
                max={goals && goals.macros !== undefined ? goals.macros.calories : 0}
              />
              <Text fontSize="sm" color={"grayText"}>
                Πρωτεΐνες:{" "}
                {event.meal.protein !== undefined
                  ? event.meal.protein.toFixed(2)
                  : 0}{" "}
                γρ (
                { goals && goals.macros !== undefined
                  ? ((event.meal.protein / goals.macros.protein) * 100).toFixed(
                      2
                    )
                  : 0}
                %)
              </Text>
              <Text fontSize="sm" color={"blue"}>
                Πελάτης:{" "}
                {event.meal.clientTotal.protein !== undefined
                  ? event.meal.clientTotal.protein.toFixed(2)
                  : 0}
                γρ
              </Text>
              <Progress
                width="100%"
                hasStripe
                value={event.meal.protein}
                max={ goals && goals.macros !== undefined ? goals.macros.protein : 0}
              />
              <Text fontSize="sm" color={"grayText"}>
                Υδατάνθρακες:{" "}
                {event.meal.carbs !== undefined
                  ? event.meal.carbs.toFixed(2)
                  : 0}
                γρ (
                {goals && goals.macros !== undefined
                  ? ((event.meal.carbs / goals.macros.carbs) * 100).toFixed(2)
                  : 0}
                %)
              </Text>
              <Text fontSize="sm" color={"blue"}>
                Πελάτης:{" "}
                {event.meal.clientTotal.carbs !== undefined
                  ? event.meal.clientTotal.carbs.toFixed(2)
                  : 0}
                γρ
              </Text>
              <Progress
                width="100%"
                colorScheme="yellow"
                hasStripe
                value={event.meal.carbs}
                max={goals && goals.macros !== undefined ? goals.macros.carbs : 0}
              />
              <Text fontSize="sm" color={"grayText"}>
                Λιπαρά:{" "}
                {event.meal.fat !== undefined ? event.meal.fat.toFixed(2) : 0}γρ
                (
                {goals && goals.macros !== undefined
                  ? ((event.meal.fat / goals.macros.fat) * 100).toFixed(2)
                  : 0}
                %)
              </Text>
              <Text fontSize="sm" color={"blue"}>
                Πελάτης:{" "}
                {event.meal.clientTotal.fat !== undefined
                  ? event.meal.clientTotal.fat.toFixed(2)
                  : 0}
                γρ
              </Text>
              <Progress
                width="100%"
                colorScheme="red"
                hasStripe
                value={event.meal.fat}
                max={goals && goals.macros !== undefined ? goals.macros.fat : 0}
              />
            </VStack>
          </HStack>
        )}
      </Box>
    );
  }

  Event.propTypes = {
    event: PropTypes.object,
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Menu
        isOpen={isContextMenuOpen}
        onClose={() => setContextMenuOpen(false)}
      >
        <MenuList
          position="fixed"
          top={globalCoords.y - 30}
          left={globalCoords.x}
          color="primary"
        >
          <MenuItem
            icon={<MdContentPaste />}
            onClick={() => {
              localStorage.setItem(
                "meal",
                JSON.stringify(selectableEvent.meal.consists_of)
              );
              setContextMenuOpen(false);
            }}
          >
            Αντιγραφή
          </MenuItem>

          <MenuItem
            icon={<MdDeleteOutline />}
            onClick={async () => {
              await deleteMeal(selectableEvent.meal.meal_id);
              handleOnClose();
              setContextMenuOpen(false);
            }}
          >
            Διαγραφή
          </MenuItem>

          <MenuItem
            icon={<MdOutlineContentPaste />}
            onClick={async () => {
              console.debug(selectableEvent.meal);
              const copied = JSON.parse(localStorage.getItem("meal"));
              console.debug(copied);
              const codes = [
                ...selectableEvent.meal.consists_of.map((item) => {
                  return item.code;
                }),
                ...copied.map((item) => {
                  return item.code;
                }),
              ];
              const quantities = [
                selectableEvent.meal.consists_of.map((item) => {
                  return item.quantity;
                }),
                ...copied.map((item) => {
                  return item.quantity;
                }),
              ];
              const date = selectableEvent.meal.date;
              const type = selectableEvent.meal.meal_type;
              const comments = selectableEvent.meal.comments;
              await addMeal(date, user, codes, quantities, type, id, comments);
              handleOnClose();
              setContextMenuOpen(false);
            }}
          >
            Επικόλληση
          </MenuItem>
        </MenuList>
      </Menu>

      <HStack>
        <Button
          leftIcon={<MdContentPaste />}
          variant="filled"
          bgColor="blue.100"
          onClick={() => setPickerOpen(true)}
        >
          Επικόλληση γευμάτων
        </Button>
        <ChangeLabelModal
          dinner={dinnerLabel}
          lunch={lunchLabel}
          breakfast={breakfastLabel}
          snack1={snack1Label}
          snack2={snack2Label}
          changeDinner={setDinnerLabel}
          changeLunch={setLunchLabel}
          changeBreakfast={setBreakfastLabel}
          changeSnack1={setSnack1Label}
          changeSnack2={setSnack2Label}
        />
        <Spacer w={10} />
        <EditUserGoals clientId={id} gender={gender} />
      </HStack>
      {pickerOpen ? (
        <CustomDatePicker
          client_id={id}
          open={true}
          onChange={() => {
            setPickerOpen(false);
            getMealPlanList(startDate, endDate);
          }}
        />
      ) : null}
      <Box h={5} />
      <Calendar
        minWidth={"100%"}
        overflowX={"auto"}
        minHeight={"100vh"}
        components={{
          event: Event,
          eventWrapper: EventWrapperComponent,
          dateCellWrapper: ColoredDateCellWrapper,
          timeSlotWrapper: customTimeSlot,
        }}
        dayLayoutAlgorithm={"no-overlap"}
        selectable
        onSelectEvent={(event) => {
          if (event.type === "total") {
            return;
          }
          console.debug("select event: " + JSON.stringify(event));
          setSelectableEvent(event);
          setSelectedDate(event.end);
          onOpen();
        }}
        onRangeChange={function (date) {
          const start = new Date(date[0]);
          let end_date = null;
          const new_date = `${start.getFullYear()}-${
            start.getMonth() + 1
          }-${start.getDate()}`;
          setStartDate(new_date);
          if (date[6]) {
            const end = new Date(date[6]);
            end_date = `${end.getFullYear()}-${
              end.getMonth() + 1
            }-${end.getDate()}`;
            setEndDate(end_date);
          }

          getMealPlanList(new_date, end_date);
        }}
        defaultDate={moment().toDate()}
        defaultView="week"
        culture={"el"}
        messages={lang["gr"]}
        views={["week", "day"]}
        timeslots={1}
        formats={{ eventTimeRangeFormat: () => null }}
        step={240}
        events={events}
        localizer={localizer}
        onSelectSlot={(slotInfo) => {
          setSelectableEvent(null);
          console.debug("select event: " + JSON.stringify(slotInfo));
          const { end } = slotInfo;
          setSelectedDate(end);
          onOpen();
        }}
        style={{ height: "100vh" }}
      />
      {selectableEvent !== null ? (
        <EditDrawer
          isOpen={isOpen}
          onClose={handleOnClose}
          meal={selectableEvent.meal}
          title={selectableEvent.title}
          selectValue={selectableEvent.meal.meal_type}
          description={selectableEvent.description}
          date={selectedDate}
          client_id={id}
        />
      ) : new Date(selectedDate).getHours() >= 23 ? null : (
        <InsertMealModal
          isOpen={isOpen}
          onClose={handleOnClose}
          date={selectedDate}
          client_id={id}
          type={
            new Date(selectedDate).getHours() >= 20
              ? "dinner"
              : new Date(selectedDate).getHours() >= 16
              ? "snack2"
              : new Date(selectedDate).getHours() >= 12
              ? "lunch"
              : new Date(selectedDate).getHours() >= 8
              ? "snack1"
              : "breakfast"
          }
        />
      )}
    </Box>
  );
}
