import {
  MdAddCircle,
  MdAddCircleOutline,
  MdCheckCircle,
  MdContentPaste,
  MdDeleteOutline,
  MdInfoOutline,
  MdOutlineFavorite,
  MdSetMeal,
} from "react-icons/md";
import {
  Heading,
  HStack,
  Icon,
  Progress,
  Select,
  Spacer,
  Textarea,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Button,
  Box,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";
import { UserContext } from "contexts/UserContext";
import AsyncSelect from "react-select/async";
import SearchFood from "./SearchFood";
import axios from "axios";
import Card from "components/card/Card";
import addMeal from "views/admin/fullCalendar/components/AddAMeal";
import MicroProgress from "./MicroCardProgress";
import { addFavoriteMeal } from "views/admin/fullCalendar/components/AddAMeal";
import { useEffect } from "react";

export default function CreateMealModal(props) {
  const { isOpen, onClose, client_id, date, showType, meal_type } = props;
  const goals = JSON.parse(localStorage.getItem("goals"));
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [meal, setMeal] = useState([]);
  const [comments, setComments] = useState("");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  const [name, setName] = useState("");
  const [type, setType] = useState(
    meal_type !== undefined ? meal_type : "breakfast"
  );

  const [micros, setMicros] = useState({});
  const initialRef = React.useRef(null);
  const { user } = useContext(UserContext);
  const cardColor = useColorModeValue("gray.200", "gray.800");
  const [openMore, setOpenMore] = useState(false);
  const [addedToFavorite, setAddedToFavorite] = useState(false);
  const [mealPlanList, setMealPlanList] = useState([]);
  function handleCancel(event) {
    clearState();
    onClose();
  }
  function handleChange(event) {
    setName(event.target.value);
  }

  useEffect(() => {
    getMealPlanList()
      .then((data) => {
        setMealPlanList(data);
      })
      .catch((err) => {
        console.error("error:gather micros of all day", err);
        setMealPlanList([]);
      });
  }, [date]);

  async function getMealPlanList() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    console.debug("date", date);
    const res = await axios.post("/api/auth/view_diet_plan", {
      client_id: client_id,
      date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
    });
    return res.data;
  }

  function clearState() {
    setMeal([]);
    setValue([]);
    setMicros({});
    setMealPlanList([]);
  }
  function doPaste() {
    const meal = JSON.parse(localStorage.getItem("meal"));
    for (let label in meal[0].micros) {
      meal.forEach((e) => {
        e[label] = e.micros[label];
      });
    }
    meal.forEach((e) => {
      e.micros = {};
    });
    setValue(meal);
    hanleValueChange(meal);
  }
  function hanleValueChange(food) {
    setValue(food);
    let micros_list = {};

    for (let label in food[0]) {
      let result = food.reduce((acc, cur) => {
        if (
          cur.quantity === 0 ||
          cur.quantity === undefined ||
          Number.isNaN(cur.quantity)
        )
          return acc;
        if (label === "quantity") {
          const quantity = parseInt(cur[label]);
          return acc + quantity;
        }
        return acc + (cur[label] * parseInt(cur.quantity)) / 100;
      }, 0);
      if (
        label === "protein" ||
        label === "carbs" ||
        label === "fat" ||
        label === "quantity"
      ) {
        console.debug("result on label", result, label);
      }

      micros_list[label] = result;
    }
    if (mealPlanList.length > 0) {
      let other_meals = mealPlanList.map((item) => {
        const temp = {
          protein: item.protein,
          carbs: item.carbs,
          fat: item.fat,
          calories: item.calories,
        };

        for (let label in item.micros) {
          temp[label] = item.micros[label];
        }
        return temp;
      });
      console.debug("temp", other_meals);
      for (let label in other_meals[0]) {
        micros_list[label] += other_meals.reduce((a, b) => a + b[label], 0);
      }
    }
    let temp_list = meal.map((item) => {
      const temp = {
        protein: item.protein,
        carbs: item.carbs,
        fat: item.fat,
        calories: item.calories,
      };
      for (let label in item.micros) {
        temp[label] = item.micros[label];
      }
      return temp;
    });
    for (let label in temp_list[0]) {
      let result = temp_list.reduce((acc, cur) => {
        return acc + cur[label];
      }, 0);
      micros_list[label] += result;
    }
    console.debug("micros_list", micros_list);
    setMicros(micros_list);
  }

  async function getMeals() {
    axios.defaults.headers.common["auth-token"] = `${user}`;
    const res = await axios.get("/api/auth/retrieve_meals");
    const data = res.data;
    return data;
  }
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getMeals({ filter: inputValue }).then((meals) => {
        resolve(
          meals.map((meal) => {
            return {
              meal_id: meal.meal_id,
              name: meal.meal_name,
              consists_of: meal.consists_of,
              label: meal.meal_name,
              meal_type: meal.meal_type,
              calories: meal.calories,
              protein: meal.protein,
              carbs: meal.carbs,
              fat: meal.fat,
              micros: meal.micros,
            };
          })
        );
      });
    });
  return isLoading ? (
    <Progress />
  ) : (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        size="6xl"
        initialFocusRef={initialRef}
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack spacing={4}>
              <Text fontSize="lg" fontWeight="bold">
                Φτιάξε το γεύμα
              </Text>
              <Spacer />
              <Button
                colorScheme="blue"
                variant="solid"
                leftIcon={<MdAddCircle />}
                onClick={() => setValue([...value, { food: "", quantity: 0 }])}
              >
                Προσθήκη τροφίμου
              </Button>
              <Box p={3} />
              <Button
                variant="outline"
                bgColor={"blue.100"}
                leftIcon={<MdAddCircleOutline />}
                onClick={() => setMeal([...meal, null])}
              >
                Προσθήκη γεύματος
              </Button>
              

              <Button
                leftIcon={<MdContentPaste />}
                variantColor="blue"
                onClick={doPaste}
              >
                Επικόλληση
              </Button>
              <Button
                bgColor={"blue.100"}
                mr={3}
                onClick={async () => {
                  const codes = [
                    ...value.map((e) => e.code),
                    ...meal.flatMap((e) => e.consists_of.map((e) => e.code)),
                  ];
                  const quantities = [
                    ...value.map((e) => e.quantity),
                    ...meal.flatMap((e) =>
                      e.consists_of.map((e) => e.quantity)
                    ),
                  ];

                  setLoading(true);
                  await addMeal(
                    date,
                    user,
                    codes,
                    quantities,
                    meal_type !== undefined ? meal_type : type,
                    client_id,
                    comments
                  );
                  clearState();
                  setLoading(false);
                  onClose();
                }}
              >
                Αποθήκευση
              </Button>
              <Box width="5%" />
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Input
              value={name}
              onChange={handleChange}
              placeholder="Δώσε όνομα στο γεύμα"
            />{" "}
            {showType !== undefined && showType === true ? (
              <Box mt={4}>
                <HStack spacing={4}>
                  <Icon as={MdSetMeal} />
                  <Select
                    px={2}
                    py={2}
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="breakfast">Πρωινό</option>
                    <option value="lunch">Μεσημεριανό</option>
                    <option value="dinner">Βραδινό</option>
                    <option value="snack1">Σνακ1</option>
                    <option value="snack2">Σνακ2</option>
                  </Select>
                </HStack>
              </Box>
            ) : null}
            {value.map((e, id) => (
              <HStack key={id} width="100%" pt="10">
                {" "}
                <SearchFood
                  value={value}
                  id={id}
                  setValue={hanleValueChange}
                  a
                />
                <Box alignSelf={"flex-start"} pt={5}>
                  {" "}
                  <Button
                    float="center"
                    align="center"
                    p={5}
                    leftIcon={<MdDeleteOutline />}
                    onClick={() => {
                      const index = value.indexOf(value[id]);
                      if (index === -1) {
                        return;
                      }
                      const deletedValues = value.splice(index, 1);
                      setValue(value.filter((e) => !deletedValues.includes(e)));
                      hanleValueChange(value);
                    }}
                  />
                </Box>{" "}
              </HStack>
            ))}
            {meal.map((e, id) => (
              <HStack key={id} width={"100%"} pt="10" align="stretch">
                <Box px={2} pb={6} width="100%">
                  <AsyncSelect
                    width="100%"
                    name="meals"
                    align="center"
                    px={10}
                    placeholder="Επέλεξε γεύμα"
                    noOptionsMessage={() => "Δεν υπάρχουν γεύματα"}
                    defaultOptions={meal}
                    loadOptions={promiseOptions}
                    closeMenuOnSelect={true}
                    size="lg"
                    onChange={(e) => {
                      if (e === null) {
                        const micros_list = {};
                        for (let label in meal[id].micros) {
                          micros_list[label] =
                            micros[label] - meal[id].micros[label];
                        }
                        micros_list["calories"] =
                          micros["calories"] - meal[id].calories;
                        micros_list["protein"] =
                          micros["protein"] - meal[id].protein;
                        micros_list["carbs"] = micros["carbs"] - meal[id].carbs;
                        micros_list["fat"] = micros["fat"] - meal[id].fat;
                        setMicros(micros_list);
                        meal[id] = null;
                        setMeal(meal);
                        return;
                      }
                      meal[id] = e;
                      setMeal(meal);
                      const micros_list = {};

                      for (let label in e.micros) {
                        micros_list[label] =
                          (isNaN(micros[label]) ? 0 : micros[label]) +
                          e.micros[label];
                      }
                      micros_list["calories"] =
                        (isNaN(micros["calories"]) ? 0 : micros["calories"]) +
                        e.calories;
                      micros_list["protein"] =
                        (isNaN(micros["protein"]) ? 0 : micros["protein"]) +
                        e.protein;
                      micros_list["carbs"] =
                        (isNaN(micros["carbs"]) ? 0 : micros["carbs"]) +
                        e.carbs;
                      micros_list["fat"] =
                        (isNaN(micros["fat"]) ? 0 : micros["fat"]) + e.fat;
                      setMicros(micros_list);
                    }}
                    isClearable={true}
                  />
                </Box>
                {meal[id] !== null && meal[id] !== undefined ? (
                  <>
                    <Box
                      bgColor={"lightBrand"}
                      borderRadius={5}
                      p={2}
                      border={"1px"}
                      color="gray.400"
                      align="center"
                      pt={1}
                    >
                      <Text>Πρωτεΐνες {meal[id].protein.toFixed(2)}γ</Text>
                    </Box>
                    <Box
                      bgColor={"lightBrand"}
                      borderRadius={5}
                      p={2}
                      border={"1px"}
                      color="gray.400"
                      align="center"
                      pt={1}
                    >
                      <Text>Υδατάνθρακες {meal[id].carbs.toFixed(2)}γ</Text>
                    </Box>
                    <Box
                      bgColor={"lightBrand"}
                      borderRadius={5}
                      p={2}
                      border={"1px"}
                      color="gray.400"
                      align="center"
                      pt={1}
                    >
                      <Text>Λιπαρά {meal[id].fat.toFixed(2)}γ</Text>
                    </Box>
                    <Box
                      bgColor={"lightBrand"}
                      borderRadius={5}
                      p={2}
                      border={"1px"}
                      color="gray.400"
                      align="center"
                      pt={1}
                    >
                      <Text>Θερμίδες {meal[id].calories.toFixed(2)} kcal</Text>
                    </Box>
                  </>
                ) : null}
                <Button
                  float="center"
                  leftIcon={<MdDeleteOutline />}
                  onClick={() => {
                    const index = meal.indexOf(meal[id]);
                    if (index === -1) {
                      return;
                    }
                    if (meal[id] === null) {
                      const deletedValues = meal.splice(index, 1);
                      console.debug("deletedValues", deletedValues);
                      setMeal(meal.filter((e) => !deletedValues.includes(e)));
                      if (meal.length === 0 && value.length === 0) {
                        clearState();
                      }
                      return;
                    }
                    const micros_list = {};
                    for (let label in meal[id].micros) {
                      micros_list[label] =
                        micros[label] - meal[id].micros[label];
                    }
                    micros_list["calories"] =
                      micros["calories"] - meal[id].calories;
                    micros_list["protein"] =
                      micros["protein"] - meal[id].protein;
                    micros_list["carbs"] = micros["carbs"] - meal[id].carbs;
                    micros_list["fat"] = micros["fat"] - meal[id].fat;
                    setMicros(micros_list);
                    const deletedValues = meal.splice(index, 1);
                    console.debug("deletedValue", e);
                    setMeal(meal.filter((e) => !deletedValues.includes(e)));
                    if (meal.length === 0 && value.length === 0) {
                      clearState();
                      return;
                    }
                  }}
                />
              </HStack>
            ))}
            {(value.length > 0 || meal.length > 0) &&
            micros !== {} &&
            goals !== null ? (
              <Card width="100%" rounded={"lg"} bg={cardColor}>
                <VStack>
                  <Text>
                    Θερμίδες: {micros.calories} kcal (
                    {goals && goals.macros !== undefined
                      ? (
                          (micros.calories / goals.macros.calories) *
                          100
                        ).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="green"
                    hasStripe
                    value={
                      isNaN(micros.calories) ? 0 : micros.calories.toFixed(2)
                    }
                    max={goals && goals.macros !== undefined ? goals.macros.calories : 0}
                  />
                  <Text>
                    Πρωτεΐνες:{" "}
                    {isNaN(micros.protein) ? "NaN" : micros.protein.toFixed(2)}{" "}
                    γρ(
                    {goals && goals.macros !== undefined
                      ? ((micros.protein / goals.macros.protein) * 100).toFixed(
                          2
                        )
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    hasStripe
                    value={micros.protein}
                    max={goals &&goals.macros !== undefined ? goals.macros.protein : 0}
                  />
                  <Text>
                    Υδατάνθρακες:{" "}
                    {isNaN(micros.carbs) ? "NaN" : micros.carbs.toFixed(2)}γρ(
                    {goals &&goals.macros !== undefined
                      ? ((micros.carbs / goals.macros.carbs) * 100).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="yellow"
                    hasStripe
                    value={micros.carbs}
                    max={goals &&goals.macros !== undefined ? goals.macros.carbs : 0}
                  />
                  <Text>
                    Λιπαρά: {isNaN(micros.fat) ? "NaN" : micros.fat.toFixed(2)}γ
                    (
                    {goals &&goals.macros !== undefined
                      ? ((micros.fat / goals.macros.fat) * 100).toFixed(2)
                      : 0}
                    %)
                  </Text>
                  <Progress
                    width="100%"
                    colorScheme="red"
                    hasStripe
                    value={micros.fat}
                    max={goals &&goals.macros !== undefined ? goals.macros.fat : 0}
                  />

                  <Button
                    leftIcon={<MdInfoOutline />}
                    onClick={() => setOpenMore(true)}
                  >
                    Περισσότερα
                  </Button>
                </VStack>
              </Card>
            ) : null}
            <Box p={3} />
            <Textarea width="100%" placeholder={"Σχόλια για το γεύμα"} value={comments} onChange={(e) => setComments(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Spacer />
            {addedToFavorite ? (
              <Box bgColor={shadow} borderRadius={20} padding={3} margin={2}>
                <HStack spacing={3}>
                  <Text fontSize="md" fontWeight="bold">
                    Προστέθηκε!
                  </Text>
                  <Icon as={MdCheckCircle} color="green.500" />
                </HStack>
              </Box>
            ) : (
              <Button
                mr={2}
                bgColor={"red.100"}
                leftIcon={<MdOutlineFavorite />}
                onClick={async () => {
                  const codes = [
                    ...value.map((e) => e.code),
                    ...meal.flatMap((e) => e.consists_of.map((e) => e.code)),
                  ];
                  const quantities = [
                    ...value.map((e) => e.quantity),
                    ...meal.flatMap((e) =>
                      e.consists_of.map((e) => e.quantity)
                    ),
                  ];
                  const res = await addFavoriteMeal(
                    name,
                    codes,
                    quantities,
                    client_id,
                    type,
                    user
                  );
                  if (res === null) {
                    return;
                  }
                  setAddedToFavorite(true);
                }}
              >
                Αποθήκευση στα αγαπημένα
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {seeMoreModal(openMore, setOpenMore, micros, goals)}
    </>
  );
}
export function seeMoreModal(openMore, setOpenMore, micros, goals) {
  if (goals === undefined || goals === null) return <Box></Box>;
  return (
    <Modal
      size="xl"
      isOpen={openMore}
      onClose={() => setOpenMore(false)}
      isCentered
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>Περισσότερα</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card width="100%" rounded={"lg"} overflowY="none" maxHeight="1000px">
            <VStack spacing={5}>
              <MicroProgress
                value={micros.protein === undefined ? 0 : micros.protein}
                title="Πρωτεΐνες"
                color={
                  goals && micros &&
                  micros.protein !== undefined &&
                  goals.macros !== undefined &&
                  ((micros.protein / goals.macros.protein) * 100).toFixed(2) <
                    105
                    ? ((micros.protein / goals.macros.protein) * 100).toFixed(
                        2
                      ) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals && goals.macros !== undefined ? goals.macros.protein : 200}
                measurement="γρ"
              />
              <MicroProgress
                value={micros.carbs === undefined ? 0 : micros.carbs}
                title="Υδατάνθρακες"
                color={
                  goals && micros &&
                  micros.carbs !== undefined &&
                  goals.macros !== undefined &&
                  ((micros.carbs / goals.macros.carbs) * 100).toFixed(2) < 105
                    ? ((micros.carbs / goals.macros.carbs) * 100).toFixed(2) >
                      95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals && goals.macros !== undefined ? goals.macros.carbs : 200}
                measurement="γρ"
              />
              <MicroProgress
                value={micros.fat === undefined ? 0 : micros.fat}
                title="Λιπαρά"
                color={
                  goals && micros &&
                  micros.fat !== undefined &&
                  goals.macros !== undefined &&
                  ((micros.fat / goals.macros.fat) * 100).toFixed(2) < 105
                    ? ((micros.fat / goals.macros.fat) * 100).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals &&goals.macros !== undefined ? goals.macros.fat : 200}
                measurement="γρ"
              />
              <MicroProgress
                value={micros.calories === undefined ? 0 : micros.calories}
                title="Θερμίδες"
                color={
                  goals && micros &&
                  micros.calories !== undefined &&
                  goals.macros !== undefined &&
                  ((micros.calories / goals.macros.calories) * 100).toFixed(2) <
                    105
                    ? micros.calories - goals.macros.calories > -150 &&
                      micros.calories - goals.macros.calories < 150
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals && goals.macros !== undefined ? goals.macros.calories : 2000}
                measurement="kcal"
              />
              <MicroProgress
                value={micros.calcium === undefined ? 0 : micros.calcium}
                title="Ασβέστιο"
                color={
                  micros.calcium !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.calcium / goals.max_goals.calcium) * 100).toFixed(
                    2
                  ) < 105
                    ? (
                        (micros.calcium / goals.max_goals.calcium) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.calcium : 0}
              />
              <MicroProgress
                value={micros.alcochol === undefined ? 0 : micros.alcochol}
                title="Αλκοόλ"
                color={
                  micros.alcochol !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.alcochol / goals.max_goals.alcochol) * 100).toFixed(
                    2
                  ) < 105
                    ? "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.alcochol : 0}
              />
              <MicroProgress
                value={micros.caffeine === undefined ? 0 : micros.caffeine}
                title="Καφεΐνη"
                color={
                  micros.caffeine !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.caffeine / goals.max_goals.caffeine) * 100).toFixed(
                    2
                  ) < 105
                    ? "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.caffeine : 0}
              />
              <MicroProgress
                value={micros.choline === undefined ? 0 : micros.choline}
                title="Xολίνη"
                color={
                  micros.choline !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.choline / goals.max_goals.choline) * 100).toFixed(
                    2
                  ) < 105
                    ? (
                        (micros.choline / goals.max_goals.choline) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.choline : 0}
              />
              <MicroProgress
                value={micros.copper === undefined ? 0 : micros.copper}
                title="Χαλκός"
                color={
                  micros.copper !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.copper / goals.max_goals.copper) * 100).toFixed(2) <
                    105
                    ? ((micros.copper / goals.max_goals.copper) * 100).toFixed(
                        2
                      ) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.copper : 0}
              />
              <MicroProgress
                value={micros.ferrum === undefined ? 0 : micros.ferrum}
                title="Σίδηρος"
                color={
                  micros.ferrum !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.ferrum / goals.max_goals.ferrum) * 100).toFixed(2) <
                    105
                    ? ((micros.ferrum / goals.max_goals.ferrum) * 100).toFixed(
                        2
                      ) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.ferrum : 0}
              />
              <MicroProgress
                value={micros.fibers === undefined ? 0 : micros.fibers}
                title="Φυτικές ίνες"
                color="green"
                goal={goals.micros !== undefined ? goals.micros.fibers : 0}
              />
              <MicroProgress
                value={micros.follicles === undefined ? 0 : micros.follicles}
                title="Φολικό οξύ"
                color={
                  micros.follicles !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros.follicles / goals.max_goals.follicles) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros.follicles / goals.max_goals.follicles) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.follicles : 0}
              />
              <MicroProgress
                value={micros.magnisium === undefined ? 0 : micros.magnisium}
                title="Μαγνήσιο"
                color={
                  micros.magnisium !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros.magnisium / goals.max_goals.magnisium) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros.magnisium / goals.max_goals.magnisium) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.magnisium : 0}
              />
              <MicroProgress
                value={
                  micros.monounsaturated === undefined
                    ? 0
                    : micros.monounsaturated
                }
                title="Μονοακόρεστα"
                color={
                  micros.monounsaturated !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros.monounsaturated / goals.micros.monounsaturated) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros.monounsaturated : 0
                }
              />
              <MicroProgress
                value={
                  micros.polyunsaturated === undefined
                    ? 0
                    : micros.polyunsaturated
                }
                title="Πολυακόρεστα"
                goal={
                  goals.micros !== undefined ? goals.micros.polyunsaturated : 0
                }
                color={
                  micros.polyunsaturated !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros.polyunsaturated / goals.micros.polyunsaturated) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
              />
              <MicroProgress
                value={micros.potassium === undefined ? 0 : micros.potassium}
                title="Κάλιο"
                goal={goals.micros !== undefined ? goals.micros.potassium : 0}
                color={
                  micros.potassium !== undefined &&
                  goals.micros !== undefined &&
                  ((micros.potassium / goals.micros.potassium) * 100).toFixed(
                    2
                  ) < 105
                    ? "blue"
                    : "red"
                }
              />
              <MicroProgress
                value={micros.saturated === undefined ? 0 : micros.saturated}
                title="Κορεσμένα"
                goal={goals.micros !== undefined ? goals.micros.saturated : 0}
                color={
                  micros.saturated !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros.saturated / goals.max_goals.saturated) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
              />
              <MicroProgress
                value={micros.phosphorus === undefined ? 0 : micros.phosphorus}
                title="Φωσφόρος"
                color={
                  micros.phosphorus !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros.phosphorus / goals.max_goals.phosphorus) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros.phosphorus / goals.max_goals.phosphorus) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.phosphorus : 0}
              />
              <MicroProgress
                value={micros.selinium === undefined ? 0 : micros.selinium}
                title="Σελήνιο"
                color={
                  micros.selinium !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.selinium / goals.max_goals.selinium) * 100).toFixed(
                    2
                  ) < 105
                    ? (
                        (micros.selinium / goals.max_goals.selinium) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.selinium : 0}
              />
              <MicroProgress
                value={micros.sugars === undefined ? 0 : micros.sugars}
                title="Ζάχαρη"
                color={
                  micros.sugars !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.sugars / goals.max_goals.sugars) * 100).toFixed(2) <
                    105
                    ? "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.sugars : 0}
              />
              <MicroProgress
                value={
                  micros["vitaminA"] === undefined ? 0 : micros["vitaminA"]
                }
                title="Βιταμίνη A"
                color={
                  micros["vitaminA"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminA"] / goals.max_goals["vitaminA"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminA"] / goals.max_goals["vitaminA"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros["vitaminA"] : 0}
              />
              <MicroProgress
                value={
                  micros["vitaminB1"] === undefined ? 0 : micros["vitaminB1"]
                }
                title="Βιταμίνη B1"
                color={
                  micros["vitaminB1"] !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros["vitaminB1"] / goals.micros["vitaminB1"]) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros["vitaminB1"] : 0
                }
              />
              <MicroProgress
                value={
                  micros["vitaminB2"] === undefined ? 0 : micros["vitaminB2"]
                }
                title="Βιταμίνη B2"
                color={
                  micros["vitaminB2"] !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros["vitaminB2"] / goals.micros["vitaminB2"]) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros["vitaminB2"] : 0
                }
              />
              <MicroProgress
                value={
                  micros["vitaminB3"] === undefined ? 0 : micros["vitaminB3"]
                }
                title="Βιταμίνη B3"
                color={
                  micros["vitaminB3"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminB3"] / goals.max_goals["vitaminB3"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminB3"] / goals.max_goals["vitaminB3"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros["vitaminB3"] : 0
                }
              />
              <MicroProgress
                value={
                  micros["vitaminB12"] === undefined ? 0 : micros["vitaminB12"]
                }
                title="Βιταμίνη B12"
                color={
                  micros["vitaminB12"] !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros["vitaminB12"] / goals.micros["vitaminB12"]) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros["vitaminB12"] : 0
                }
              />
              <MicroProgress
                value={
                  micros["vitaminB6"] === undefined ? 0 : micros["vitaminB6"]
                }
                title="Βιταμίνη B6"
                color={
                  micros["vitaminB6"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminB6"] / goals.max_goals["vitaminB6"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminB6"] / goals.max_goals["vitaminB6"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={
                  goals.micros !== undefined ? goals.micros["vitaminB6"] : 0
                }
              />
              <MicroProgress
                value={
                  micros["vitaminC"] === undefined ? 0 : micros["vitaminC"]
                }
                title="Βιταμίνη C"
                color={
                  micros["vitaminC"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminC"] / goals.max_goals["vitaminC"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminC"] / goals.max_goals["vitaminC"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros["vitaminC"] : 0}
              />
              <MicroProgress
                value={
                  micros["vitaminD"] === undefined ? 0 : micros["vitaminD"]
                }
                title="Βιταμίνη D"
                color={
                  micros["vitaminD"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminD"] / goals.max_goals["vitaminD"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminD"] / goals.max_goals["vitaminD"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros["vitaminD"] : 0}
              />
              <MicroProgress
                value={
                  micros["vitaminE"] === undefined ? 0 : micros["vitaminE"]
                }
                title="Βιταμίνη E"
                color={
                  micros["vitaminE"] !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  (
                    (micros["vitaminE"] / goals.max_goals["vitaminE"]) *
                    100
                  ).toFixed(2) < 105
                    ? (
                        (micros["vitaminE"] / goals.max_goals["vitaminE"]) *
                        100
                      ).toFixed(2) > 95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros["vitaminE"] : 0}
              />
              <MicroProgress
                value={
                  micros["vitaminK"] === undefined ? 0 : micros["vitaminK"]
                }
                title="Βιταμίνη K"
                color={
                  micros["vitaminK"] !== undefined &&
                  goals.micros !== undefined &&
                  (
                    (micros["vitaminK"] / goals.micros["vitaminK"]) *
                    100
                  ).toFixed(2) < 105
                    ? "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros["vitaminK"] : 0}
              />
              <MicroProgress
                value={micros.natrium === undefined ? 0 : micros.natrium}
                title="Νάτριο"
                color={
                  micros.natrium !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.natrium / goals.max_goals.natrium) * 100).toFixed(
                    2
                  ) < 105
                    ? "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.natrium : 0}
              />
              <MicroProgress
                value={micros.zinc === undefined ? 0 : micros.zinc}
                title="Ψευδάργυρος"
                color={
                  micros.zinc !== undefined &&
                  goals.micros !== undefined &&
                  goals.max_goals !== undefined &&
                  ((micros.zinc / goals.max_goals.zinc) * 100).toFixed(2) < 105
                    ? ((micros.zinc / goals.max_goals.zinc) * 100).toFixed(2) >
                      95
                      ? "green"
                      : "blue"
                    : "red"
                }
                goal={goals.micros !== undefined ? goals.micros.zinc : 0}
              />
              <MicroProgress
                value={micros.water === undefined ? 0 : micros.water}
                title="Νερό"
                color="green"
                goal={goals.micros !== undefined ? goals.micros.water : 0}
              />
              <MicroProgress
                value={micros["omega3"] === undefined ? 0 : micros["omega3"]}
                title="Ωμέγα 3"
                color="blue"
                goal={goals.micros !== undefined ? goals.micros["omega3"] : 0}
              />
              <MicroProgress
                value={micros["omega6"] === undefined ? 0 : micros["omega6"]}
                title="Ωμέγα 6"
                color="blue"
                goal={goals.micros !== undefined ? goals.micros["omega6"] : 0}
              />
            </VStack>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setOpenMore(false)}>Πίσω</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
