import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Select,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";

export default function CreateCouponModal() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState("");
  const [couponDuration, setCouponDuration] = useState("");
  const [couponMonthsDuration, setCouponMonthsDuration] = useState("");
  async function createCoupon() {
    axios
      .post("/api/auth/create_coupon", {
        coupon_info: {
          name: couponCode,
          duration: couponDuration,
          monthsDuration: couponMonthsDuration,
          percentOff: couponDiscount,
        },
      })
      .then((res) => {
        console.log(res);
        toast({
          title: "Επιτυχία",
          description: "Ο κωδικός δημιουργήθηκε επιτυχώς",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα",
          description: "Δεν μπορέσαμε να δημιουργήσουμε τον κωδικό",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  }
  return (
    <Box>
      <Button
        leftIcon={<FiPlus />}
        colorScheme="blue"
        aria-label="Προσθήκη πελάτη"
        size="md"
        onClick={onOpen}
      >
        Δημιουργία κουπονιού
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        isCentered
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Δημιουργία κουπονιού</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="couponCode" isRequired>
              <FormLabel>Κωδικός κουπονιού</FormLabel>
              <Input
                type="text"
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </FormControl>
            <FormControl id="couponDiscount" isRequired>
              <FormLabel>Έκπτωση</FormLabel>
              <Input
                type="number"
                onChange={(e) => setCouponDiscount(e.target.value)}
              />
            </FormControl>
            <FormControl id="couponDuration" isRequired>
              <FormLabel>Διάρκεια</FormLabel>
              <Select
                placeholder="Επιλέξτε"
                onChange={(e) => setCouponDuration(e.target.value)}
              >
                <option value="forever">Πάντα</option>
                <option value="repeating">Επαναλαμβανόμενη</option>
                <option value="once">1 φορά</option>
              </Select>
            </FormControl>
            <FormControl id="couponDurationInMonths" isRequired>
              <FormLabel>Διάρκεια σε μήνες</FormLabel>
              <Input
                type="number"
                disabled={couponDuration === "forever"}
                readOnly={couponDuration === "forever"}
                onChange={(e) => setCouponMonthsDuration(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={createCoupon}>
              Δημιουργία
            </Button>
            <Button onClick={onClose}>Ακύρωση</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
