import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  Button,
  VStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Stack,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineVerifiedUser } from "react-icons/md";

export default function EditUserGoals(props) {
  const { clientId, gender } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [protein, setProtein] = useState(0);
  const [carbohydrate, setCarbohydrate] = useState(0);
  const [fat, setFat] = useState(0);
  const [calories, setCalories] = useState(0);
  const [isPregnant, setIsPregnant] = useState(false);
  const [isLactating, setIsLactating] = useState(false);
  const goals = JSON.parse(localStorage.getItem("goals"));

  useEffect(() => {
    setCalories(protein * 4 + carbohydrate * 4 + fat * 9);
  }, [protein, carbohydrate, fat]);

  useEffect(() => {
    if (goals) {
      setProtein(goals.macros.protein);
      setCarbohydrate(goals.macros.carbs);
      setFat(goals.macros.fat);
      setCalories(goals.macros.calories);
    }
  }, []);
  return (
    <Flex>
      <Button
        variant="filled"
        bgColor="blue.100"
        leftIcon={<MdOutlineVerifiedUser />}
        onClick={onOpen}
      >
        Επεξεργασία στόχων
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Επεξεργασία στόχων</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Text textAlign="start" fontSize="sm">
                Πρωτείνες
              </Text>
              <InputGroup size="md">
                <Input
                  type="number"
                  value={protein || 0}
                  onChange={(e) => setProtein(e.target.value)}
                />
                <InputRightElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="gr"
                />
              </InputGroup>
              <Text textAlign="start" fontSize="sm">
                Υδατάνθρακες
              </Text>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="gr"
                />
                <Input
                  type="number"
                  value={carbohydrate || 0}
                  onChange={(e) => setCarbohydrate(e.target.value)}
                />
              </InputGroup>
              <Text textAlign="start" fontSize="sm">
                Λιπαρά
              </Text>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="gr"
                />
                <Input
                  type="number"
                  value={fat || 0}
                  onChange={(e) => setFat(e.target.value)}
                />
              </InputGroup>
              <Text textAlign="start" fontSize="sm">
                Θερμίδες
              </Text>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                  children="kcal"
                />
                <Input
                  type="number"
                  variant="filled"
                  value={calories || 0}
                  onChange={(e) => setCalories(e.target.value)}
                />
              </InputGroup>

              {gender === "female" ? (
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    value={isPregnant}
                    onChange={(e) => setIsPregnant(e.target.value)}
                  >
                    Εγκυμοσύνη
                  </Checkbox>
                  <Checkbox
                    value={isLactating}
                    onChange={(e) => setIsLactating(e.target.value)}
                  >
                    Θηλασμός
                  </Checkbox>
                </Stack>
              ) : null}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Ακύρωση
            </Button>
            <Button
              variant="ghost"
              onClick={async () => {
                const json = {
                  client_id: clientId,
                  macros: {
                    protein: protein,
                    carbs: carbohydrate,
                    fat: fat,
                    calories: calories,
                  },
                  info: {
                    sex: gender,
                    pregnant: isPregnant,
                    breastFeeding: isLactating,
                  },
                };
                await axios.put("/api/auth/set_client_goals", json);
                localStorage.setItem("goals", JSON.stringify(json));
                onClose();
              }}
            >
              Αποθήκευση
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
