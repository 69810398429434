// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Box,
  VStack,
  StackDivider,
  Button,
  Checkbox,
  Input,
  Textarea,
  useToast,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import Card from "components/card/Card.js";
import { useEffect, useRef, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { MdOutlineAddLink } from "react-icons/md";
import { useInterval } from "views/admin/default/components/Tasks";

import Notification from "views/admin/profile/components/Notification.js";
export default function Notifications(props) {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { name, client_id, ...rest } = props;
  const initialFocusRef = useRef();
  const [notifications, setNotifications] = useState([]);
  const [date, setDate] = useState(new Date());
  const [comments, setComments] = useState("");
  const [link, setLink] = useState("");
  const [showOnlyMeeting, setShowOnlyMeeting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    getNotifications();
  }, []);

  useInterval(() => {
    getNotifications(showOnlyMeeting);
  }, 1000 * 10);

  async function getNotifications(showOnlyMeeting) {
    const response = await axios
      .get("/api/auth/get_meetings/" + client_id)
      .catch((err) => {
        console.error(err);
        toast({
          title: "Πρόβλημα σύνδεσης",
          description: "Δεν μπορέσαμε να συνδεθούμε στον server",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
    const data = response.data;
    console.debug("showOnlyMeeting", showOnlyMeeting);
    data.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    console.debug("data", data);
    setNotifications(
      showOnlyMeeting !== undefined && showOnlyMeeting
        ? data.filter((notification) => notification.type === "meeting")
        : data
    );
    console.debug("meetings", data);
    return data;
  }
  async function deleteNotification(meeting_id) {
    const response = await axios.delete(
      "/api/auth/delete_meeting/" + meeting_id
    );
    const data = response.data;
    console.info("deleted meeting", data);
    getNotifications();
    return data;
  }
  async function addMeeting() {
    console.debug("date", date.toISOString());
    const response = await axios
      .post("/api/auth/create_meeting", {
        client_id: client_id,
        date: date.toISOString(),
        link: link,
        comments: comments,
        type: "meeting",
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Πρόβλημα",
          description:
            "Πρόβλημα κατά την προσθήκη συνάντησης, πιθανόν να υπάρχει ήδη συνάντηση για αυτή την ημερομηνία",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      });
    const data = response.data;
    getNotifications();
    onClose();
    return data;
  }

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  return (
    <Card
      mb="20px"
      {...rest}
      overflowX="auto"
      overflowY="auto"
      maxHeight="1000px"
    >
      <Wrap align="stretch" w="100%" justify="space-between" mb="30px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mb="4px"
        >
          Ειδοποιήσεις
        </Text>
        <Box w={5} />
        <Checkbox
          defaultValue={showOnlyMeeting}
          onChange={async (e) => {
            setShowOnlyMeeting(e.target.checked);
            await getNotifications(e.target.checked);
          }}
          size="md"
        >
          Meetings
        </Checkbox>
        <Box w={5} />
        <Popover
          initialFocusRef={initialFocusRef}
          placement="bottom"
          closeOnBlur={false}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
        >
          <PopoverTrigger

          >
            <Button
              onClick={getNotifications}
              variant="ghost"
              size="small"
              fontSize="smaller"
              leftIcon={<MdOutlineAddLink />}
            >
              Προσθήκη
            </Button>
          </PopoverTrigger>

          <PopoverContent color="black">
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              Συνάντηση στις {date !== null ? date.toLocaleDateString() : " "}{" "}
              {date !== null ? new Date(date).toISOString() : " "}
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <DateTimePicker onChange={setDate} value={date} color="white" />

              <Box h={10} />
              <Input
                value={link}
                placeholder="Link"
                onChange={(e) => setLink(e.target.value)}
              />
              <Box h={5} />
              <Textarea
                ref={initialFocusRef}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Σχόλια"
              />
            </PopoverBody>
            <PopoverFooter
              border="0"
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              pb={4}
            >
              <Button onClick={addMeeting}>Αποθήκευση</Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Wrap>

      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={2}
        align="stretch"
      >
        {notifications.length === 0 ? (
          <Card {...rest}>
            <Flex
              direction="column"
              align="center"
              justify="center"
              w="100%"
              h="100%"
            >
              <Text
                fontSize="xl"
                fontWeight="bold"
                color={textColorPrimary}
                textAlign="center"
              >
                Δεν υπάρχουν ειδοποιήσεις
              </Text>
            </Flex>
          </Card>
        ) : null}

        {notifications.map((notification) => (
          <Notification
            key={notification.meeting_id}
            id={notification.meeting_id}
            date={notification.date}
            type={notification.type}
            title={
              "Meeting στις " + notification.date !== undefined
                ? new Date(notification.date).toLocaleDateString() +
                  "  " +
                  new Date(notification.date).toLocaleTimeString()
                : notification.date
            }
            link={notification.link}
            notes={notification.comments}
            textColorPrimary={textColorPrimary}
            onDelete={deleteNotification}
          />
        ))}
        <Box />
      </VStack>
    </Card>
  );
}
